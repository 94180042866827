import { useStaticQuery, graphql } from 'gatsby';
import { EsgOverviewQuery } from 'generated/graphqlCodegen';

const useQueryEsgOverview = () => {
  const { json } = useStaticQuery<EsgOverviewQuery>(
    graphql`
      query EsgOverview {
        json: allNodePage(
          filter: { path: { alias: { eq: "/esg/overview" } } }
        ) {
          nodes {
            __typename
            id
            drupal_id
            title
            path {
              alias
            }
            status
            relationships {
              custom: field_ref_custom {
                ...esgBranding
              }
              layout: field_ref_layout {
                ...layoutFull
              }
            }
          }
        }
      }
    `,
  );
  // There is only one /overview page.
  return json.nodes[0];
};

export default useQueryEsgOverview;
