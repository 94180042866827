/**
 * Displays on /esg/financials
 */
import React from 'react';
import { merge } from 'lodash';
import { EsgChartConfig } from 'types';
import Doughnut from '../Doughnut';
import defaultConfig from './config';

/**
 * Chart Configuration
 */
const chartConfig: EsgChartConfig = {
  data: {
    labels: [
      'Within 0-6 Months',
      'Within 7-12 Months',
      'Greater than 12 Months',
    ],
    datasets: [
      {
        data: [90, 9, 1],
        backgroundColor: ['#FF8D1E', '#2175D9', '#80B7EC'],
        borderColor: ['#ffffff', '#ffffff', '#ffffff'],
        borderWidth: 5,
      },
    ],
  },
  // @todo Move these options to a place where multiple doughnut charts can use them.
  options: {
    plugins: {
      datalabels: {
        formatter: value => `${value}%`,
      },
      doughnutLabel: {
        labels: [
          {
            text: 'Begin Earning',
            font: {
              size: '32',
              family: 'futura_ptmedium',
            },
            color: '#66696c',
          },
          {
            text: 'on Investment',
            font: {
              size: '32',
              family: 'futura_ptmedium',
            },
            color: '#66696c',
          },
        ],
      },
    },
  },
};

const config = merge({}, defaultConfig, chartConfig);

const BEIChart = () => <Doughnut name="BEIChart" props={config} />;

export default BEIChart;
