import React, { useContext } from 'react';
import { Regions } from 'types';
import ContentResolver from 'components/Energy/ContentResolver/ContentResolver';
import { regionContext } from 'context/Provider';

const RegionWrapper = (data: Regions) => {
  const { region: contentRegion, regionContent, regionExclude } = data;
  const { region: userRegion } = useContext(regionContext);

  const regionComponents = ContentResolver({ content: regionContent });

  // Show the region if is set on the selection.
  const showRegion = userRegion && contentRegion.includes(userRegion);

  // Show the default if i'ts set to default and the region its not excluded.
  const showDefault =
    userRegion &&
    contentRegion.includes('Default') &&
    !regionExclude.includes(userRegion);

  return (
    <>
      {(showRegion || showDefault) && regionComponents && (
        <>{regionComponents}</>
      )}
    </>
  );
};

export default RegionWrapper;
