import React, { useContext } from 'react';
import { useSpring } from 'react-spring';
import EsgRingFull from './EsgRingFull';
import RingSegments from './RingSegments';
import EsgPageContext from '../Context';

interface Props {
  maxWidth: string;
  all: boolean;
}

/**
 * Animated SVG for the Hero component.
 */
const EsgRing = ({ maxWidth, all = false }: Props) => {
  const { other, esg } = useContext(EsgPageContext);

  // Spring animation props.
  const ringProps = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: { delay: 250, duration: 750 },
  });

  return (
    <>
      <div
        className="relative left-0 right-0 m-auto z-10 bottom-0"
        style={ringProps}
      >
        <div
          className={`absolute bottom-0 right-50p m-auto home-ring-transform ${maxWidth}`}
        >
          {all ? <EsgRingFull /> : <RingSegments esgData={esg} bg />}
          {!all && (
            <h5 className="font-heavy text-xl md:text-2xl text-esg-gray-dark text-center absolute left-0 right-0 -bottom-40 m-0">
              {other.pageTitle}
            </h5>
          )}
        </div>
      </div>
    </>
  );
};

export default EsgRing;
