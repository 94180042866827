import React, { useEffect } from 'react';
import Swiper, { Navigation, Pagination } from 'swiper';
import './carousel.css';

interface Card {
  title: string;
  text: string;
  icon: string;
  number: string;
  cardPadding: string;
}

export interface InfoCardCarouselData {
  cards: Card[];
}

const InfoCardCarousel = (data: InfoCardCarouselData) => {
  const { cards } = data;
  const items = cards.map((card: Card, index) => ({
    ...card,
    text: card.text,
    number: index + 1,
  }));

  const totalSlides: number = items.length;

  useEffect(() => {
    const nextBtn = document.getElementById('swiper-button-next');
    const prevBtn = document.getElementById('swiper-button-prev');
    let slidesPerViewMobile = 1;
    let slidesPerViewDesktop = 1;

    switch (totalSlides) {
      case 1:
        slidesPerViewMobile = 1;
        slidesPerViewDesktop = 1;
        break;
      case 2:
        slidesPerViewMobile = 2;
        slidesPerViewDesktop = 2;
        break;
      case 3:
        slidesPerViewMobile = 2;
        slidesPerViewDesktop = 3;
        break;
      default:
        slidesPerViewMobile = 2;
        slidesPerViewDesktop = 4;
    }

    new Swiper('.swiper', {
      modules: [Navigation, Pagination],
      slidesPerView: 1,
      navigation: {
        nextEl: nextBtn,
        prevEl: prevBtn,
      },
      a11y: {
        itemRoleDescriptionMessage: `slide of carousel of navigable informational slides pertaining`,
      },
      breakpoints: {
        0: {
          slidesPerView: 1,
        },
        640: {
          slidesPerView: slidesPerViewMobile,
        },

        1440: {
          slidesPerView: slidesPerViewDesktop,
        },
      },
    });
  }, [totalSlides]);

  return (
    <>
      <div className="wrapper info-card-carousel">
        <div className="bg-desktop"></div>
        <div className="inner">
          <div
            id="swiper"
            className="swiper"
            role="region"
            aria-label="carousel"
          >
            <div className="swiper-wrapper">
              {items.map(({ number, icon, text, title, cardPadding }) => {
                return (
                  <div className="swiper-slide">
                    <div className="card-wrapper">
                      <atmos-info-card
                        card-number={number}
                        card-icon={icon}
                        text={text}
                        title={title}
                        card-padding={cardPadding}
                        variant="none"
                      ></atmos-info-card>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <button
            type="button"
            aria-controls="swiper"
            id="swiper-button-prev"
            className="swiper-button-prev"
          >
            <span className="icon-container-prev">
              <atmos-icon className="" icon="next" svg="true"></atmos-icon>
            </span>
          </button>
          <button
            type="button"
            aria-controls="swiper"
            id="swiper-button-next"
            className="swiper-button-next"
          >
            <span className="icon-container-next">
              <atmos-icon className="" icon="next" svg="true"></atmos-icon>
            </span>
          </button>
        </div>
      </div>
    </>
  );
};

export default InfoCardCarousel;
