import React from 'react';
import Link from './Link';

export type ButtonProps = {
  color: string;
  text: string;
  textColor?: string;
  link: ButtonAnchor;
  size?: 'big';
  classes?: string;
};

interface ButtonAnchor {
  href: string;
  title: {
    processed: string;
  };
}

const Button = ({
  color,
  textColor,
  link,
  text,
  size,
  classes,
}: ButtonProps) => {
  const defaultClasses =
    size === 'big'
      ? 'font-heavy px-12 py-4 rounded-lg text-sm bigbtn'
      : 'bigbtn';

  const btn = `${defaultClasses} ${classes}`;
  const tc = textColor || 'white';

  return (
    <Link
      className={`${btn} text-${tc} hover:text-${tc} bg-${color} bg-${color}-grad uppercase no-underline`}
      href={link.href}
      title={text || 'Button link needs title'}
      label={text || 'Button link needs label'}
    >
      {text}
    </Link>
  );
};

export default Button;
