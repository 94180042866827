import React from 'react';
import { MetricsFragment } from 'generated/graphqlCodegen';
import MetricCounter from './MetricCounter';

interface Props {
  fieldComponent: MetricsFragment;
}

/**
 * This is custom implementation of Metrics.
 *
 * @see Shared/Metrics
 * @param fieldComponent
 * @param node
 * @constructor
 */
const MetricsBg = ({ fieldComponent }: Props) => {
  if (!fieldComponent.relationships) {
    return null;
  }

  const itemCount = fieldComponent.relationships.metrics.length;

  return (
    <div className="container text-center" datatype="MetricsBg">
      <div className="text-white font-md mx-auto max-w-4xl flex flex-wrap justify-center mb-16">
        {fieldComponent.relationships.metrics.map(value => {
          return (
            <MetricCounter
              key={value.id}
              props={{
                id: value.id,
                prefix: value.prefix,
                number: value.number,
                suffix: value.suffix,
                description: value.description.processed,
              }}
              itemCount={itemCount}
              growingLines
              lightText
            />
          );
        })}
      </div>
    </div>
  );
};

export default MetricsBg;
