import { graphql, useStaticQuery } from 'gatsby';

/**
 * React hook used to get all content that represents a primary ESG page.
 */
const useEsgPages = () => {
  const { esgPages } = useStaticQuery(
    graphql`
      query EsgBranded {
        esgPages: allNodePage(
          filter: {
            relationships: { field_ref_custom: { id: { ne: null } } }
            path: { alias: { ne: "/overview" } }
          }
        ) {
          nodes {
            ...nodesWithBranding
          }
        }
      }
    `,
  );
  return esgPages ? esgPages.nodes : null;
};

export default useEsgPages;
