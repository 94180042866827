import React from 'react';
import RingSegments from '../Ring/RingSegments';

interface MediaOverview extends ParagraphsTypeParagraphsTypeRelationships {
  mediaComponent:
    | 'media__esg-paragraph__media_single'
    | 'media__esg-paragraph__esg_card_overview';
}

const EsgMediaOverviewSingle = ({ mediaComponent }: MediaOverview) => {
  const { id, relationships } = mediaComponent;
  const { mediaImage } = relationships;

  // Prepare esgData.
  const esg = { relationships: { esgMedia: mediaComponent } };

  return (
    <div className="ringRef esg-ring relative m-auto mb-10 sm:mb-10 md:mb-0">
      <>
        <div className="svg-shim-square" />
        <RingSegments
          esgData={esg}
          bg={false}
          renderIcon={false}
          className="absolute top-0 right-0 bottom-0 left-0 w-full h-auto"
          attributes={{ strokeWidth: '9' }}
        />
        <svg
          viewBox="0 0 200 200"
          className="absolute top-0 right-0 bottom-0 left-0 w-full h-auto esg-ring-img-mask"
        >
          <defs>
            <circle id={`circle-${id}`} cx="100" cy="100" r="100" />
          </defs>
          <clipPath id={`clip-${id}`}>
            <use xlinkHref={`#circle-${id}`} overflow="visible" />
          </clipPath>
          <g clipPath={`url(#clip-${id})`}>
            <image
              overflow="visible"
              width="100%"
              height="100%"
              xlinkHref={mediaImage.localFile.publicURL}
            />
          </g>
        </svg>
      </>
    </div>
  );
};

export default EsgMediaOverviewSingle;
