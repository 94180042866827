import React, { Fragment } from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import FadeIn from 'components/Esg/Animations/FadeIn';
// eslint-disable-next-line import/no-cycle
import AccordionItem from 'components/Esg/Accordion/AccordionItem';
import { NodePage, ParagraphAccordion } from 'generated/graphqlCodegen';

// Add the icons we need to the library.
library.add(faChevronRight);

/**
 * Base component for Accordions and AccordionItems
 */
const Accordion = ({ fieldComponent }: ParagraphAccordion, node: NodePage) => {
  const {
    heading,
    relationships: { accordionItems },
  } = fieldComponent;

  return (
    <>
      {accordionItems ? (
        <FadeIn up>
          {heading && (
            <div
              dangerouslySetInnerHTML={{ __html: heading.processed || '' }}
            />
          )}
          <div className="mb-20 p-0 md:pl-10">
            <ul className="text-left w-full rounded !list-none !p-0">
              {accordionItems.map(item => {
                return (
                  // When using .map() you must have a unique key for each item.
                  <Fragment key={item.id}>
                    {item ? <AccordionItem item={item} node={node} /> : null}
                  </Fragment>
                );
              })}
            </ul>
          </div>
        </FadeIn>
      ) : null}
    </>
  );
};

export default Accordion;
