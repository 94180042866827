/**
 * Displays on /esg/safety
 */
import React from 'react';
import { merge } from 'lodash';
import BarChart from '../Bar';
import { EsgChartConfig } from 'types';
import defaultConfig from './config';

/**
 * Configuration for the chart.
 */
const chartConfig: EsgChartConfig = {
  type: 'bar',
  description: 'Recordable Injury Rate (per 200,000 hours worked)',
  data: {
    labels: ['2014', '2015', '2016', '2017', '2018', '2019'],
    datasets: [
      {
        data: [3.0, 3.2, 3.49, 2.91, 2.84, 2.88],
        backgroundColor: '#2175D9',
      },
    ],
  },
  options: {
    title: {
      text: 'OSHA',
    },
    indexAxis: 'x',
    responsive: true,
    maintainAspectRatio: true,
    scales: {
      y: {
        // The maximum Y value.
        max: 4,
        title: {
          display: false,
        },
        ticks: {
          callback(value) {
            const newValue = value.toFixed(2);
            return newValue;
          },
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: ({ formattedValue }) => Number(formattedValue).toFixed(2),
        },
      },
      datalabels: {
        display: false,
      },
    },
  },
};

const config = merge({}, defaultConfig, chartConfig);

/**
 * Recordable Injury Rate
 *
 * @constructor
 */
const Osha = () => <BarChart name="Osha" props={config} />;

export default Osha;
