/**
 * Displays on /esg/corporate-governance
 */
import React from 'react';
import { merge } from 'lodash';
import { EsgChartConfig } from 'types';
import Doughnut from '../Doughnut';
import defaultConfig from './config';

/**
 * Chart configuration
 */
const chartConfig: EsgChartConfig = {
  data: {
    labels: ['< 5 Years', '5-9 Years', '10-15 Years', '> 15 Years'],
    datasets: [
      {
        data: [42.9, 7.1, 21.4, 28.6],
        backgroundColor: ['#00B56C', '#80B7EC', '#FF8D1E', '#2175D9'],
        borderColor: ['#ffffff', '#ffffff', '#ffffff', '#ffffff'],
        borderWidth: 5,
      },
    ],
  },
  options: {
    plugins: {
      datalabels: {
        formatter: value => `${value.toFixed(1)}%`,
      },
      doughnutLabel: {
        labels: [
          {
            text: 'Board Tenure',
            font: {
              size: 32,
              family: 'futura_ptmedium',
            },
            color: '#66696c',
          },
        ],
      },
    },
  },
};

const config = merge({}, defaultConfig, chartConfig);

/**
 * Board Tenure Chart
 *
 * @constructor
 */
const BTChart = () => <Doughnut name="BTChart" props={config} />;

export default BTChart;
