const isMidTexas = (zipCode: number): boolean | null => {
  const MidTexas = [
    75001, 75002, 75006, 75007, 75009, 75010, 75011, 75013, 75014, 75017, 75019,
    75020, 75021, 75022, 75023, 75024, 75025, 75027, 75028, 75029, 75030, 75032,
    75033, 75034, 75035, 75036, 75038, 75039, 75040, 75041, 75042, 75043, 75044,
    75046, 75048, 75050, 75051, 75052, 75053, 75054, 75056, 75057, 75058, 75060,
    75061, 75062, 75063, 75065, 75067, 75068, 75069, 75070, 75071, 75072, 75074,
    75075, 75076, 75077, 75078, 75080, 75081, 75082, 75083, 75087, 75088, 75089,
    75090, 75091, 75092, 75093, 75094, 75098, 75099, 75101, 75102, 75103, 75104,
    75105, 75106, 75109, 75110, 75114, 75115, 75116, 75117, 75118, 75119, 75123,
    75124, 75125, 75126, 75132, 75134, 75135, 75137, 75141, 75142, 75143, 75144,
    75146, 75147, 75148, 75149, 75150, 75152, 75153, 75154, 75155, 75156, 75158,
    75159, 75160, 75161, 75163, 75164, 75165, 75166, 75167, 75169, 75172, 75173,
    75180, 75181, 75182, 75189, 75201, 75202, 75203, 75204, 75205, 75206, 75207,
    75208, 75209, 75210, 75211, 75212, 75214, 75215, 75216, 75217, 75218, 75219,
    75220, 75222, 75223, 75224, 75225, 75226, 75227, 75228, 75229, 75230, 75231,
    75232, 75233, 75234, 75235, 75236, 75237, 75238, 75240, 75241, 75242, 75243,
    75244, 75246, 75247, 75248, 75249, 75251, 75252, 75253, 75254, 75260, 75261,
    75265, 75270, 75287, 75315, 75339, 75354, 75390, 75401, 75402, 75403, 75404,
    75407, 75409, 75410, 75411, 75412, 75414, 75415, 75416, 75417, 75418, 75420,
    75421, 75422, 75423, 75424, 75426, 75428, 75431, 75432, 75433, 75435, 75436,
    75438, 75439, 75440, 75441, 75442, 75443, 75446, 75449, 75452, 75453, 75454,
    75459, 75460, 75461, 75462, 75469, 75470, 75471, 75472, 75473, 75474, 75476,
    75477, 75478, 75479, 75482, 75483, 75485, 75487, 75489, 75490, 75491, 75492,
    75494, 75495, 75496, 75497, 75550, 75554, 75601, 75602, 75603, 75604, 75605,
    75606, 75626, 75633, 75647, 75652, 75654, 75662, 75663, 75684, 75701, 75703,
    75704, 75705, 75706, 75707, 75708, 75709, 75710, 75711, 75713, 75750, 75751,
    75752, 75754, 75756, 75758, 75759, 75762, 75763, 75766, 75770, 75771, 75773,
    75778, 75782, 75783, 75791, 75792, 75801, 75802, 75803, 75824, 75831, 75832,
    75833, 75839, 75840, 75842, 75844, 75846, 75850, 75852, 75855, 75859, 75860,
    75861, 76001, 76002, 76004, 76005, 76006, 76008, 76009, 76010, 76011, 76012,
    76013, 76014, 76015, 76016, 76017, 76018, 76019, 76020, 76021, 76022, 76023,
    76028, 76031, 76033, 76034, 76036, 76039, 76040, 76041, 76043, 76044, 76048,
    76049, 76050, 76051, 76052, 76053, 76054, 76055, 76058, 76059, 76060, 76061,
    76063, 76064, 76065, 76066, 76071, 76073, 76078, 76082, 76084, 76087, 76088,
    76092, 76093, 76098, 76101, 76102, 76103, 76104, 76105, 76106, 76107, 76108,
    76109, 76110, 76111, 76112, 76114, 76115, 76116, 76117, 76118, 76119, 76120,
    76121, 76122, 76123, 76126, 76127, 76129, 76131, 76132, 76133, 76134, 76135,
    76136, 76137, 76139, 76140, 76148, 76153, 76155, 76161, 76162, 76164, 76177,
    76179, 76180, 76182, 76201, 76202, 76203, 76204, 76205, 76206, 76207, 76208,
    76209, 76210, 76225, 76226, 76227, 76228, 76230, 76233, 76234, 76240, 76241,
    76244, 76247, 76248, 76249, 76250, 76251, 76252, 76253, 76255, 76258, 76259,
    76262, 76264, 76265, 76266, 76268, 76270, 76271, 76272, 76273, 76301, 76302,
    76305, 76306, 76307, 76308, 76309, 76310, 76311, 76351, 76354, 76357, 76360,
    76363, 76364, 76365, 76366, 76367, 76369, 76370, 76371, 76372, 76373, 76374,
    76377, 76380, 76384, 76385, 76388, 76401, 76424, 76426, 76429, 76430, 76431,
    76432, 76435, 76437, 76442, 76444, 76445, 76446, 76448, 76453, 76454, 76455,
    76457, 76462, 76463, 76464, 76466, 76469, 76470, 76472, 76475, 76483, 76484,
    76487, 76501, 76502, 76503, 76504, 76505, 76508, 76511, 76513, 76518, 76520,
    76522, 76524, 76525, 76528, 76530, 76531, 76534, 76540, 76541, 76542, 76543,
    76544, 76547, 76548, 76549, 76550, 76554, 76557, 76559, 76561, 76565, 76567,
    76569, 76570, 76574, 76577, 76578, 76579, 76596, 76597, 76598, 76621, 76623,
    76626, 76627, 76629, 76630, 76632, 76634, 76635, 76636, 76638, 76639, 76640,
    76641, 76642, 76643, 76645, 76648, 76649, 76651, 76653, 76655, 76656, 76657,
    76660, 76661, 76664, 76665, 76667, 76670, 76671, 76676, 76679, 76680, 76681,
    76682, 76684, 76686, 76687, 76689, 76690, 76691, 76692, 76693, 76701, 76702,
    76703, 76704, 76705, 76706, 76707, 76708, 76710, 76711, 76712, 76714, 76798,
    76801, 76802, 76803, 76804, 76821, 76823, 76834, 76844, 76853, 76857, 76861,
    76875, 76877, 76878, 76880, 76882, 76884, 76901, 76902, 76903, 76904, 76905,
    76908, 76909, 76933, 76934, 76945, 77484, 77493, 77494, 77801, 77802, 77803,
    77805, 77807, 77808, 77830, 77836, 77837, 77840, 77841, 77842, 77843, 77845,
    77856, 77859, 77861, 77864, 77868, 77871, 77872, 77879, 78003, 78010, 78013,
    78028, 78605, 78611, 78613, 78617, 78624, 78626, 78627, 78628, 78633, 78634,
    78641, 78642, 78643, 78653, 78654, 78660, 78664, 78665, 78680, 78681, 78682,
    78691, 78704, 78717, 78724, 78725, 78726, 78727, 78728, 78729, 78750, 78753,
    78754, 78762, 78947, 79201, 79225, 79227, 79238, 79247, 79252, 79259, 79501,
    79503, 79504, 79505, 79506, 79508, 79510, 79512, 79516, 79520, 79521, 79525,
    79526, 79529, 79530, 79532, 79533, 79534, 79535, 79536, 79538, 79539, 79540,
    79541, 79543, 79544, 79545, 79546, 79547, 79549, 79553, 79556, 79560, 79561,
    79562, 79563, 79566, 79567, 79601, 79602, 79603, 79604, 79605, 79606, 79607,
    79697, 79699, 79706, 79730,
  ];

  return MidTexas.includes(zipCode);
};

export default isMidTexas;
