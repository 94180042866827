/* eslint import/no-cycle: "warn" */
import React, { Fragment } from 'react';
import mediaResolver from 'utils/mediaResolver';
import ComponentResolver from 'utils/componentResolver';
import { MediaGridFragment, NodePage } from 'generated/graphqlCodegen';

/**
 * Base component used to render a grid of media components.
 *
 * Determines the more specific media grid component responsible for the render.
 * Called directly from ComponentResolver.
 *
 * @param fieldComponent
 * @param node
 * @constructor
 */
const MediaGridBase = (fieldComponent: MediaGridFragment, node: NodePage) => {
  const { relationships, __typename: type, id } = fieldComponent;
  if (!relationships) {
    return null;
  }

  // We only need a title for now but we don't want to limit this component.
  // The "components" represent a Basic Feature that can have a title + more.
  const { components, mediaUnlimited } = relationships;
  // Get the react component for our feature.
  const feature = ComponentResolver(components, node);
  // Determines the number of columns we will use for this grid, 3 or 4.
  const columns = mediaUnlimited.length % 2 === 0 ? 3 : 4;

  return (
    <>
      <div id={id} className="min-h-20" datatype="MediaGridBase">
        {feature}
        <figure
          className="flex flex-wrap items-center my-4 p-0"
          datatype="Media grid items"
        >
          {mediaUnlimited.map(media => {
            const mediaComponent = mediaResolver(media, type, { columns });
            return (
              <Fragment key={media.id}>
                {mediaComponent && <>{mediaComponent}</>}
              </Fragment>
            );
          })}
        </figure>
      </div>
    </>
  );
};

export default MediaGridBase;
