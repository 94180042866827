import { withPrefix } from 'gatsby';
import React from 'react';

const MultiColumn = data => {
  const {
    leftColumn: { text: leftText },
    middleColumn: { text: middleText },
    rightColumn: { text: rightText },
    footerLink: {
      embed: {
        text: linkText,
        attributes: { href, target, rel, title: linkTitle },
      },
    },
  } = data;

  return (
    <atmos-multi-column
      left-col-text={leftText}
      mid-col-text={middleText}
      right-col-text={rightText}
      icon="envelope"
      icon-text={linkText}
      icon-link={href.length ? withPrefix(href) : null}
      target={target}
      rel={rel}
      link-title={linkTitle}
    />
  );
};

export default MultiColumn;
