import React from 'react';
import { ComponentGroupFragment, NodePage } from 'generated/graphqlCodegen';
import SEO from '../Shared/SEO';
import ComponentResolver from '../../../utils/componentResolver';

type ColumnRelationships = {
  primary: ComponentGroupFragment[];
};

interface Props {
  columns: ColumnRelationships;
  node: NodePage;
}

const FullBleed = ({ columns, node }: Props) => {
  // Destructure our columns
  const { primary } = columns;

  // Build the components
  const components = ComponentResolver(primary, node);

  return (
    <>
      <SEO title={` | Atmos Energy`} />
      {components && <>{components}</>}
    </>
  );
};

export default FullBleed;
