import { useCallback } from 'react';
import useEventListener from './useEventListener';

/**
 * React Hook
 *
 * This hook will only "listen" while the element is in the viewport
 *
 * @param target The element we are targeting
 * @param strength A numerical value used to increase/decrease the speed
 */
export default function useHeroScroll(target: string, strength: number) {
  const parallaxHandler = useCallback(() => {
    const element: HTMLElement | null = document.querySelector(target);
    if (element) {
      element.style.top = `${+(window.pageYOffset * strength)}px`;
    }
  }, [strength, target]);

  useEventListener('scroll', parallaxHandler);
}
