import React, { useEffect, useState } from 'react';

interface GLAnimation {
  w: string;
  mb: string;
  animate?: boolean;
}

const GrowingLine = ({ w, mb, animate = true }: GLAnimation) => {
  const [lineClasses, growLine] = useState('opacity-0');

  useEffect(() => {
    if (animate) {
      growLine(
        'opacity-100 transition transition-delay-longest transition-slow w-full line-grow',
      );
    } else {
      growLine('opacity-100');
    }
  }, [animate]);

  return (
    <div
      className={`rule-anim ${w} border-t border-esg-green m-auto ${mb} ${lineClasses}`}
    />
  );
};

export default GrowingLine;
