import React, { useState } from 'react';
import SlicePadding from 'components/Energy/Layout/SlicePadding';
import useSlottedImage from 'hooks/useSlottedImage';
import { getImage, ImageDataLike } from 'gatsby-plugin-image';
import BackgroundImage from 'gatsby-background-image';
import { convertToBgImage } from 'gbimage-bridge';
import './accordionPerson.css';
import GetPersonNodes from 'utils/GetPersonNodes';

export interface PersonData {
  clean: boolean;
  singlePanel: boolean;
  allOpen: boolean;
  gradient: boolean;
  people: AccordionItemData[];
  topspacing: string;
  bottomspacing: string;
}

export interface AccordionItemData {
  accordionItem: {
    embed: {
      embedHTML: string;
      attributes: {
        'data-entity-uuid': string;
      };
    };
  };
  image: {
    embed: {
      attributes: {
        'data-media-url': string;
      };
    };
  };
}

const AccordionPerson = (data: PersonData) => {
  const {
    clean,
    singlePanel,
    allOpen,
    gradient,
    people,
    topspacing,
    bottomspacing,
  } = data;

  const [open, toggleOpen] = useState(false);

  const isSinglePanel = singlePanel ? 'true' : null;
  const isClean = clean ? 'true' : null;
  const isAllOpen = allOpen ? 'true' : null;
  const isGradient = gradient ? 'true' : null;

  const toHyphen = (input: string) => {
    const keyString = input.replace(/\s+/g, '-').toLowerCase();
    return keyString;
  };

  function GetSlotted(img, cls, slot) {
    return useSlottedImage(img, cls, slot);
  }

  // Get the markup from uuid or image url.
  function getBackgroundImage(localFile: ImageDataLike) {
    // Get the image.
    const image = getImage(localFile);

    // Get the background image.
    const bgImage = convertToBgImage(image);

    return (
      <div slot="image">
        <div className="circle">
          <BackgroundImage
            className="bg-image"
            style={{
              position: 'initial',
              height: 1000,
              width: 1000,
              minHeight: 1000,
              minWidth: 1000,
            }}
            {...bgImage}
          />
        </div>
      </div>
    );
  }

  function GetPersonFile(personNode) {
    // Sets the "localFile" variable.
    const {
      0: { relationships },
    } = personNode;

    const field_photo =
      relationships && relationships.field_photo
        ? relationships.field_photo
        : null;

    const field_photo_relationships =
      field_photo && field_photo.relationships
        ? field_photo.relationships
        : null;

    const media_image =
      field_photo_relationships && field_photo_relationships.field_media_image
        ? field_photo_relationships.field_media_image
        : null;

    return media_image && media_image.localFile ? media_image.localFile : null;
  }

  return (
    <SlicePadding top={topspacing} bottom={bottomspacing}>
      <atmos-accordion
        clean={isClean}
        single-panel={isSinglePanel}
        allopen={isAllOpen}
      >
        <>
          {people.map(person => {
            const {
              accordionItem: {
                embed: { attributes },
              },
              image,
            } = person;

            // Drupal stores the uuid of the media entity in the "data-entity-uuid"
            // attribute.
            const entityUUID = attributes['data-entity-uuid'];

            const {
              allNodePerson: { nodes },
            } = GetPersonNodes();

            const personNode = nodes.filter(
              node => node.drupal_id === entityUUID,
            );

            const {
              0: { title: personName, body, field_job_title },
            } = personNode;

            const localFile = entityUUID ? GetPersonFile(personNode) : null;

            const bgImage = localFile ? getBackgroundImage(localFile) : '';
            const markupContentImage = GetSlotted(image, 'image', '');
            const jobTitle = field_job_title || 'name';
            const bio = (body && body.processed) || 'bio information';
            return (
              <atmos-accordion-panel
                key={`accordion-panel-${toHyphen(jobTitle)}`}
                slot="panels"
                gradient={isGradient}
                person-accordion
                data-accordion-person={personName}
                data-accordion-open={open}
                onClick={() => toggleOpen(!open)}
              >
                <atmos-person title={jobTitle} name={personName} slot="heading">
                  {bgImage}
                </atmos-person>
                <atmos-wysiwyg markup={bio} />
                {markupContentImage}
              </atmos-accordion-panel>
            );
          })}
        </>
      </atmos-accordion>
    </SlicePadding>
  );
};

export default AccordionPerson;
