import { EsgChartConfig } from 'types';

const defaultConfig: EsgChartConfig = {
  type: 'line',
  options: {
    // Curve the line a bit.
    tension: 0.5,
    aspectRatio: 1.6,
    responsive: true,
    scales: {
      y: {
        ticks: {
          callback: value =>
            value.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            }),
        },
      },
      x: {},
    },
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
      tooltip: {
        callbacks: {
          labelColor: () => ({ backgroundColor: '#FF8D1E' }),
          label: ({ formattedValue }) =>
            Number(formattedValue).toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            }),
        },
      },
    },
  },
};

export default defaultConfig;
