import { EsgChartConfig } from 'types';

const defaultConfig: EsgChartConfig = {
  type: 'doughnut',
  options: {
    // The percentage of the cutout in the center of the doughnut chart.
    cutout: '70%',
    responsive: true,
    layout: {
      padding: 40,
    },
    animation: {
      animateScale: true,
      animateRotate: true,
      duration: 2500,
    },
    plugins: {
      datalabels: {
        // Most Doughnut charts render as a percentage.
        formatter: (value, { dataset: { data } }) => {
          const sum = data.reduce((a, b) => a + b, 0);
          return `${((value * 100) / sum).toFixed(2)}%`;
        },
        font: {
          family: 'futura_ptheavy',
          size: 17,
        },
        padding: 5,
        // Align the label with outer edge of the sections.
        align: 'end',
        // Anchor the label to the outside of the sections.
        anchor: 'end',
      },
      tooltip: {
        callbacks: {
          label: ({ label, formattedValue }) => ` ${label}: ${formattedValue}%`,
        },
      },
      // Settings for the legend that is displayed at the bottom of this chart.
      legend: {
        display: true,
        fullSize: true,
        position: 'bottom',
        padding: 40,
        // Using the title's padding to create some space between the doughnut
        // chart and legend.
        title: {
          display: true,
          text: '',
          padding: 10,
        },
        labels: {
          font: {
            size: 16,
            family: 'futura_ptbook',
          },
          boxWidth: 15,
        },
      },
    },
  },
};

export default defaultConfig;
