import { graphql, useStaticQuery } from 'gatsby';
import { GetAlertsQuery } from 'generated/graphqlCodegen';

const GetAlerts = () => {
  return useStaticQuery<GetAlertsQuery>(
    graphql`
      query GetAlerts {
        allNodeAlert {
          nodes {
            status
            relationships {
              field_alert_type {
                name
              }
            }
            title
            body {
              value
            }
            field_cta {
              title
              uri
            }
          }
        }
      }
    `,
  );
};

export default GetAlerts;
