/* eslint react/no-unused-prop-types: "warn" */
import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useInView } from 'react-intersection-observer';
import FadeIn from 'components/Esg/Animations/FadeIn';
import { BtnTableFinancialsQuery } from 'generated/graphqlCodegen';
import { ParagraphComponent } from 'types';

interface ByTheNumbersTableComponent extends ParagraphComponent {
  field_custom_component_item: string;
}

/**
 * Displays on /esg/financials
 *
 * Source File: project/src/data/json/by-the-numbers-table-financials.json
 */
const BTNTableFinancials = (fieldComponent: ByTheNumbersTableComponent) => {
  const { drupal_id } = fieldComponent;
  // Data: by-the-numbers-table-financials.json
  const { data } = useStaticQuery<BtnTableFinancialsQuery>(
    graphql`
      query BTNTableFinancials {
        data: allByTheNumbersTableFinancialsJson {
          nodes {
            th {
              text
              label
            }
            tr {
              data
            }
          }
        }
      }
    `,
  );

  // Destructure our nodes
  const { th, tr } = data.nodes[0];

  // Lets us know when the component is in the viewport.
  const [ref, inView] = useInView({
    // Number between 0 and 1 indicating the percentage that should be visible
    // before triggering. Can also be an array of numbers, to create multiple
    // trigger points.
    threshold: 0.2,
    triggerOnce: true,
  });

  return (
    <div
      datatype="BTNTableFinancials"
      key={drupal_id}
      ref={ref}
      className="min-h-20"
    >
      {inView && (
        <FadeIn up>
          <table className="esg-matrix leading-tight my-20">
            <thead>
              <tr>
                {th.map(({ label, text }) => {
                  return (
                    <th
                      // When using .map() you must have a unique key for each item.
                      key={`${text ? `${text}-th` : 'no-text-th'}`}
                      className="hidden lg:table-cell lg:first:text-left lg:first:pl-3"
                      dangerouslySetInnerHTML={{ __html: text }}
                      aria-label={label || text}
                      scope="col"
                    />
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {tr.map((row, rowIndex: number, rowArray: { length: number }) => {
                const lastRow = rowArray.length - 1 === rowIndex;
                return (
                  <tr key={`${row.data[0]}-tr`}>
                    {row.data.map((value: string, dataIndex: number) => {
                      let date;
                      let price;
                      if (lastRow && dataIndex > 0) {
                        [date, price] = value.split('|');
                      }
                      // HACK! due to the one-off styling of the "Stock Price"
                      // data if we are on the last row
                      return (
                        <td
                          // When using .map() you must have a unique key for each item.
                          key={`${value}-td`}
                          className="first:font-bold first:text-left lg:first:font-normal lg:first:pl-3"
                          // dangerouslySetInnerHTML={{ __html: value }}
                          data-label={
                            dataIndex !== 0 ? th[dataIndex].label : null
                          }
                        >
                          <>
                            {date ? (
                              <>
                                <div className="text-xs">{date}</div>
                                {price}
                              </>
                            ) : (
                              value
                            )}
                          </>
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </FadeIn>
      )}
    </div>
  );
};

export default BTNTableFinancials;
