import React from 'react';
import { useInView } from 'react-intersection-observer';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { MediaImageFragment } from 'generated/graphqlCodegen';
import FadeIn from '../Animations/FadeIn';
import columnsResolver from '../../../utils/columnsResolver';
import Link from '../Shared/Link';

interface Props {
  mediaComponent: MediaImageFragment;
  columns: number;
}

/**
 * Renders an image in a grid.
 *
 * @param media
 * @param columns
 * @constructor
 */
const MediaGridImage = ({ mediaComponent, columns }: Props) => {
  let title;
  let uri;
  const { mediaDisplay, mediaImageMeta, mediaLink, relationships } =
    mediaComponent;
  const { mediaImage } = relationships;

  const isRound = mediaDisplay && mediaDisplay === 'round';
  const classes = isRound
    ? 'shadow-md border-4 rounded-full border-transparent hover:border-white'
    : '';

  const columnsWidth = columnsResolver(columns);

  if (mediaLink) {
    title = mediaLink.title;
    uri = mediaLink.uri;
  }
  // Lets us know when the image is in the viewport.
  const [ref, inView] = useInView({
    // Number between 0 and 1 indicating the percentage that should be visible
    // before triggering. Can also be an array of numbers, to create multiple
    // trigger points.
    threshold: 0.8,
    triggerOnce: true,
  });

  // Get the image.
  const image = getImage(mediaImage.localFile);

  return (
    <div
      ref={ref}
      key={mediaImage.id}
      className={`w-8/12 mx-auto md:w-1/2 xl:${columnsWidth} mb-4 px-2 relative full-visible`}
      datatype="MediaGridImage"
      // @todo: Use media grid for MediaLinkImage as well...we don't need both
      // className={`w-8/12 md:w-4/12 mx-auto mb-8 px-4 relative`}
    >
      <>
        {inView && (
          <FadeIn up>
            {/* Actual images, not svg */}
            {image ? (
              <GatsbyImage
                key={mediaImage.id}
                className={`mb-6 ${classes}`}
                image={image}
                alt={mediaImageMeta.alt}
                title={mediaImageMeta.title}
              />
            ) : (
              // Render svg.
              <img
                key={mediaImage.id}
                className={`mb-6 ${classes}`}
                src={mediaImage.localFile.publicURL}
                alt={mediaImageMeta.alt}
                title={mediaImageMeta.title}
              />
            )}
            {title && <div>{title}</div>}
            {uri && (
              <Link
                className="absolute top-0 right-0 bottom-0 left-0"
                href={uri}
                title={title || 'Media needs title'}
                label={title || 'Media needs title'}
              />
            )}
          </FadeIn>
        )}
      </>
    </div>
  );
};

export default MediaGridImage;
