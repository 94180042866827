import React from 'react';

/**
 * Heading Component
 *
 */

const Heading = data => {
  const { level, levelSize, levelStyle, text } = data;

  return (
    <outline-container>
      <outline-heading
        level={level}
        level-size={levelSize}
        level-style={levelStyle}
      >
        {text}
      </outline-heading>
    </outline-container>
  );
};

export default Heading;
