import React from 'react';

const RecognizingLeak = data => {
  const { title, cards, icons, overlayBottom, phoneCta } = data;
  const cardItems = cards.map(i => ({ text: i.text.text, icon: i.icon }));
  const iconItems = icons.map(i => ({ text: i.text.text, number: i.number }));

  const opts = {};
  if (overlayBottom) {
    opts['overlay-bottom'] = true;
  }
  return (
    <div className="recognizing-leak">
      <atmos-recognizing-leak
        phone-cta={phoneCta}
        title={title}
        cards={JSON.stringify([...cardItems])}
        icons={JSON.stringify([...iconItems])}
        {...opts}
      />
    </div>
  );
};

export default RecognizingLeak;
