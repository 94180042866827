import React, { useContext } from 'react';
import { useInView } from 'react-intersection-observer';
import SpringNumbers from '../Animations/SpringNumbers';
import FadeIn from '../Animations/FadeIn';
import GrowingLine from '../Animations/GrowingLine';
import EsgPageContext from '../Context';

export interface MetricCounterCard {
  props: MetricCounterFields;
  itemCount: number;
  growingLines?: boolean;
  lightText?: boolean;
}

export interface MetricCounterFields {
  id: string;
  number: number[];
  prefix?: string;
  suffix?: string;
  description?: string;
}

/**
 *
 * @param props
 * @constructor
 */
const MetricCounter = ({
  props,
  itemCount,
  growingLines = false,
  lightText = false,
}: MetricCounterCard) => {
  // Props
  const { prefix, number, suffix, description, id } = props;
  const { esg } = useContext(EsgPageContext);
  const { color } = esg.relationships.esgMedia;
  let themeColor = color ? `text-${color}` : 'text-white';
  themeColor = lightText ? 'text-white' : themeColor;
  const descriptionColor =
    themeColor === 'text-white' ? themeColor : 'text-esg-gray-dark';

  // Lets us know when the stat is in the viewport.
  const [ref, inView] = useInView({
    // Number between 0 and 1 indicating the percentage that should be visible
    // before triggering. Can also be an array of numbers, to create multiple
    // trigger points.
    threshold: 0.5,
    triggerOnce: true,
  });

  const numCol = itemCount % 3 === 0 ? '3' : '2';
  const classes = growingLines
    ? `w-full md:w-1/2 lg:w-1/${numCol} px-1 py-4 md:p-4 md:pb-8 xl:p-5 xl:pb-8`
    : `${themeColor} md:p-4 lg:w-1/${numCol} xl:p-5 w-full px-1 py-4`;

  // This component should fade in and up.
  return (
    <div key={id} ref={ref} className={classes} datatype="MetricCounter">
      {inView && (
        <FadeIn up>
          <div className="px-2">
            {growingLines && <GrowingLine w="w-1" mb="mb-5" />}
            <h2 className="count-up text-4xl lg:text-3xl xl:text-4xl tracking-tighter font-md mb-0">
              {prefix && <span className="stat-prepend">{prefix}</span>}
              <SpringNumbers numbers={number} />
              {suffix && <span className="stat-append">{suffix}</span>}
            </h2>
            {description && (
              <div
                className={`${descriptionColor}`}
                dangerouslySetInnerHTML={{ __html: description }}
              />
            )}
          </div>
        </FadeIn>
      )}
    </div>
  );
};

export default MetricCounter;
