import React from 'react';
import { Spring, animated } from 'react-spring';

interface Props {
  numbers: number[];
}

const SpringNumbers = ({ numbers }: Props) => {
  return (
    <>
      {numbers &&
        numbers.map((number, index: number, array: { length: any }) => {
          const { length } = array;
          const isLast = length - 1 === index;
          const separator = isLast && length > 1 ? '-' : '';
          return (
            <Spring
              // When using .map() you must have a unique key for each item.
              key={number}
              from={{ number: 0 }}
              number={number}
              config={{ delay: 250, duration: 1500 }}
            >
              {({ number: target }) => {
                return (
                  <animated.span>
                    {target.to(n => {
                      return `${separator}${Math.floor(n)
                        .toString(10)
                        .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}`;
                    })}
                  </animated.span>
                );
              }}
            </Spring>
          );
        })}
    </>
  );
};

export default SpringNumbers;
