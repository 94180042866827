import React, { useState, useEffect } from 'react';
import { graphql, navigate } from 'gatsby';
import Webform from 'gatsby-drupal-webform';
import {
  WebformElement,
  WebformObject,
} from 'gatsby-drupal-webform/dist/Webform';
import EnergyHeader from 'components/Header/EnergyHeader';
import EnergyFooter from 'components/Footer/EnergyFooter';
import SelectField from 'components/Energy/Webform/customComponents/SelectField';
import { WebformTemplate } from 'types';
import WebformStyles from '../styles/energy/WebformStyles';
import '../styles/energy/index.css';
import { isEmpty } from 'lodash';

interface WebformObjectProps extends WebformObject {
  id: string;
  title: string;
}

const captchaEndpoint = process.env.GATSBY_CAPTCHA_ENDPOINT;
const postEndpoint = process.env.GATSBY_WEBFORM_ENDPOINT;

export const WebformPageQueryResults = graphql`
  query WebformPage($webformId: String!) {
    form: allWebformWebform(
      filter: {
        drupal_internal__id: { eq: $webformId }
        status: { eq: "open" }
      }
    ) {
      nodes {
        __typename
        id
        title
        drupal_internal__id
        description
        status
        elements {
          name
          type
          attributes {
            name
            value
          }
          options {
            label
            value
          }
        }
      }
    }
  }
`;

// Contact form id: e028a01c-9a32-400d-b809-0ba62bd2184c

const WebformPage = ({ data, pageContext }: WebformTemplate) => {
  const {
    form: {
      nodes: { 0: originalWebform },
    },
  } = data;

  // Store the webform in state so we can update it.
  const [webform, updateWebform] =
    useState<WebformObjectProps>(originalWebform);

  // Calls captcha endpoint and stores the image in state.
  const [captchaImage] = useState(
    `<img style="width: 200px" src="${captchaEndpoint}" alt="CAPTCHA" />`,
  );
  const [hasCaptcha, setHasCaptcha] = useState(false);

  const [submitted, setSubmitted] = useState(false);
  const [isError, setError] = useState(false);
  const [systemMessage, setMessage] = useState('Thanks for your submission');
  const [buttonDisabled, setButtonDisabled] = useState(false);

  // todo: Get this value from the webform in Drupal is there's a way to get it.
  const [errorMessage, setErrorMessage] = useState(
    "Sorry but we couldn't submit your form at this time. Please try again later.",
  );

  // @todo: Move this to a utility so we can add all the Drupal specific urls
  // like "<front>";
  const urlMap = new Map();
  urlMap.set('<front>', '/');

  const { menus } = pageContext;

  // const formWithCaptcha = <CaptchaForm webform={webform} />;

  // The webform's title.
  const { title } = webform;

  const webformElements: WebformElement[] = webform.elements || [];

  // Triggered on initial render only.
  useEffect(() => {
    // TODO: TS does not like this but it works; declare this as a module
    import('../../.design/outline.js');
    import('../../.design/outline.theme.css');

    // Define captcha webform elements for the form.
    const captchaImageElement: WebformElement = {
      name: 'captcha_image',
      type: 'webform_markup',
      options: undefined,
      attributes: [
        {
          name: '#markup',
          value: captchaImage,
        },
      ],
    };

    const captchaTextfieldElement: WebformElement = {
      name: 'captcha',
      type: 'textfield',
      options: undefined,
      attributes: [
        {
          name: '#title',
          value: 'Please enter the values from the image.',
        },
        {
          name: '#required',
          value: 'true',
        },
        { name: '#size', value: '6' },
        { name: '#maxlength', value: '5' },
      ],
    };

    // Add the captcha elements to the webform.
    if (!hasCaptcha && webformElements.length > 0) {
      setHasCaptcha(true);
      // Insert our captcha elements just before the submit button.
      // The submit button is not part of the webformElements array, so simply add captcha at the end of the array.
      webformElements.splice(
        webformElements.length,
        0,
        ...[captchaImageElement, captchaTextfieldElement],
      );
      // Update the webform state with the extra captcha elements.
      updateWebform(updatedWebform => ({
        ...updatedWebform,
        elements: [...webformElements],
      }));
    }
  }, []);

  if (!data) {
    return <h1>No Data passed to WebformPage!!!</h1>;
  }

  if (!originalWebform) {
    return <h1>Data is missing a webform!!!</h1>;
  }

  /**
   * Called when the webform is successfully submitted.
   *
   * @param response - The response from the webform submission.
   */
  const formSuccess = (response: any) => {
    if (!isEmpty(response.settings.confirmation_message)) {
      setMessage(response.settings.confirmation_message);
    }
    setSubmitted(true);
    setButtonDisabled(true);
    const url = response.settings.confirmation_url;
    if (url) {
      const redirect = urlMap.get(url) || url;
      setTimeout(() => navigate(redirect), 2000);
    }
  };

  /**
   * Called when the webform is unsuccessfully submitted.
   *
   * @param error - The error from the webform submission.
   */
  const formError = (error: any) => {
    setError(true);
    setErrorMessage(errorMessage);
    // Use this for the actual error message; get rid of conditional.
    if (error) {
      // setErrorMessage(error.message);
    }
  };

  /**
   * Renders a message to the user.
   *
   * @returns The webform message to be rendered.
   */
  const renderMessageAfterSubmit = () => {
    const messageType = submitted ? 'success' : isError ? 'error' : 'unknown';
    const message = submitted ? systemMessage : isError ? errorMessage : null;
    if (message) {
      return (
        <div className="message-wrapper mt-10">
          <atmos-system-message title={messageType} type={messageType}>
            <p>{message}</p>
          </atmos-system-message>
        </div>
      );
    }
    return null;
  };

  /**
   * A custom button component that is disabled when the form is submitted.
   *
   * @returns The webform submit button.
   */
  const CustomButton = () => {
    const classes = submitted
      ? 'submission-success'
      : isError
      ? 'submission-error'
      : '';
    return (
      <button className={classes} type="submit" disabled={buttonDisabled}>
        {submitted ? 'Submitted' : 'Submit'}
      </button>
    );
  };

  return (
    <>
      <EnergyHeader menus={menus} />
      <WebformStyles />
      {hasCaptcha ? (
        <div className="form-wrapper">
          {title && (
            <h1 className="mr-5 ml-5 md:ml-auto md:mr-auto max-w-3xl">
              {title}
            </h1>
          )}
          <div className="form-message">{renderMessageAfterSubmit()}</div>
          {hasCaptcha ? (
            <Webform
              id="webform"
              className="mr-5 ml-5 md:ml-auto md:mr-auto max-w-3xl"
              webform={webform}
              endpoint={`${postEndpoint}`}
              customComponents={{
                select: SelectField,
              }}
              buttonComponent={CustomButton}
              onSuccess={response => formSuccess(response)}
              onError={error => formError(error)}
            />
          ) : (
            '...Loading form'
          )}
          <div className="form-message">{renderMessageAfterSubmit()}</div>
        </div>
      ) : (
        <h1>Loading...</h1>
      )}
      <EnergyFooter menus={menus} />
    </>
  );
};

export default WebformPage;
