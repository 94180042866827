/**
 * Layout components that queries for data
 * with Gatsby's useStaticQuery components
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
import React, { useEffect, ReactNode } from 'react';
// import Header from 'components/Header/Header';
import EnergyHeader from 'components/Header/EnergyHeader';
import EnergyFooter from 'components/Footer/EnergyFooter';
import SEO from './SEO';

interface LayoutProps {
  children?: ReactNode;
  className?: string;
  menus: any;
}

const Layout = ({ children, className, menus }: LayoutProps) => {
  useEffect(() => {
    import('../../../../.design/outline.js');
    import('../../../../.design/outline.theme.css');
  }, []);

  return (
    <>
      <div className="font-book">
        <SEO />
        <EnergyHeader menus={menus} />
        <main className={className}>{children}</main>
        <EnergyFooter menus={menus} />
      </div>
    </>
  );
};

export default Layout;
