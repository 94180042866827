const isWestTexas = (zipCode: number): boolean | null => {
  const WestTexas = [
    79009, 79010, 79012, 79015, 79016, 79018, 79019, 79021, 79022, 79025, 79027,
    79029, 79031, 79032, 79035, 79036, 79039, 79041, 79042, 79043, 79044, 79045,
    79052, 79054, 79058, 79063, 79064, 79065, 79066, 79068, 79072, 79073, 79078,
    79082, 79085, 79086, 79088, 79091, 79092, 79097, 79098, 79101, 79102, 79103,
    79104, 79105, 79106, 79107, 79108, 79109, 79110, 79111, 79114, 79116, 79117,
    79118, 79119, 79120, 79121, 79124, 79168, 79178, 79185, 79189, 79221, 79231,
    79235, 79241, 79243, 79244, 79250, 79255, 79257, 79258, 79261, 79311, 79312,
    79313, 79316, 79322, 79323, 79325, 79329, 79331, 79336, 79339, 79342, 79343,
    79345, 79347, 79350, 79351, 79355, 79356, 79357, 79358, 79359, 79360, 79363,
    79364, 79366, 79367, 79368, 79369, 79371, 79373, 79377, 79378, 79380, 79381,
    79382, 79383, 79401, 79402, 79403, 79404, 79407, 79408, 79409, 79410, 79411,
    79412, 79413, 79414, 79415, 79416, 79423, 79424, 79430, 79452, 79453, 79457,
    79464, 79491, 79493, 79511, 79701, 79702, 79703, 79705, 79706, 79707, 79711,
    79712, 79714, 79720, 79721, 79733, 79739, 79748, 79749, 79755, 79760, 79761,
    79762, 79763, 79764, 79765, 79766, 79768, 79782, 79783,
  ];

  return WestTexas.includes(zipCode);
};

export default isWestTexas;
