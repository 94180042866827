import React from 'react';
import { Helmet } from 'react-helmet';

const WebformStyles = () => {
  return (
    <Helmet>
      <style type="text/css">
        {`
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          font-family: 'Futura Std Bold', 'Helvetica', 'Arial', 'sans-serif';
        }
        .hide-field {
          display: none;
        }
        .message-wrapper {
          margin-bottom: 20px;
        }
        .form-wrapper {
          margin-bottom: 40px;
          margin-top: 40px;
          margin-left: auto;
          margin-right: auto;
          max-width: 1134px;
        }
        .form-group {
          margin-bottom: 37px;
        }
        .form-group:not(.form-check) > label {
          display: block;
          margin-bottom: 4px;
        }
        .form-group:not(.form-check) > input {
          border: solid 1px #2c2c2c;
          height: 48px;
          margin-bottom: 4px;
          padding: 5px 16px;
          width: 100%;
        }
        .form-group:not(.form-check) > input:focus {
          border: solid 1px #2175d9;
          outline: 2px solid #2175d9;
        }
        .form-group textarea {
          border: solid 1px #2c2c2c;
          height: 156px;
          margin-bottom: 4px;
          padding: 5px 20px;
          width: 100% !important;
        }
        .form-group textarea:focus {
          border: solid 1px #2175d9;
          outline: 2px solid #2175d9;
        }
        .invalid-feedback {
          font-size: 14px;
          color: #DE0000;
        }
        .form-group input[type='checkbox'] {
          width: 0;
        }
        .form-group input[type='checkbox'] ~ label::before {
          border: solid 1px #2c2c2c;
          content: '';
          display: inline-block;
          height: 24px;
          margin-right: 16px;
          transform: translate(0px, 6px);
          width: 24px;
        }
        .form-group input[type='checkbox']:checked ~ label::before {
          background-image: url('/icon-check.png');
          background-repeat: no-repeat;
          background-position: center;
          border-color: #2175d9;
          background-color: #2175d9;
        }
        .invalid-feedback:before {
          background-image: url('/icon-error.png');
          background-size: cover;
          display: inline-block;
          content: '';
          height: 16px;
          width: 17px;
          margin-right: 5px;
          transform: translate(0, 3px);
        }
        .form-group input[type='radio'] {
          width: 0;
        }
        .form-group input[type='radio'] ~ label {
          position: relative;
        }
        .form-group input[type='radio'] ~ label::before {
          border-radius: 50%;
          border: solid 1px #2c2c2c;
          content: '';
          display: inline-block;
          height: 24px;
          margin-right: 16px;
          margin-bottom: 16px;
          transform: translate(0px, 22px);
          width: 24px;
        }
        .form-group input[type='radio']:checked ~ label::before {
          background-repeat: no-repeat;
          background-position: center;
          border-color: #2175d9;
          background-color: #2175d9;
        }
        .form-group input[type='radio']:checked ~ label::after {
          border-radius: 50%;
          background-color: white;
          content: '';
          display: inline-block;
          height: 10px;
          margin-right: 16px;
          margin-bottom: 16px;
          transform: translate(0px, 22px);
          width: 10px;
          position: absolute;
          left: 7px;
          top: -16px;
        }
        .form-group .select-field {
          margin-bottom: 5px;
          position: relative;
          width: 250px;
        }
        .form-group .select-field:after {
          content: '';
          display: block;
          border: solid 2px transparent;
          border-right-color: #2c2c2c;
          border-bottom-color: #2c2c2c;
          height: 9px;
          position: absolute;
          width: 9px;
          transform: rotate(45deg);
          top: 17px;
          right: 20px;
          transition: border-color 0.5s, transform 0.5s;
        }
        .form-group .select-field.open {
          position: relative;
        }
        .form-group .select-field.open:after {
          border-right-color: var(--brand-primary);
          border-bottom-color: var(--brand-primary);
          transform: rotate(225deg);
        }
        .form-group .select-field .select-button {
          position: relative;
          height: 48px;
          padding: 5px 16px;
          text-align: left;
          width: 100%;
        }
        .form-group .select-field.open .select-button {
          color: white;
          border-top: 2px solid var(--brand-primary);
          border-left: 1px solid #c3c8c8;
          border-right: 1px solid #c3c8c8;
          position: relative;
        }
        .form-group .select-field:not(.open) .select-button {
          border: solid 1px #2c2c2c;
        }
        .form-group .select-field .select-options {
          border-left: 1px solid #c3c8c8;
          border-right: 1px solid #c3c8c8;
          border-bottom: 1px solid #c3c8c8;
          position: absolute;
          width: 100%;
          background: white;
          z-index: 10000;
        }
        .form-group .select-field .select-options button {
          display: block;
          height: 48px;
          margin-bottom: 4px;
          padding: 5px 16px 5px 48px;
          text-align: left;
          width: 100%;
        }
        .form-group .select-field .select-options button:hover,
        .form-group .select-field .select-options button.selected {
          color: white;
          background: linear-gradient(28.01deg, #0053b7 16.5%, #0074ff 85.96%);
        }
        button[type='submit'] {
          background: var(--brand-primary);
          font-size: 18px;
          color: white;
          border: none;
          cursor: pointer;
          line-height: 27px;
          padding: 11px 24px;
        }
        button[type='submit']:hover {
          background: var(--brand-primary-hover);
        }
        button[type='submit']:disabled {
          cursor: not-allowed;
        }
        button[type='submit'].submission-success {
          /* lighter version of --status-success */
          background: #9acbb2;
        }
        .form-group *:required {
          border-left: 2px solid red !important;
        }
        `}
      </style>
    </Helmet>
  );
};

export default WebformStyles;
