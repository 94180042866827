import React from 'react';
import { useInView } from 'react-intersection-observer';
import { registerables } from 'chart.js';
import DataLabels from 'chartjs-plugin-datalabels';
import { ReactChart } from 'chartjs-react';
import FadeIn from '../Animations/FadeIn';
import { EsgChartConfig } from './types';

// Register plugins.
ReactChart.register(DataLabels, ...registerables);

const BarChart = ({ props }: EsgChartConfig, name: string) => {
  // Lets us know when the stat is in the viewport.
  const [ref, inView] = useInView({
    // Number between 0 and 1 indicating the percentage that should be visible
    // before triggering. Can also be an array of numbers, to create multiple
    // trigger points.
    threshold: 0.8,
    triggerOnce: true,
  });

  if (!props) {
    return null;
  }

  const {
    data,
    options,
    options: {
      title,
      aspectRatio,
      title: { text },
    },
    plugins,
    description,
    footnote,
  } = props;

  return (
    <>
      <div datatype={`${name}|Bar`} ref={ref} className="min-h-20 my-20">
        <>
          {inView && (
            <FadeIn up>
              <figure>
                <figcaption className="pb-2">
                  {title && (
                    <h5 className="font-md mb-0 text-center text-2xl">
                      {text}
                    </h5>
                  )}
                  {description && (
                    <div className="text-center">{description}</div>
                  )}
                </figcaption>
                <div className="w-full lg:w-10/12 mx-auto px-0 md:px-4 mb-6 md:mb-0">
                  <ReactChart
                    type="bar"
                    data={data}
                    options={options}
                    plugins={plugins}
                    width={aspectRatio ? 400 * aspectRatio : 400}
                    height={aspectRatio ? 400 : 200}
                  />
                  {footnote && (
                    <p className="text-sm block leading-tight text-center pt-6">
                      <sup>{footnote.sup}</sup>
                      {footnote.text}
                    </p>
                  )}
                </div>
              </figure>
            </FadeIn>
          )}
        </>
      </div>
    </>
  );
};

export default BarChart;
