import React from 'react';
import { ResearchCoverage } from 'types';
import SlicePadding from 'components/Energy/Layout/SlicePadding';

const Stock = (data: ResearchCoverage) => {
  const { topspacing, bottomspacing, title, list } = data;

  return (
    <SlicePadding top={topspacing} bottom={bottomspacing}>
      <atmos-research-coverage title={title} list={JSON.stringify(list)} />
    </SlicePadding>
  );
};

export default Stock;
