import React, { Fragment } from 'react';
import parseMenuData from 'utils/parseMenuData';
import { HeaderProps } from 'types';
import '../../styles/energy/footer.css';

interface FooterMenuSection {
  label: string;
  children: FooterMenuItem[];
}

export interface FooterMenuItem {
  label: string;
  path: string;
}

const EnergyFooter = ({ menus }: HeaderProps) => {
  const { footerMenu, footerLegalMenu, footerContactMenu } = menus;
  const menu = parseMenuData(footerMenu);
  const legalMenu = parseMenuData(footerLegalMenu);
  const contactMenu = parseMenuData(footerContactMenu);

  // TODO: Simplify this code later.
  return (
    <atmos-footer class="footer text-base font-body" role="contentinfo">
      {menu &&
        menu.map(
          (
            { label: title, children: items }: FooterMenuSection,
            index: number,
          ) => {
            return (
              <div
                key={index.toString()}
                slot="footer-column"
                className="xxl:pr-0 pr-6 xxl:mr-16 pb-6"
              >
                {title ? (
                  <outline-heading class="lg:mb-6 xl:mb-8" level="h3">
                    {title}
                  </outline-heading>
                ) : (
                  ''
                )}
                <ul>
                  {items.map(({ label: linkText, path }: FooterMenuItem) => {
                    return (
                      <li
                        key={linkText}
                        className="flex justify-start border-none"
                      >
                        <a
                          className={`inline-block border-b border-neutral-transparent hover:border-neutral-white active:border-neutral-white mb-4 text-white`}
                          href={path}
                          title={linkText}
                        >
                          {linkText}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>
            );
          },
        )}

      <div
        slot="footer-column"
        className="xxl:pr-0 xxl:ml-auto pb-0 xl:pb-6 last"
      >
        <div className="hidden lg:block w-40 mb-8 xl:mb-12 ml-auto">
          <a className="atmos-logo-link" href="#">
            <atmos-icon icon="atmosLogo" url="/" svg="true"></atmos-icon>
          </a>
        </div>
        <ul>
          {contactMenu &&
            contactMenu.map(
              ({ label: title, path }: FooterMenuItem, index: number) => {
                const isTel = path && path.includes('tel:');
                {
                  return isTel ? (
                    <Fragment key={index.toString()}>
                      <div className="mb-2 lg:mb-1 lg:text-right">{title}</div>
                      <li className="flex lg:justify-end">
                        <a
                          className="phone inline-block border-b border-neutral-transparent hover:border-neutral-white active:border-neutral-white lg:text-right text-3.5xl mb-6 lg:mb-5 xl:mb-8"
                          href={path}
                          title={path.replace('tel:', '')}
                        >
                          {path.replace('tel:', '')}
                        </a>
                      </li>
                    </Fragment>
                  ) : (
                    <li key={index.toString()} className="flex lg:justify-end">
                      <a
                        className="inline-block border-b border-neutral-transparent hover:border-neutral-white active:border-neutral-white lg:text-right"
                        href={path}
                        title={title}
                      >
                        {title}
                      </a>
                    </li>
                  );
                }
              },
            )}
        </ul>

        <div className="flex justify-between mt-12 lg:mt-9 xl:mt-3 items-center lg:justify-end">
          <div className=" w-5 lg:w-4 lg:ml-6">
            <atmos-icon-link
              variant="light"
              linkurl="https://twitter.com/atmosenergy"
            />
          </div>

          <div className="w-5 lg:w-4 lg:ml-6">
            <atmos-icon-link
              variant="light"
              linkurl="https://www.facebook.com/atmosenergy"
            />
          </div>

          <div className="w-5 lg:w-4 lg:ml-6">
            <atmos-icon-link
              variant="light"
              linkurl="https://www.linkedin.com/company/atmos-energy"
            />
          </div>

          <div className="w-5 lg:w-4 lg:ml-6">
            <atmos-icon-link
              variant="light"
              linkurl="https://www.instagram.com/atmosenergy"
            />
          </div>
        </div>
      </div>

      <ul slot="legal-links" className="flex text-sm">
        {legalMenu &&
          legalMenu.map(
            ({ label: title, path }: FooterMenuItem, index: number) => {
              const classNames = 'pr-4 mr-4 border-r border-neutral-gray';
              return (
                <li key={index.toString()} className={classNames}>
                  <a
                    className="lg:text-base pb-1 border-b border-neutral-transparent hover:border-neutral-white active:border-neutral-white"
                    href={path}
                  >
                    {title}
                  </a>
                </li>
              );
            },
          )}
      </ul>

      <p slot="copyright" className="text-sm font-body pt-3">
        Atmos Energy is engaged in regulated utility operations. Atmos Energy
        Corporation. All Rights Reserved.
      </p>
    </atmos-footer>
  );
};

export default EnergyFooter;
