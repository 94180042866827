/**
 * Displays on /esg/safety
 */
import React from 'react';
import { merge } from 'lodash';
import BarChart from '../Bar';
import { EsgChartConfig } from 'types';
import defaultConfig from './config';

/**
 * Configuration for the chart.
 */
const chartConfig: EsgChartConfig = {
  type: 'bar',
  description:
    'Reportable Motor Vehicle Collision Rate (per 1,000,000 miles driven)',
  data: {
    labels: ['2014', '2015', '2016', '2017', '2018', '2019'],
    datasets: [
      {
        data: [5.46, 5.8, 5.16, 4.97, 5.18, 5.25],
        backgroundColor: '#80B7EC',
      },
    ],
  },
  options: {
    title: {
      text: 'RMVC',
    },
    indexAxis: 'x',
    responsive: true,
    maintainAspectRatio: true,
    scales: {
      y: {
        max: 6,
        ticks: {
          callback(value) {
            return value.toFixed(2);
          },
        },
      },
      x: {
        title: {
          display: false,
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: ({ formattedValue }) => Number(formattedValue).toFixed(2),
        },
      },
      datalabels: {
        display: false,
      },
    },
  },
};

const config = merge({}, defaultConfig, chartConfig);

/**
 * Reportable Motor Vehicle Collision Rate
 *
 * @constructor
 */
const Rmvc = () => <BarChart name="Rmvc" props={config} />;

export default Rmvc;
