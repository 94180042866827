/**
 * Displays on /esg/financials
 */
import React from 'react';
import { merge } from 'lodash';
import BarChart from '../Bar';
import { EsgChartConfig } from 'types';
import defaultConfig from './config';

/**
 * Configuration for the chart.
 */
const chartConfig: EsgChartConfig = {
  type: 'bar',
  data: {
    labels: [
      '2016',
      '2017',
      '2018',
      '2019',
      '2020',
      '2021E*',
      '2022E*',
      '2023E*',
      '2024E*',
      '2025E*',
    ],
    datasets: [
      {
        label: 'Distribution',
        backgroundColor: '#FF8D1E',
        data: [
          4.712, 5.144, 5.868, 6.876, 8.081, 9.099, 10.268, 11.533, 12.867,
          14.333,
        ],
      },
      {
        label: 'Pipeline & Storage',
        backgroundColor: '#2175D9',
        data: [
          1.772, 1.843, 2.083, 2.325, 2.784, 3.008, 3.574, 4.124, 4.593, 5.05,
        ],
      },
    ],
  },
  options: {
    title: {
      text: 'Regulated Rate Base Growth',
    },
    events: [],
    scales: {
      y: {
        stacked: true,
        title: {
          display: true,
          text: '$ Billions',
        },
        ticks: {
          callback: value =>
            value
              .toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
              })
              .split('.')[0],
        },
      },
      x: {
        stacked: true,
      },
    },
    plugins: {
      datalabels: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          fontSize: 16,
          fontFamily: 'futura_ptbook',
          boxWidth: 15,
        },
      },
    },
  },
};

const config = merge({}, defaultConfig, chartConfig);

/**
 * Regulated Rate Base Growth
 *
 * @constructor
 */
const RegulatedGrowth = () => (
  <BarChart name="RegulatedGrowth" props={config} />
);

export default RegulatedGrowth;
