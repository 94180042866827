import React from 'react';
import { merge } from 'lodash';
import Doughnut from 'components/Esg/Charts/Doughnut';
import { EsgChartConfig } from 'types';
import defaultConfig from './config';

/**
 * Chart configuration object.
 */
const chartConfig: EsgChartConfig = {
  data: {
    labels: ['Mid-Tex', 'Colorado', 'Mississippi'],
    datasets: [
      {
        data: [1390861, 615645, 1449608],
        backgroundColor: ['#00B56C', '#80B7EC', '#FF8D1E'],
        borderColor: ['#ffffff', '#ffffff', '#ffffff'],
        borderWidth: 5,
      },
    ],
  },
  options: {
    plugins: {
      doughnutLabel: {
        labels: [
          {
            text: 'Program Spending',
            font: {
              size: '32',
              family: 'futura_ptmedium',
            },
            color: '#66696c',
          },
          {
            text: 'by Division',
            font: {
              size: '32',
              family: 'futura_ptmedium',
            },
            color: '#66696c',
          },
        ],
      },
    },
  },
};

const config = merge({}, defaultConfig, chartConfig);

/**
 * Program Spending by Division
 *
 * /esg/environment
 *
 * @constructor
 */
const PSDChart = () => <Doughnut name="PSDChart" props={config} />;

export default PSDChart;
