/**
 * Utility function used to resolve styles based on a display setting.
 * @param display
 * @param base
 */
const displayResolver = (display: string | null, base: boolean = false) => {
  const split = !display ? '100' : display.toString();

  let leftWidth = '';
  let rightWidth = '';
  let caption = '';
  let body = '';
  switch (split) {
    case '50-50': {
      leftWidth = 'w-full md:w-1/2';
      rightWidth = 'w-full md:w-1/2 pl-4';
      caption = 'font-md leading-relaxed mb-0';
      body = 'text-base leading-normal mb-5';
      break;
    }
    case '60-40': {
      leftWidth = 'w-3/5';
      rightWidth = 'w-2/5 pl-4';
      caption = 'font-md leading-relaxed mb-0';
      body = 'text-base leading-normal mb-5';
      break;
    }
    case '25-75': {
      leftWidth = 'w-full md:w-3/12 m-auto';
      rightWidth = 'w-full md:w-9/12 pl-4';
      caption = 'font-md leading-relaxed mb-0';
      body = 'text-base leading-normal mb-5';
      break;
    }
    case '100': {
      leftWidth = 'w-full md:border-0'; // w-full md:w-1/2 pl-4
      rightWidth = 'w-full'; // was: text-sm leading-tight pt-4 text-esg-gray-dark
      caption = 'font-heavy text-esg-gray-dark text-sm mb-2';
      body = 'text-sm';
      break;
    }
    default: {
      leftWidth = 'w-1/2';
      rightWidth = 'w-1/2';
      body = 'text-base leading-normal mb-5';
      break;
    }
  }

  // If we are render the base component without media.
  if (base) {
    rightWidth = 'w-full px-4 border-0 md:border-l border-gray-500';
  }

  return {
    leftWidth,
    rightWidth,
    caption,
    body,
  };
};

export default displayResolver;
