import React from 'react';
import {
  Layout3ColumnFragment,
  LayoutFullFragment,
  NodePageFragment,
} from 'generated/graphqlCodegen';
import FullBleed from 'components/Esg/Layout/FullBleed';
import OneColumn from 'components/Esg/Layout/OneColumn';
import ThreeColumn from 'components/Esg/Layout/ThreeColumn';

interface Layout {
  [key: string]: Function;
}

const layouts: Layout = {
  paragraph__layout_full: FullBleed,
  paragraph__layout_3_col: ThreeColumn,
  paragraph__one_column: OneColumn,
};

const layoutResolver = (
  layout: LayoutFullFragment | Layout3ColumnFragment,
  node: NodePageFragment,
) => {
  const paragraphType = layout.__typename;
  if (layouts[`${paragraphType}`]) {
    const Layout = layouts[`${paragraphType}`];
    return <Layout columns={layout.relationships} node={node} />;
  }
  return null;
};

export default layoutResolver;
