import React from 'react';
import SlicePadding from 'components/Energy/Layout/SlicePadding';
import './map-code.css';

const MapCode = data => {
  const { code, topspacing, bottomspacing, size } = data;

  const classeNames = ['map-code', size];

  return (
    <SlicePadding top={topspacing} bottom={bottomspacing}>
      <div
        className={classeNames.join(' ')}
        dangerouslySetInnerHTML={{ __html: code }}
      />
    </SlicePadding>
  );
};

export default MapCode;
