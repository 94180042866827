import { graphql, useStaticQuery } from 'gatsby';
import { GetPersonsQuery } from 'generated/graphqlCodegen';

// 315 images as of 2022-04-30 : warning This query took more than 15s to run
const GetPersonNodes = () => {
  return useStaticQuery<GetPersonsQuery>(
    graphql`
      query GetPersons {
        allNodePerson {
          nodes {
            drupal_id
            title
            body {
              processed
            }
            field_job_title
            relationships {
              field_person_type {
                name
              }
              field_photo {
                relationships {
                  field_media_image {
                    localFile {
                      publicURL
                      childImageSharp {
                        gatsbyImageData(
                          width: 125
                          height: 130
                          placeholder: BLURRED
                          formats: [AUTO, WEBP]
                          transformOptions: { cropFocus: NORTH }
                        )
                      }
                    }
                    filename
                  }
                }
              }
            }
          }
        }
      }
    `,
  );
};

export default GetPersonNodes;
