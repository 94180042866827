/* eslint import/no-cycle: "warn" */
import React, { useContext } from 'react';
import ComponentResolver from 'utils/componentResolver';
import EsgPageContext, { GroupProvider } from 'components/Esg/Context';
import { ComponentGroupFragment, NodePage } from 'generated/graphqlCodegen';

/**
 * A wrapper around a group of components (paragraphs) to distinguish them,
 * sylistically, from other groups of components.
 *
 * @param param
 */
const ComponentGroup = (
  { fieldComponent }: ComponentGroupFragment,
  node: NodePage,
) => {
  const { esg } = useContext(EsgPageContext);
  const { groupType, groupBg, relationships } = fieldComponent;

  const {
    relationships: {
      esgMedia: { color },
    },
  } = esg;

  // If this page is using a hero then it will display a ring with icon
  // that will cover the top portion of the next section directly below it.
  // We will apply a class so we can use an adjacent sibling combinator that
  // will add padding to the section directly below the section that contains
  // the hero.
  // const isHero = !isEmpty(relationships.field_components)
  //   ? relationships.field_components[0].__typename === 'paragraph__hero_full_image'
  //   : false;

  if (!relationships) {
    return null;
  }

  const { components: groupComponents } = relationships;

  const components = ComponentResolver(groupComponents, node);

  return (
    <>
      {groupType === 'full' && (
        <GroupProvider
          key={fieldComponent.id}
          value={{ group: { ...fieldComponent } }}
        >
          <section className={`bg-${groupBg}`} datatype="ComponentGroup-Full">
            <>{components && <>{components}</>}</>
          </section>
        </GroupProvider>
      )}
      {groupType === 'container-full' && (
        <GroupProvider
          key={fieldComponent.id}
          value={{ group: { ...fieldComponent } }}
        >
          <section
            className={`${color}-theme bg-${groupBg} w-full relative clear-both pt-12 pb-4`}
            datatype="ComponentGroup-Container"
          >
            {/* text-center md:text-left */}
            <div className="container text-esg-gray-dark leading-relaxed text-lg text-center md:text-left">
              <div className="w-full md:w-10/12 lg:w-9/12 mx-auto px-2 xl:px-12">
                <>{components && <>{components}</>}</>
              </div>
            </div>
          </section>
        </GroupProvider>
      )}
      {groupType === 'container' && (
        <GroupProvider
          key={fieldComponent.id}
          value={{ group: { ...fieldComponent } }}
        >
          <section
            className={`${color}-theme bg-${groupBg} w-full relative clear-both pt-12 pb-4`}
            datatype="ComponentGroup-Container"
          >
            {/* text-center md:text-left */}
            <div className="container text-esg-gray-dark leading-relaxed text-lg text-center md:text-left">
              <>{components && <>{components}</>}</>
            </div>
          </section>
        </GroupProvider>
      )}
    </>
  );
};

export default ComponentGroup;
