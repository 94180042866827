import React from 'react';
import { withPrefix } from 'gatsby';
import SlicePadding from 'components/Energy/Layout/SlicePadding';
import { getImageMarkup } from 'utils/getSliceMedia';

const ImageCard = data => {
  const {
    topspacing,
    bottomspacing,
    variant,
    layout,
    type,
    title,
    content: { text },
    link: {
      embed: {
        text: linkText,
        attributes: { href, target, rel, title: linkTitle },
      },
    },
    media,
  } = data;

  const textColor = variant ? 'none' : 'reverse ';

  // This is an exception, the video image is slotted inside another slot
  // so can't be accessed through the slotted selector.
  const imageStyles = {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  };
  let markup;

  if (media === undefined) {
    markup = '<h1>No media found</h1>';
  } else {
    markup = getImageMarkup(media, 'preview', 'image', imageStyles);
  }

  return (
    <SlicePadding top={topspacing} bottom={bottomspacing}>
      <atmos-image-card
        variant={textColor}
        layout={layout}
        type={type}
        title={title}
        text={text}
        link={linkText}
        target={target}
        rel={rel}
        link-title={linkTitle}
        url={href && href.length ? withPrefix(href) : null}
      >
        {markup}
      </atmos-image-card>
    </SlicePadding>
  );
};

export default ImageCard;
