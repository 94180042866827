import { useEffect, useState } from 'react';

/**
 * Calculate the min-height for the Hero image and the max-width for
 * the EsgRing.
 *
 * @Note: https://www.npmjs.com/package/react-device-detect
 */
const useEsgDimensions = () => {
  // Determine if we are building or are in the browser.
  const isBrowser = typeof window !== 'undefined';

  const [width, setWidth] = useState(isBrowser ? window.innerWidth : 0);
  const [height, setHeight] = useState(isBrowser ? window.innerHeight : 0);

  const isLandscape = width > height;
  const isPortrait = height > width;

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  if (!isBrowser) {
    return [0, 0, 0];
  }

  if (isLandscape) {
    if (width <= 400) return ['h-30vh', 'max-w-W125', 'min-h-300'];
    if (width <= 640) return ['h-60vh', 'max-w-W125', 'min-h-500'];
    if (width < 768) return ['h-55vh', 'max-w-W125', 'min-h-500'];
    if (width < 992) return ['h-65vh', 'max-w-W150', 'min-h-550'];
    if (width < 1280) return ['h-65vh', 'max-w-W250', 'min-h-550'];
    return ['h-65vh', 'max-w-W300', 'min-h-550'];
  }
  if (isPortrait) {
    if (width <= 400) return ['h-45vh', 'max-w-W125', 'min-h-400'];
    if (width <= 640) return ['h-45vh', 'max-w-W150', 'min-h-400'];
    if (width <= 768) return ['h-35vh', 'max-w-W175', 'min-h-300'];
    if (width <= 992) return ['h-45vh', 'max-w-W225', 'min-h-400'];
    if (width <= 1280) return ['h-55vh', 'max-w-W250', 'min-h-500'];
    return ['h-65vh', 'max-w-W300', 'min-h-550'];
  }
  return [0, 0, 0];
};

export default useEsgDimensions;
