/**
 * Displays on esg/environment
 */
import React from 'react';
import { merge } from 'lodash';
import BarChart from '../Bar';
import { EsgChartConfig } from 'types';
import defaultConfig from './config';

/**
 * Configuration for the chart.
 */
const chartConfig: EsgChartConfig = {
  type: 'bar',
  data: {
    labels: ['2015', '2016', '2017', '2018', '2019'],
    datasets: [
      {
        label:
          'Industry Identified Materials – Bare Steel, Cast Iron, Vintage Plastics',
        backgroundColor: '#00B56C',
        data: [328, 336, 375, 430, 453],
      },
      {
        label: 'Other Risk-Based Materials',
        backgroundColor: '#2175D9',
        data: [142, 141, 143, 305, 317],
      },
    ],
  },
  options: {
    title: {
      text: 'Distribution Miles Replacement Rate',
    },
    scales: {
      y: {
        stacked: true,
        max: 1000,
        title: {
          display: true,
          text: 'Miles',
        },
      },
      x: {
        stacked: true,
      },
    },
    plugins: {
      datalabels: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: ({ formattedValue, dataset: { label } }) =>
            `${label}: ${formattedValue}`,
        },
      },
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          fontSize: 16,
          fontFamily: 'futura_ptbook',
          boxWidth: 15,
        },
      },
    },
  },
};

const config = merge({}, defaultConfig, chartConfig);

/**
 * Distribution Miles Replacement Rate
 *
 * /esg/environment
 *
 * @constructor
 */
const DistributionMRR = () => (
  <BarChart name="DistributionMRR" props={config} />
);

export default DistributionMRR;
