import React, { ReactElement } from 'react';
import { useInView } from 'react-intersection-observer';
import { Transition, animated } from 'react-spring';
import { PngAnimationFragment } from 'generated/graphqlCodegen';

type TransitionItem = {
  id: number;
  text: ReactElement;
  trailtime: number;
};

const PngAnimation = ({ fieldComponent }: PngAnimationFragment) => {
  const { id, relationships } = fieldComponent;
  const { mediaUnlimited } = relationships;

  // Lets us know when the stat is in the viewport.
  const [ref, inView] = useInView({
    // Number between 0 and 1 indicating the percentage that should be visible
    // before triggering. Can also be an array of numbers, to create multiple
    // trigger points.
    threshold: 0.8,
    triggerOnce: true,
  });

  const items: TransitionItem[] = [];
  mediaUnlimited.forEach((media, index) => {
    const {
      relationships: {
        mediaImage: {
          localFile: { publicURL },
        },
      },
    } = media;
    const {
      mediaImageMeta: { alt },
    } = media;
    const itemId = index + 1;
    items.push({
      id: itemId,
      text: (
        <img
          className={itemId === 3 ? '' : 'absolute top-0 left-0'}
          src={publicURL}
          alt={alt}
        />
      ),
      trailtime: itemId === 1 ? 2000 : 0,
    });
  });

  return (
    <>
      <div id={id} ref={ref} className="mb-20 min-h-20">
        <>
          {inView && (
            <div>
              <figure className="relative overflow-hidden min-h-full text-center">
                <Transition
                  items={items}
                  keys={item => item.id}
                  trail={1000}
                  from={{ opacity: 0, transform: 'translate3d(0,100px,0)' }}
                  enter={{ opacity: 1, transform: 'translate3d(0,0,0)' }}
                >
                  {(values, item) => (
                    <animated.div style={values}>{item.text}</animated.div>
                  )}
                </Transition>
              </figure>
            </div>
          )}
        </>
      </div>
    </>
  );
};

export default PngAnimation;
