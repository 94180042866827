import React from 'react';
import ReactPlayer from 'react-player';
import { MediaVideoFragment } from 'generated/graphqlCodegen';

interface Props {
  mediaComponent: MediaVideoFragment;
}

/**
 * Section for the Title plus Content paragraph
 *
 * @param imageLeft
 * @constructor
 */
const MediaCaptionVideoFile = ({ mediaComponent }: Props) => {
  const { relationships } = mediaComponent;

  if (!relationships) {
    return null;
  }

  return (
    <>
      <div
        className="relative pb-48 mb-56 md:mb-24 md:pb-8"
        datatype="MediaCaptionVideoFile"
      >
        <ReactPlayer
          width="100%"
          height="100%"
          className="w-full h-full absolute top-0 left-0"
          url={relationships.video.localFile.publicURL}
          playing
          loop
          ready="true"
        />
      </div>
    </>
  );
};

export default MediaCaptionVideoFile;
