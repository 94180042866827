/**
 * @file Defines custom propTypes
 */
export interface DrupalLink {
  drupal_link: {
    embed: {
      attributes: {
        href: string;
        title: string;
      };
      embedHTML: string;
      text: string;
    };
  };
}

/**
 * Custom propType
 *
 * @see "project/node_modules/@types/prop-types/index.d.ts - interface Validator"
 *
 * @param props
 * @param propName
 * @param componentName
 * @returns
 */
const drupalLinks = (
  props: { [key: string]: any },
  propName: string,
  componentName: string,
) => {
  componentName = componentName || 'NoComponentName';

  // Used in the error messages
  const errorPrefix = `The ${propName} prop in the ${componentName} component`;

  const isDrupalLink = (value: any) => {
    return (
      value.filter((v: DrupalLink) => v.drupal_link).length === value.length
    );
  };

  // We can't use a 'string' as the key of an object
  const key = propName as keyof typeof props;

  if (props[key]) {
    let value = props[key];
    if (!Array.isArray(value)) {
      return new Error(
        `${errorPrefix} must be an array of drupal_link objects`,
      );
    }
    const valid = isDrupalLink(value);
    return valid
      ? null
      : new Error(`${errorPrefix} does not match the DrupalLinks interface`);
  }

  // assume all ok
  return null;
};

export default drupalLinks;
