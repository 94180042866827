import { withPrefix } from 'gatsby';
import React from 'react';

const IconCard = data => {
  const {
    title,
    text,
    icon,
    link: {
      embed: {
        attributes: { href, target, rel, title: linkTitle },
        text: linkText,
      },
    },
    phone,
  } = data;

  return (
    <atmos-icon-card
      title={title}
      text={text}
      link={linkText}
      target={target}
      rel={rel}
      link-title={linkTitle}
      url={withPrefix(href)}
      phone={phone}
      icon={icon}
    />
  );
};

export default IconCard;
