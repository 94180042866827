import { withPrefix } from 'gatsby';
import React from 'react';

const PageSwitcher = data => {
  const { title, buttonSize, selectors, link } = data;
  const {
    embed: {
      text,
      attributes: { href, target, rel, title: linkTitle },
    },
  } = link;

  return (
    <atmos-page-content-switcher
      title={title}
      buttonSize={buttonSize}
      selectors={JSON.stringify(selectors)}
      url={withPrefix(href)}
      link={text}
      target={target}
      rel={rel}
      link-title={linkTitle}
    />
  );
};

export default PageSwitcher;
