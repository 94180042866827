import React, { useState, useRef } from 'react';

import {
  useWebformElement,
  WebformElementWrapper,
  WebformCustomComponent,
} from 'gatsby-drupal-webform';

export const DEFAULT_SELECT_LABEL = '-- Select --';

/* eslint-disable react/prop-types */

export const WebformSelect: WebformCustomComponent = ({ element, error }) => {
  const elementRef = useRef();
  const [selectedValue, setSelectedValue] = useState('');
  const [open, setOpen] = useState(false);
  const id = `form-${element.name}`.replace(/[_\s]/g, '-');
  const [inputProps, settings] = useWebformElement(element, {
    className: 'form-control hide-field',
    name: element.name,
    id,
  });

  const onSelect = (label: string, value: string | null) => {
    setOpen(false);
    setSelectedValue(label);
    if (elementRef.current) {
      elementRef.current.value = value;
    }
  };

  return (
    <WebformElementWrapper settings={settings} error={error} labelFor={id}>
      <select
        {...inputProps}
        defaultValue={inputProps.defaultValue || ''}
        ref={elementRef}
      >
        {/** Render placeholder as first element */}
        <option value="" disabled>
          {inputProps.placeholder || DEFAULT_SELECT_LABEL}
        </option>

        {element.options &&
          element.options.map(option => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
      </select>
      <div className={`select-field ${open ? 'open' : ''}`}>
        <button
          className="select-button"
          type="button"
          onClick={() => setOpen(!open)}
        >
          {selectedValue !== ''
            ? selectedValue
            : inputProps.placeholder || DEFAULT_SELECT_LABEL}
        </button>
        {element.options && open && (
          <div className="select-options">
            <button
              key={inputProps.placeholder || DEFAULT_SELECT_LABEL}
              type="button"
              onClick={() =>
                onSelect(inputProps.placeholder || DEFAULT_SELECT_LABEL, null)
              }
            >
              {inputProps.placeholder || DEFAULT_SELECT_LABEL}
            </button>
            {element.options.map(option => (
              <button
                className={selectedValue === option.label ? 'selected' : ''}
                key={option.value}
                type="button"
                onClick={() => onSelect(option.label, option.value)}
              >
                {option.label}
              </button>
            ))}
          </div>
        )}
      </div>
    </WebformElementWrapper>
  );
};

export default WebformSelect;
