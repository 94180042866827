/* eslint @typescript-eslint/no-use-before-define: off */

interface MenuLinkData {
  uri: string;
  uri_alias: string;
  external: boolean;
}

const pathResolver = (link: MenuLinkData): string => {
  const { uri, uri_alias, external } = link;
  if (!external && uri_alias) {
    return uri_alias;
  }
  if ((!external && uri.includes('internal:')) || uri.includes('route:')) {
    const internal = uri.replace(/internal:|route:/g, '');
    if (internal === '/home') {
      return '/';
    }
    return internal;
  }
  return uri;

  // return path.includes('internal:') ? path.replace(/internal:/g, '') : path;
};

const buildTree = (elements, parent: string | null = null) => {
  const items = elements.filter(e => e.drupal_parent_menu_item === parent);
  if (!items.length) return null;
  return items.map(item => {
    return {
      ...item,
      children: buildTree(elements, `${item.bundle}:${item.drupal_id}`),
    };
  });
};

const transform = (elements, deep, submenu = false) => {
  return elements.map((element, key) => {
    return structure(element, key, deep + 1, submenu);
  });
};

const structure = (element, key, deep, submenu = false) => {
  const { title, link, altern } = element;

  const { external } = link;

  const path = pathResolver(link);
  const menuItem = {
    label: title,
    external,
    path,
  };
  menuItem.children = element.children
    ? transform(element.children, deep, submenu)
    : [];

  if (deep === 2 && submenu) {
    menuItem.title = key ? title : null;
    if (altern) {
      const first = menuItem.children.slice(0, 1).shift();
      menuItem.link = first ? first.label || '' : '';
      menuItem.url = first ? pathResolver(first?.path) : '#';
      menuItem.items = [...menuItem.children.slice(1)];
    } else {
      menuItem.items = [...menuItem.children];
    }
    menuItem.altern = altern;
    delete menuItem.label;
    delete menuItem.path;
    delete menuItem.external;
    delete menuItem.children;
  }

  return menuItem;
};

const parseMenuData = (menuData, submenu = false) => {
  const {
    data: {
      allMenuLinkContentMenuLinkContent: { nodes },
    },
  } = menuData;

  const menuTree = buildTree(nodes);

  return menuTree ? transform(menuTree, 0, submenu) : null;
};

export default parseMenuData;
