/**
 * Displays on /esg/environment
 */
import React from 'react';
import { merge } from 'lodash';
import BarChart from '../Bar';
import { EsgChartConfig } from 'types';
import defaultConfig from './config';

/**
 * Configuration for the chart.
 */
const chartConfig: EsgChartConfig = {
  type: 'bar',
  data: {
    labels: [
      '2009',
      '2010',
      '2011',
      '2012',
      '2013',
      '2014',
      '2015',
      '2016',
      '2017',
      '2018',
    ],
    datasets: [
      {
        label: 'McCommas',
        backgroundColor: '#00B56C',
        data: [
          1190716, 1365184, 1233743, 1634715, 1764781, 1897041, 1900754,
          2102450, 1980462, 2012374,
        ],
      },
      {
        label: 'Melissa',
        backgroundColor: '#2175D9',
        data: [0, 0, 0, 0, 0, 0, 0, 0, 23885, 834460],
      },
      {
        label: 'River Birch',
        backgroundColor: '#80B7EC',
        data: [
          0, 267812, 652992, 719016, 895844, 810496, 950473, 1017203, 1295410,
          1449738,
        ],
      },
    ],
  },
  options: {
    title: {
      display: false,
      text: 'Renewable Natural Gas',
    },
    scales: {
      y: {
        stacked: true,
        ticks: {
          callback: value =>
            value
              .toLocaleString('en-US', {
                style: 'decimal',
                currency: 'USD',
              })
              .split('.')[0],
        },
        title: {
          display: true,
          text: 'Annual/MMBtu',
        },
      },
      x: {
        stacked: true,
      },
    },
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          fontSize: 16,
          fontFamily: 'futura_ptbook',
          boxWidth: 15,
        },
      },
      tooltip: {
        callbacks: {
          label: ({ formattedValue }) =>
            formattedValue
              .toLocaleString('en-US', {
                style: 'decimal',
                currency: 'USD',
              })
              .split('.')[0],
        },
      },
      datalabels: {
        display: false,
      },
    },
  },
};

const config = merge({}, defaultConfig, chartConfig);

/**
 * Renewable Natural Gas : AnnualMMBtu measurement
 *
 * /esg/environment
 *
 * @constructor
 */
const AnnualMMBtu = () => <BarChart name="AnnualMMBtu" props={config} />;

export default AnnualMMBtu;
