import React from 'react';
import Layout from 'components/Esg/Shared/Layout';
import SEO from 'components/Esg/Shared/SEO';
import layoutResolver from 'utils/layoutResolver';
import { EsgPageProvider } from 'components/Esg/Context';
import useQueryEsgOverview from 'hooks/useQueryEsgOverview';
import { GatsbyPageContext } from 'types';
import '../styles/esg/index.css';

/**
 * The ESG Overview page.
 *
 * @constructor
 */
const EsgOverview = ({ pageContext }: GatsbyPageContext) => {
  const node = useQueryEsgOverview();

  const { title, relationships } = node;

  // Without relationships we have nothing; return early.
  if (!relationships) {
    return null;
  }

  const { layout, custom: esg } = relationships;

  const content = layoutResolver(layout, node);

  // Drupal menus.
  const { menus } = pageContext;

  return (
    <EsgPageProvider value={{ esg, other: { pageTitle: title } }}>
      <Layout menus={menus}>
        <SEO />
        {content && <>{content}</>}
      </Layout>
    </EsgPageProvider>
  );
};

export default EsgOverview;
