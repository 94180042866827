import { withPrefix } from 'gatsby';
import useSlottedImage from 'hooks/useSlottedImage';
import React from 'react';

const CirclePromo = data => {
  const {
    position,
    image,
    title,
    textAccent,
    content: { text },
    link: {
      embed: {
        text: linkText,
        attributes: { href, target, rel, title: linkTitle },
      },
    },
  } = data;

  const pos = position ? 'right' : 'left ';

  const imageStyles = {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    position: 'absolute',
  };

  // Get the image markup.
  const markup = useSlottedImage(image, 'preview', 'image', imageStyles);

  return (
    <atmos-circle-promo
      position={pos}
      title={title}
      title-accent={textAccent}
      text={text}
    >
      <>
        {markup}
        {linkText && href ? (
          <atmos-button
            slot="button"
            url={withPrefix(href)}
            target={target}
            rel={rel}
            link-title={linkTitle}
          >
            {' '}
            {linkText}{' '}
          </atmos-button>
        ) : (
          ''
        )}
      </>
    </atmos-circle-promo>
  );
};

export default CirclePromo;
