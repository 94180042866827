const columnsResolver = (columns: number) => {
  let columnsWidth = '';
  switch (columns) {
    case 3: {
      columnsWidth = 'w-4/12';
      break;
    }
    case 4: {
      columnsWidth = 'w-3/12';
      break;
    }
    default: {
      columnsWidth = 'w-4/12';
      break;
    }
  }

  return columnsWidth;
};

export default columnsResolver;
