import React, { ReactNode } from 'react';
import { Link as GatsbyLink } from 'gatsby';

interface Props {
  className: string;
  href: string;
  title: string;
  label: string;
  children?: ReactNode;
}

/**
 * Custom Link component
 *
 * This will use Gatsby Link to rewrite links if necessary. There are a few
 * things we have to take into consideration since this site uses a prefix.
 *
 * The following examples represent the production base url:
 *
 * Gatsby site
 *  - https://www.atmosenergy.com/esg
 *
 * Base url for Drupal
 *  - https://www.atmosenergy.com
 *
 * If we have a link or menu item that needs to link directly to the Drupal
 * site we need the link to be written absolutely and use the standard <a> tag.
 * If that link is written relatively then it will include the aforementioned
 * prefix.
 *
 * Environmental Variables:
 *  The .env.qa and .env.production files contain a variable,
 *  GATSBY_PRODUCTION_URL, that defines the Drupal base url. We can use this
 *  to determine if we should target "_blank" or "_self" for this particular
 *  link.
 *
 * Rules:
 *  - New tab: All files open in a new tab
 *    - Example: https://www.atmosenergy.com/sites/default/files/2019-methane-report-1-30.pdf
 *  - New tab: Any link who's base url differs from that of the Drupal
 *    environment should open in a new tab unless it is linking internally to
 *    the Gatsby site
 *    - External link in the Atmos Energy menu
 *      - Example: https://atmos.taleo.net/careersection/prof/jobsearch.ftl?lang=en
 *  - Same tab: All links that are internal to the Gatsby site should open in
 *    the same tab as well as those that are technically "external" but share
 *    the same base url as the Drupal site.
 *      - Example: /corporate-governance
 *      - Example: https://www.atmosenergy.com/company/board-directors
 *
 * @param href The link
 * @param className Classes applied to this component
 * @param label The label for this link
 * @param title The title for this link
 * @param children ReactNode children
 * @constructor
 */
const Link = ({ href, className, label, title, children }: Props) => {
  const drupalUrl = process.env.DRUPAL_URL || 'https://www.atmosenergy.com';
  let hrefProcessed = href;
  let target;
  let rel;
  // Looking for links that represent files, ends with a 3 or 4 character
  // alpha extension, i.e., ".pdf"
  // @TODO: If we know we are only linking to .pdf then we can be more specific
  const reg = new RegExp('^.*.(pdf|PDF)$');

  // Open in a new tab if the link is external
  // and is a file. This affects links that do not start with '/' that are
  // meant to be internal links to the Gatsby site
  if ((!href.startsWith(drupalUrl) && href !== '#') || reg.test(href)) {
    target = '_blank';
    rel = 'noopener noreferrer';
  }

  // Internal links from Drupal, i.e., internal:/environment
  if (href.includes('internal:')) {
    hrefProcessed = href.replace('internal:', '');
  }

  return (
    <>
      {hrefProcessed.startsWith('/') ? (
        <GatsbyLink
          aria-label={label}
          title={title}
          className={className}
          to={hrefProcessed}
        >
          {children}
        </GatsbyLink>
      ) : (
        <a
          aria-label={label}
          title={title}
          className={className}
          href={hrefProcessed}
          rel={rel}
          target={target}
        >
          {children}
        </a>
      )}
    </>
  );
};

export default Link;
