import { withPrefix } from 'gatsby';
import useSlottedImage from 'hooks/useSlottedImage';
import React from 'react';
import RegionDisplay from '../Layout/RegionDisplay';

const JumboFeature = data => {
  const {
    link: {
      embed: {
        text: linkText,
        attributes: { href, target, rel, title: linkTitle },
      },
    },
    content: { text },
    title,
    image,
    position,
    region,
  } = data;

  const pos = position ? 'right' : 'left ';

  // This is an exception, the video image is slotted inside another slot
  // so can't be accessed through the slotted selector.
  const imageStyles = {
    aspectRatio: '1504 / 870',
    objectFit: 'cover',
    width: '100%',
  };

  const wrapperStyles = {
    maxWidth: '1504px',
    marginLeft: 'auto',
    marginRight: 'auto',
  };

  // Get the image markup.
  const markup = useSlottedImage(image, 'preview', 'image', imageStyles);

  return (
    <RegionDisplay region={region || 'all'}>
      <div style={wrapperStyles}>
        <atmos-jumbo-feature position={pos}>
          {markup}
          <atmos-feature-content
            slot="info"
            url={withPrefix(href)}
            type="none"
            variant="reverse"
            title={title}
            text={text}
            link={linkText}
            target={target}
            link-title={linkTitle}
            rel={rel}
          />
        </atmos-jumbo-feature>
      </div>
    </RegionDisplay>
  );
};

export default JumboFeature;
