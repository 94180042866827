// TODO: This is a placeholder can be updated later for a location zipcode service.

import isMidTexas from './isMidTexas';
import isWestTexas from './isWestTexas';

const getRegionByZip = (zipCode: number): string | null => {
  let region = null;
  if (zipCode >= 40003 && zipCode <= 42788) {
    region = 'Kentucky';
  } else if (zipCode >= 37010 && zipCode <= 38589) {
    region = 'Tennessee';
  } else if (isMidTexas(zipCode)) {
    region = 'Mid Texas';
  } else if (isWestTexas(zipCode)) {
    region = 'West Texas';
  } else if (
    (zipCode >= 20040 && zipCode <= 20167) ||
    (zipCode >= 22001 && zipCode <= 24658)
  ) {
    region = 'Virginia';
  } else if (
    (zipCode >= 73301 && zipCode <= 73301) ||
    (zipCode >= 75001 && zipCode <= 75501) ||
    (zipCode >= 75503 && zipCode <= 79999) ||
    (zipCode >= 88510 && zipCode <= 88589)
  ) {
    region = 'Texas';
  } else if (zipCode >= 66002 && zipCode <= 67954) {
    region = 'Kansas';
  } else if (zipCode >= 80001 && zipCode <= 81658) {
    region = 'Colorado';
  } else if (
    (zipCode >= 70001 && zipCode <= 71232) ||
    (zipCode >= 71234 && zipCode <= 71497)
  ) {
    region = 'Louisiana';
  } else if ((zipCode >= 38601 && zipCode <= 39776) || zipCode === 71233) {
    region = 'Mississippi';
  } else {
    region = 'N/A';
  }

  return region;
};

export default getRegionByZip;
