import React, { useContext } from 'react';
import Helmet from 'react-helmet';
import { convertToBgImage } from 'gbimage-bridge';
import BackgroundImage from 'gatsby-background-image';
import { isEmpty } from 'lodash';
import { useSpring } from 'react-spring';
import useHeroScroll from 'hooks/useHeroScroll';
import useEsgDimensions from 'hooks/useEsgDimensions';
import EsgPageContext from 'components/Esg/Context';
import EsgRing from 'components/Esg/Ring/EsgRing';
import {
  HeroFullImageFragment,
  MediaImageFieldMediaImage,
} from 'generated/graphqlCodegen';
import { getImage } from 'gatsby-plugin-image';

const Hero = ({ fieldComponent }: HeroFullImageFragment) => {
  const { relationships } = fieldComponent;
  // Esg context
  const { esg } = useContext(EsgPageContext);

  // Determine the min-height for the Hero Image and the max-width
  // for the EsgRing
  const [height, , minHeight] = useEsgDimensions();

  const bgProps = useSpring({
    from: { opacity: 1 },
    to: { opacity: 0 },
    config: { delay: 250, duration: 750 },
  });

  const headingProps = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: { delay: 750, duration: 750 },
  });

  // Destructure ESG info
  const {
    relationships: {
      esgMedia: {
        segment,
        relationships: { icon },
      },
    },
  } = esg;

  const {
    media: { mediaImageMeta, relationships: mediaRelationships },
    components,
  } = relationships;

  const { alt, title }: MediaImageFieldMediaImage = mediaImageMeta;

  // Get the image.
  const image = getImage(mediaRelationships.mediaImage.localFile);
  // Get the background image.
  const bgImage = convertToBgImage(image);

  const { publicURL }: MediaImageFieldMediaImage =
    mediaRelationships.mediaImage.localFile;

  const drupalUrl = process.env.DRUPAL_URL || 'https://www.atmosenergy.com/esg';
  let heading;
  if (!isEmpty(components)) {
    heading = components[0].heading;
  }

  const shouldRenderIconRing = !isEmpty(icon);

  type MetaProps = JSX.IntrinsicElements['meta'];

  const constantMeta: MetaProps[] = [
    {
      property: `og:image`,
      content: drupalUrl + publicURL,
    },
  ];

  // Adds a parallax scroll effect to the image.
  useHeroScroll('.hero-parallax .gatsby-image-wrapper', 0.4);

  return (
    <>
      <Helmet meta={constantMeta} />
      {segment && (
        <div
          datatype="HeroWhiteBg"
          className="bg-white absolute top-0 right-0 bottom-0 left-0 relative"
          style={bgProps}
        />
      )}
      <div
        datatype="Hero"
        // className={`${segment ? "hero-parallax overflow-hidden -mt-20" : "hero-parallax bg-fixed overflow-hidden max-w-full"}`}
        className="hero-parallax overflow-hidden max-w-full bg-fixed"
      >
        {heading && (
          <div
            className="absolute z-10 left-0 right-0 mx-auto px-16 max-w-144 top-20p sm:top-12p md:top-20p lg:px-0"
            datatype="heading"
            dangerouslySetInnerHTML={{ __html: heading.processed }}
            style={headingProps}
          />
        )}
        <BackgroundImage
          className={`${height} ${minHeight} gatsby-image-wrapper`}
          alt={alt || 'hero background image needs alt'}
          title={title || 'hero background image needs title'}
          {...bgImage}
          preserveStackingContext
        />
      </div>
      {segment && (
        <EsgRing
          maxWidth="max-w-W300"
          all={segment === 'all' && shouldRenderIconRing}
        />
      )}
    </>
  );
};

export default Hero;
