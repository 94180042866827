import React, { useContext, useRef, FormEvent, MouseEvent } from 'react';
import { regionContext } from 'context/Provider';
import getRegionByZip from 'utils/getRegionByZip';
import './locationHero.css';

const Location = () => {
  const { region, updateRegion } = useContext(regionContext);
  const zipForm = useRef(null);

  const inputs = [
    { name: 'input-1' },
    { name: 'input-2' },
    { name: 'input-3' },
    { name: 'input-4' },
    { name: 'input-5' },
  ];

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (zipForm && zipForm.current) {
      const { zipCode } = zipForm.current;
      updateRegion(getRegionByZip(zipCode));
    }
  };

  const clearRegion = (e: MouseEvent) => {
    e.preventDefault();
    updateRegion(null);
  };

  return !region ? (
    <>
      <p className="hero-zipcode-form-text" slot="location-selector">
        Enter your zip code to see
        <span>rates & tariffs</span>
        in your area
      </p>
      <form
        className="pt-spacing-18 flex flex-col items-center hero-zipcode-form"
        onSubmit={onSubmit}
        slot="location-selector"
      >
        <atmos-zip-code-collection
          ref={zipForm}
          size="1"
          className="flex flex-col w-56 hero-zip-selector"
          name="zipCodeInput"
          id="zipCodeInput"
          inputs={JSON.stringify([...inputs])}
          label="Enter your zip code"
          errorMessage="Enter a valid zip code"
        />
        <input
          className="hero-zipcode-form-button w-full bottom-0 xl:-bottom-16 p-2.5 xl:p-5 text-neutral-white bg-brand-primary hover:bg-brand-primaryHover hover:cursor-pointer font-body"
          type="submit"
          value="Go"
        />
      </form>
    </>
  ) : (
    <div className="hero-map-container" slot="location-selector">
      {region !== 'N/A' ? (
        <p className="hero-map text-title flex justify-center items-center">
          <img
            className="map-image"
            alt={`${region} map`}
            src={`/maps/${region.toLowerCase()}.svg`}
          />
        </p>
      ) : (
        ''
      )}
      <button type="button" className="hero-region-click" onClick={clearRegion}>
        See rates and tariffs for another state&nbsp;&nbsp;&#9658;
      </button>
    </div>
  );
};

export default Location;
