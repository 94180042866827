/**
 * Displays on /esg/financials
 */
import React from 'react';
import { merge } from 'lodash';
import Line from '../Line';
import { EsgChartConfig } from 'types';
import defaultConfig from './config';

const chartConfig: EsgChartConfig = {
  data: {
    labels: ['2013', '2014', '2015', '2016', '2017', '2018', '2019', '2020'],
    datasets: [
      {
        data: [28.47, 47.7, 58.18, 74.47, 83.84, 93.91, 113.89, 95.59],
        label: 'Atmos Energy Stock Price',
        borderColor: '#FF8D1E',
        pointHoverBorderColor: '#FF8D1E',
        borderWidth: 5,
        pointRadius: 3,
        pointHoverRadius: 4,
        pointHoverBorderWidth: 4,
        // Fill the grid below the line
        fill: {
          target: 'origin',
        },
      },
    ],
  },
  options: {
    title: {
      text: 'Stock Price as of Fiscal Year End',
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: ({ formattedValue }) =>
            Number(formattedValue).toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            }),
        },
      },
    },
  },
};

const config = merge({}, defaultConfig, chartConfig);

/**
 * Stock Price Line Chart
 * @constructor
 */
const StockPrice = () => <Line name="StockPrice" props={config} />;

export default StockPrice;
