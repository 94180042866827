import React from 'react';
// import { FlexibleColumns } from 'types';
// import ContentResolver from 'components/Energy/ContentResolver/ContentResolver';
import SlicePadding from 'components/Energy/Layout/SlicePadding';
import { withPrefix } from 'gatsby';

const DocumentList = data => {
  const {
    topspacing,
    bottomspacing,
    title: { text },
    documents,
  } = data;

  const documentElements = documents.map(doc => {
    const {
      document: {
        embed: { attributes },
      },
    } = doc;

    return {
      name: attributes['data-media-name'] || 'document name',
      url: withPrefix(attributes['data-media-url']) || '#',
    };
  });

  return (
    <div style={{ margin: 'auto', maxWidth: '1134px' }}>
      <SlicePadding top={topspacing} bottom={bottomspacing}>
        <atmos-documents
          title={text}
          documents={JSON.stringify(documentElements)}
        />
      </SlicePadding>
    </div>
  );
};

export default DocumentList;
