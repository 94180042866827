import React from 'react';
import { useInView } from 'react-intersection-observer';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { faFile } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FadeIn from 'components/Esg/Animations/FadeIn';
import Link from 'components/Esg/Shared/Link';
import linkResolver from 'utils/linkResolver';
import { LinkListFragment } from 'generated/graphqlCodegen';

// Add the icons we need to the library.
library.add(faFile, faAngleRight);

const LinkList = ({ fieldComponent }: LinkListFragment) => {
  const { id, listLinks } = fieldComponent;

  // Lets us know when the component is in the viewport.
  const [ref, inView] = useInView({
    // Number between 0 and 1 indicating the percentage that should be visible
    // before triggering. Can also be an array of numbers, to create multiple
    // trigger points.
    threshold: 0.8,
    triggerOnce: true,
  });

  return (
    <>
      <section id={id} ref={ref} className="mb-32 flow-root">
        <>
          {inView && (
            <FadeIn up>
              <nav className="report-download-bars font-heavy text-left text-esg-gray-dark leading-tight">
                {listLinks.map(({ title, uri }) => {
                  return (
                    <Link
                      key={title}
                      href={linkResolver(uri)}
                      className="block text-esg-gray-dark flow-root no-underline border-b border-gray-300 bg-transparent hover:bg-gray-100"
                      title={title}
                      label={title}
                    >
                      <div className="float-left p-4">
                        <FontAwesomeIcon
                          icon={['far', 'file']}
                          className="mr-4"
                          color="#333"
                          size="lg"
                        />
                        {title}
                      </div>
                    </Link>
                  );
                })}
              </nav>
            </FadeIn>
          )}
        </>
      </section>
    </>
  );
};

export default LinkList;
