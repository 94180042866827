import React from 'react';
import { useInView } from 'react-intersection-observer';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { MediaImageFragment } from 'generated/graphqlCodegen';
import FadeIn from '../Animations/FadeIn';
import Link from '../Shared/Link';

interface Props {
  mediaComponent: MediaImageFragment;
}

const MediaLinkImage = ({ mediaComponent }: Props) => {
  const { id, mediaImageMeta, relationships, mediaDisplay, mediaLink } =
    mediaComponent;

  // Lets us know when the component is in the viewport.
  const [ref, inView] = useInView({
    // Number between 0 and 1 indicating the percentage that should be visible
    // before triggering. Can also be an array of numbers, to create multiple
    // trigger points.
    threshold: 0.8,
    triggerOnce: true,
  });

  let title;
  let uri;

  if (mediaLink) {
    ({ title, uri } = mediaLink);
  }

  const isRound = mediaDisplay && mediaDisplay === 'round';
  const classes = isRound
    ? 'shadow-md border-4 rounded-full border-transparent hover:border-white'
    : '';

  // Get the image.
  const image = getImage(relationships.mediaImage.localFile);

  return (
    <div
      ref={ref}
      key={id}
      className="w-8/12 mx-auto md:w-4/12 mb-8 px-4 relative"
      datatype="MediaLinkImage"
    >
      <>
        {inView && (
          <FadeIn up>
            <GatsbyImage
              key={relationships.mediaImage.id}
              className={`mb-6 ${classes}`}
              image={image}
              alt={mediaImageMeta.alt}
              title={mediaImageMeta.title}
            />
            <div>{title}</div>
            {uri && (
              <Link
                className="absolute top-0 right-0 bottom-0 left-0"
                href={uri}
                title={title || 'needs title'}
                label={title || 'needs title'}
              />
            )}
          </FadeIn>
        )}
      </>
    </div>
  );
};

export default MediaLinkImage;
