/**
 * SEO components that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useContext } from 'react';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import { SiteMetaQuery } from 'generated/graphqlCodegen';
import EsgPageContext from '../Context';

type MetaProps = JSX.IntrinsicElements['meta'];

interface SEOProps {
  title?: string;
  description?: string;
  lang?: string;
  link?: JSX.IntrinsicElements['link'];
  meta?: MetaProps[];
}

const SEO = ({
  title,
  description,
  lang = 'en',
  link,
  meta = [],
}: SEOProps) => {
  const { site } = useStaticQuery<SiteMetaQuery>(
    graphql`
      query SiteMeta {
        site {
          siteMetadata {
            title
            description
            author
            link {
              rel
              href
            }
          }
        }
      }
    `,
  );

  const { metaDescription: contextDescription } = useContext(EsgPageContext);

  const siteMetadata = site!.siteMetadata!;
  const metaTitle = title || siteMetadata.title!;
  const metaDescription =
    description || contextDescription || siteMetadata.description!;

  const metaAuthor = siteMetadata.author!;
  const metaLink = link || siteMetadata.link!;

  const constantMeta: MetaProps[] = [
    {
      name: `description`,
      content: metaDescription,
    },
    {
      property: `og:title`,
      content: metaTitle,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      name: `twitter:card`,
      content: `summary`,
    },
    {
      name: `twitter:creator`,
      content: metaAuthor!,
    },
    {
      name: `twitter:title`,
      content: metaTitle,
    },
    {
      property: `og:description`,
      content: metaDescription,
    },
    {
      name: `twitter:description`,
      content: metaDescription,
    },
  ];

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      link={[metaLink]}
      title={metaTitle}
      titleTemplate="%s"
      meta={constantMeta.concat(meta)}
    />
  );
};

SEO.defaultProps = {
  title: 'ESG',
  description: 'IR/ESG',
  lang: 'en',
  link: '',
  meta: [],
};

export default SEO;
