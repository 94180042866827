import GetEEVideos from 'utils/GetEEVideos';
import useSlottedImage from 'hooks/useSlottedImage';
import SlicePadding from 'components/Energy/Layout/SlicePadding';
import './video.css';

import React, { useEffect, useState } from 'react';
import MediaCaptionVideoRemote from 'components/Esg/MediaCaption/MediaCaptionVideoRemote';
import { withPrefix } from 'gatsby';

const Video = data => {
  const {
    topspacing,
    bottomspacing,
    width,
    layout,
    videoThumbnail,
    video: {
      embed: { attributes: videoAttributes },
    },
    media,
  } = data;

  let videoFile = null;

  const {
    allMediaEeVideoFile: { nodes: fileVideoData },
    allMediaEeRemoteVideo: { nodes: remoteVideoData },
  } = GetEEVideos();

  const mediaRemote =
    media && media.__typename && media.__typename === 'media__ee_remote_video';

  const mediaLocal =
    media && media.__typename && media.__typename === 'media__ee_image';

  let alternateImage = null;
  if (
    videoAttributes['data-media-bundle'] === 'ee_remote_video' ||
    mediaRemote
  ) {
    const {
      0: {
        relationships: {
          thumbnail: { localFile },
        },
      },
    } = remoteVideoData.filter(
      v => v.drupal_id === videoAttributes['data-entity-uuid'],
    );
    alternateImage = localFile;
  }
  if (videoAttributes['data-media-bundle'] === 'ee_video_file' || mediaLocal) {
    videoFile = fileVideoData
      .filter(v => v.drupal_id === videoAttributes['data-entity-uuid'])
      .shift();
  }

  // This is an exception, the video image is slotted inside another slot
  // so can't be accessed through the slotted selector.
  const videoStyles = {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  };

  // Get the image markup.
  const markup = useSlottedImage(
    videoThumbnail,
    'preview',
    'videoThumbnail',
    videoStyles,
    alternateImage,
  );

  let videoUrl = '';

  const classeNames = ['video', width];

  if (videoFile) {
    const {
      relationships: { field_media_video_file },
    } = videoFile;
    videoUrl = field_media_video_file.localFile.url;
  } else {
    videoUrl =
      videoAttributes['data-media-url'] ?? media.field_media_oembed_video;
  }

  const [play, togglePlay] = useState(false);

  useEffect(() => {
    const videoCard = document.getElementById('video');
    const modalClose = (e: CustomEvent): void => {
      const {
        detail: { state },
      } = e;
      togglePlay(state);
    };

    videoCard?.addEventListener('modal-click-level-1', modalClose);
  }, []);

  return (
    <SlicePadding top={topspacing} bottom={bottomspacing}>
      <div className={classeNames.join(' ')}>
        {!mediaRemote ? (
          <atmos-video id="video" url={withPrefix(videoUrl)} layout={layout}>
            {markup}
          </atmos-video>
        ) : (
          <atmos-video id="video" remote>
            <div slot="videoEmbed">
              <MediaCaptionVideoRemote
                status={play}
                mediaComponent={{ videoEmbed: videoUrl }}
              />
            </div>
            {markup}
          </atmos-video>
        )}
      </div>
    </SlicePadding>
  );
};

export default Video;
