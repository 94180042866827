/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import Provider from './src/context/Provider';

/**
 * Called when the Gatsby browser runtime first starts.
 *
 * @returns {Promise<void>}
 */
export const onClientEntry = async () => {
  if (typeof IntersectionObserver === `undefined`) {
    console.info('importing intersection-observer');
    await import(`intersection-observer`);
  }
};

export const wrapRootElement = Provider;

const portal = document.createElement('div');
portal.id = 'portal';
document.body.appendChild(portal);
