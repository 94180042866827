import useSlottedImage from 'hooks/useSlottedImage';
import React, { useEffect, useState } from 'react';
import GetEEVideos from 'utils/GetEEVideos';
import HTMLReactParser from 'html-react-parser';
import MediaCaptionVideoRemote from 'components/Esg/MediaCaption/MediaCaptionVideoRemote';

const ContentSwitcher = data => {
  function camelFormat(title) {
    const newTitle = title.replace(/\//g, ' ');
    const titleArr = newTitle.split(' ');

    const formattedArr = titleArr.map(titleString => {
      return titleString.charAt(0).toUpperCase() + titleString.slice(1);
    });

    const pascalTitle = formattedArr.join('');
    const camelTitle =
      pascalTitle.charAt(0).toLowerCase() + pascalTitle.slice(1);
    return camelTitle;
  }

  function GetVideo(video, videoThumbnail) {
    const {
      embed: { attributes: videoAttributes },
    } = video;

    let videoFile = null;
    let remoteVideo = false;

    const {
      allMediaEeVideoFile: { nodes: fileVideoData },
      allMediaEeRemoteVideo: { nodes: remoteVideoData },
    } = GetEEVideos();

    let alternateImage = null;
    if (videoAttributes['data-media-bundle'] === 'ee_remote_video') {
      remoteVideo = true;
      const {
        0: {
          relationships: {
            thumbnail: { localFile },
          },
        },
      } = remoteVideoData.filter(
        v => v.drupal_id === videoAttributes['data-entity-uuid'],
      );
      alternateImage = localFile;
    }
    if (videoAttributes['data-media-bundle'] === 'ee_video_file') {
      videoFile = fileVideoData
        .filter(v => v.drupal_id === videoAttributes['data-entity-uuid'])
        .shift();
    }

    // This is an exception, the video image is slotted inside another slot
    // so can't be accessed through the slotted selector.
    const videoStyles = {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    };

    // Get the image markup.
    const markup = useSlottedImage(
      videoThumbnail,
      'preview',
      'videoThumbnail',
      videoStyles,
      alternateImage,
    );

    let videoUrl = '';

    if (videoFile) {
      const {
        relationships: { field_media_video_file },
      } = videoFile;
      videoUrl = field_media_video_file.localFile.url;
    } else {
      videoUrl = videoAttributes['data-media-url'];
    }

    return { videoUrl, markup, remoteVideo };
  }

  // calling the hook in a switch makes linter fail, this is a workaround.
  function GetSlotted(img, cls, slot) {
    return useSlottedImage(img, cls, slot);
  }

  function RenderTabContent(tab) {
    const [play, togglePlay] = useState(false);

    useEffect(() => {
      const videoCard = document.getElementById('video');
      const modalClose = (e: CustomEvent): void => {
        const {
          detail: { state },
        } = e;
        togglePlay(state);
      };

      videoCard?.addEventListener('modal-click-level-1', modalClose);
    }, []);

    return Object.keys(tab).map(item => {
      switch (item) {
        case 'content':
          return tab[item].map(t => {
            const { id } = t;
            return (
              <div key={id} className="mb-4">
                {HTMLReactParser(t.content.text)}
              </div>
            );
          });
          break;
        case 'image':
          return tab[item].map(t => {
            const markup = GetSlotted(t.image, 'preview', 'image');
            const { id } = t;
            return (
              <div key={id} className="mb-4">
                {markup}
              </div>
            );
          });
          break;
        case 'video':
          return tab[item].map(t => {
            const { videoUrl, markup, remoteVideo } = GetVideo(
              t.video,
              t.videoThumbnail,
            );
            const { id } = t;
            if (remoteVideo) {
              return (
                <div key={id} className="mb-4">
                  <atmos-video id="video" remote>
                    <div slot="videoEmbed">
                      <MediaCaptionVideoRemote
                        status={play}
                        mediaComponent={{ videoEmbed: videoUrl }}
                      />
                    </div>
                    {markup}
                  </atmos-video>
                </div>
              );
            }
            return (
              <div className="mb-4">
                <atmos-video url={videoUrl}>{markup}</atmos-video>
              </div>
            );
          });
          break;
        default:
          break;
      }
      return true;
    });
  }
  const { title, contentOptions } = data;

  return (
    <atmos-content-switcher
      title={title}
      selectors={JSON.stringify(contentOptions)}
    >
      <>
        {contentOptions.map(tab => {
          const { id } = tab;

          return (
            <div
              key={id}
              className="text font-body text-lg tracking-normal"
              slot={camelFormat(tab.title)}
            >
              {RenderTabContent(tab)}
            </div>
          );
        })}
      </>
    </atmos-content-switcher>
  );
};

export default ContentSwitcher;
