import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useInView } from 'react-intersection-observer';
import { BlTableQuery } from 'generated/graphqlCodegen';
import FadeIn from '../Animations/FadeIn';
import useTailwindConfig from '../../../hooks/useTailwindConfig';

library.add(faCircle);

interface BLTableMembers {
  data: [];
}

interface BLTableFooter {
  color: string;
  text: string;
}

interface IconColors {
  [key: string]: String;
}

/**
 * Board Leadership and Committees Table
 *
 * /esg/corporate-governance
 *
 * @param fieldComponent
 * @constructor
 */
const BLCTable = () => {
  const { data } = useStaticQuery<BlTableQuery>(
    graphql`
      query BLTable {
        data: allBoardLeadershipTableJson {
          nodes {
            th {
              text
              label
            }
            tr {
              data
            }
            footer {
              color
              text
            }
          }
        }
      }
    `,
  );

  // The icon used in the table.
  const IconProp = 'circle';

  // Destructure our nodes
  const { th, tr, footer } = data.nodes[0];

  // Lets us know when the component is in the viewport.
  const [ref, inView] = useInView({
    // Number between 0 and 1 indicating the percentage that should be visible
    // before triggering. Can also be an array of numbers, to create multiple
    // trigger points.
    threshold: 0.2,
    triggerOnce: true,
  });

  const {
    'esg-purple': purple,
    'esg-green': green,
    'esg-orange': orange,
    'esg-blue-light': ltBlue,
  } = useTailwindConfig().theme.colors;

  const colors: IconColors = {
    purple,
    green,
    orange,
    ltBlue,
  };

  return (
    <div
      ref={ref}
      datatype="BLCTable"
      key="board-leadership-committee-table"
      className="min-h-20"
    >
      {inView && (
        <FadeIn up>
          <table className="esg-matrix board-lc leading-tight my-20">
            <caption>
              <h5 className="font-md text-center text-2xl">
                Board Leadership & Committees
              </h5>
            </caption>
            <thead>
              <tr>
                {th.map(({ label, text }: BoardLeadershipTableJsonTh) => {
                  return (
                    <th
                      // When using .map() you must have a unique key for each item.
                      key={`${text ? `${text}-th` : 'no-text-th'}`}
                      className="lg:first:text-left lg:first:pl-3"
                      dangerouslySetInnerHTML={{ __html: text }}
                      aria-label={label || text}
                      scope="col"
                    />
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {tr.map((row: BLTableMembers) => {
                return (
                  // When using .map() you must have a unique key for each item.
                  <tr key={`${row.data[0]}-tr`}>
                    {row.data.map((value: string, trIndex: number) => {
                      const notApplicable = value === '' ? 'na' : 'valid';
                      const key = `${value}-td-${trIndex}`;
                      return (
                        <td
                          // When using .map() you must have a unique key for each item.
                          key={key}
                          className={`first:font-bold lg:first:font-normal first:text-left lg:first:pl-3 ${notApplicable}`}
                          data-label={
                            th[trIndex] && value !== '' ? th[trIndex].label : ''
                          }
                        >
                          {colors[value] ? (
                            <FontAwesomeIcon
                              title="Member"
                              icon={IconProp}
                              color={colors[value] as string}
                              size="sm"
                            />
                          ) : (
                            value
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
              {footer && (
                <tr key={Math.floor(Math.random() * 9999999999)}>
                  <td colSpan={7} className="legend">
                    {footer.map((item: BLTableFooter) => {
                      return (
                        <div
                          key={Math.floor(Math.random() * 9999999999)}
                          className="inline-block pr-2"
                        >
                          {colors[item.color] ? (
                            <FontAwesomeIcon
                              icon={IconProp}
                              color={colors[item.color] as string}
                              size="lg"
                              className="pr-1"
                            />
                          ) : (
                            item.color
                          )}
                          {item.text}
                        </div>
                      );
                    })}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </FadeIn>
      )}
    </div>
  );
};

export default BLCTable;
