import { withPrefix } from 'gatsby';
import useSlottedImage from 'hooks/useSlottedImage';
import React from 'react';
import { DrupalLinkEmbed } from 'types';

export interface InfoCardData {
  title: string;
  content: {
    text: string;
  };
  icon: string;
  circleInfo: any[];
  infoCardImage: any;
  link: DrupalLinkEmbed;
  padding: string;
  variant: string;
  overlayBottom: boolean;
}

/**
 * InfoCard component.
 */
const InfoCard = (data: InfoCardData) => {
  const opts: { 'overlay-bottom'?: boolean; 'sample-image'?: boolean } = {};

  const {
    title,
    content,
    icon,
    circleInfo,
    infoCardImage,
    link: {
      embed: {
        text: linkText,
        attributes: { href: linkHref, target, rel, title: linkTitle },
      },
    },
    padding,
    variant,
    overlayBottom,
  } = data;

  const {
    embed: { attributes },
  } = infoCardImage;

  const imageStyles = {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  };

  // Get the image markup.
  const image = useSlottedImage(
    infoCardImage,
    'preview',
    'infoImage',
    imageStyles,
  );

  if (overlayBottom) {
    opts['overlay-bottom'] = true;
  }

  if (attributes['data-entity-uuid']) {
    opts['sample-image'] = true;
  }

  return (
    <div className="info-card">
      <atmos-info-card
        id="infocard"
        title={title}
        text={content.text}
        card-icon={icon}
        card-number={circleInfo}
        url={withPrefix(linkHref)}
        target={target}
        rel={rel}
        link-title={linkTitle}
        link-text={linkText}
        card-padding={padding}
        variant={variant}
        {...opts}
      >
        {image}
      </atmos-info-card>
    </div>
  );
};

export default InfoCard;
