/* eslint import/no-cycle: "warn" */
import React, { useContext } from 'react';
import { useInView } from 'react-intersection-observer';
import { FeatureBasicFragment, NodePage } from 'generated/graphqlCodegen';
import FadeIn from '../Animations/FadeIn';
import Button from '../Shared/Button';
import EsgPageContext, { ComponentContext } from '../Context';
import { components } from '../../../utils/componentResolver';

/**
 * Section for the Title plus Content paragraph
 */
const FeatureBasic = (
  { fieldComponent }: FeatureBasicFragment,
  node: NodePage,
) => {
  const { esg } = useContext(EsgPageContext);
  const { customResolver } = useContext(ComponentContext);

  // Lets us know when the component is in the viewport.
  const [ref, inView] = useInView({
    // Number between 0 and 1 indicating the percentage that should be visible
    // before triggering. Can also be an array of numbers, to create multiple
    // trigger points.
    threshold: 0.8,
    triggerOnce: true,
  });

  const {
    relationships: {
      esgMedia: { color },
    },
  } = esg;

  const { id, heading, body, cta } = fieldComponent;

  // A paragraph component might be using the FeatureBasic paragraph
  // as a sub-component. This allows us to redirect this to the proper
  // component resolver.
  if (customResolver && components[`FeatureBasic_${customResolver}`]) {
    const CustomComponent = components[`FeatureBasic_${customResolver}`];
    return (
      <CustomComponent key={id} fieldComponent={fieldComponent} node={node} />
    );
  }

  const ctaPadding = cta ? 'pb-4' : '';
  // text-base leading-normal mb-5

  // This is the standard FeatureBasic paragraph
  return (
    <div
      id={id}
      ref={ref}
      className="min-h-20 py-4" // @note: Added py-4
      datatype="FeatureBasic"
    >
      {inView && (
        <FadeIn>
          {heading && heading.processed && (
            <div
              className="pt-4" // @note: Added pt-4
              dangerouslySetInnerHTML={{ __html: heading.processed }}
            />
          )}
          {body && body.processed && (
            <div
              className={`${ctaPadding}`}
              // If we want the margin on the body.
              // className={cta ? 'mb-5' : ''}
              dangerouslySetInnerHTML={{
                __html: body.processed,
              }}
            />
          )}
          {cta && (
            <Button
              color={color}
              text={cta.title}
              link={{
                href: cta.uri_alias || cta.uri,
                title: heading || cta.title,
              }}
            />
          )}
        </FadeIn>
      )}
    </div>
  );
};

export default FeatureBasic;
