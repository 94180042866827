import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { MediaImageFragment } from 'generated/graphqlCodegen';

interface Props {
  mediaComponent: MediaImageFragment;
}

/**
 * Section for the Title plus Content paragraph
 *
 * @param imageLeft
 * @constructor
 */

const MediaCaptionImage = ({ mediaComponent }: Props) => {
  const {
    id,
    mediaImageMeta,
    relationships: { mediaImage },
    shadow,
  } = mediaComponent;
  const mediaStyles = `m-auto border-white border-8 ${shadow}`;

  if (!mediaImage) {
    return null;
  }

  // Get the image.
  const image = getImage(mediaImage.localFile);

  let captionImage;

  if (image) {
    captionImage = (
      <GatsbyImage
        key={id}
        className={mediaStyles}
        image={image}
        alt={mediaImageMeta.alt}
        title={mediaImageMeta.title}
      />
    );
  } else {
    captionImage = (
      <img
        key={id}
        src={mediaImage.localFile.publicURL}
        alt={mediaImageMeta.alt}
        title={mediaImageMeta.title}
      />
    );
  }

  return <>{captionImage}</>;
};

export default MediaCaptionImage;
