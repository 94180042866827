import React, { useContext } from 'react';
import { graphql } from 'gatsby';
import Layout from 'components/Esg/Shared/Layout';
import SEO from 'components/Esg/Shared/SEO';
import layoutResolver from 'utils/layoutResolver';
import EsgPageContext, { EsgPageProvider } from 'components/Esg/Context';
import useEsgPages from 'hooks/useEsgPages';
import { NodePageFragment } from 'generated/graphqlCodegen';
import { PageTemplate } from 'types';
import '../styles/esg/index.css';

// This makes all fragments globally available
import '../fragments';

/**
 * Page template for ESG.
 *
 * The following tailwind classes are classes that would be purged since they
 * are built dynamically via variables:
 *
 * text-esg-blue text-esg-blue-light text-esg-orange text-esg-purple text-esg-green text-esg-yellow
 * bg-esg-blue bg-esg-blue-light bg-esg-orange bg-esg-purple bg-esg-green bg-esg-yellow
 * bg-esg-blue-grad bg-esg-blue-light-grad bg-esg-orange-grad bg-esg-purple-grad bg-esg-green-grad bg-esg-yellow-grad
 */
const Page = ({ data, pageContext }: PageTemplate) => {
  const esgDefault = useContext(EsgPageContext);
  const esgBranding = useEsgPages();

  if (!data) {
    throw new Error('No data from Page component');
  }

  const node: NodePageFragment = data.json.nodes[0];

  if (node.relationships === null) {
    throw new Error(`Missing relationships for node ${node.title}`);
  }

  let esg;

  const { layout, custom } = node.relationships;

  // Drupal menus.
  const { menus } = pageContext;

  // Some "sub" pages use ESG data from their "parent" page. In this case,
  // `esg` will be null so we will determine the sub page's parent ESG data
  // based on its alias. e.g., /foo is the "parent" page for /foo/bar since
  // it extends its URL.
  if (!custom || !custom.esg) {
    const {
      path: { alias },
    } = node;
    const esgBrand = esgBranding.filter(
      brand =>
        brand?.relationships.custom.relationships.nodePage[0].path.alias ===
        alias,
    );
    esg =
      esgBrand && esgBrand[0]
        ? esgBrand[0].relationships.custom
        : esgDefault.esg;
  }

  const content = layoutResolver(layout, node);

  return (
    // This allows us to easily add "arguments" from context later.
    <EsgPageProvider value={{ esg, other: { pageTitle: node.title } }}>
      <Layout className="" menus={menus}>
        <SEO title={`${node.title} | Atmos Energy`} />
        {content && <>{content}</>}
      </Layout>
    </EsgPageProvider>
  );
};

export const AllPagesQuery = graphql`
  query Page($alias: String!) {
    json: allNodePage(
      filter: { path: { alias: { eq: $alias } }, status: { eq: true } }
    ) {
      nodes {
        ...nodePage
      }
    }
  }
`;

// @todo: Move all fragments below to their own file in project/src/fragments
export const EsgIconRing = graphql`
  fragment EsgIconRing on paragraph__esg_icon_ring {
    __typename
    id
    relationships {
      components: field_components {
        __typename
        id
        relationships {
          mediaUnlimited: field_media_unlimited {
            ...MediaESG
          }
        }
      }
    }
  }
`;

export const EsgCardOverview = graphql`
  fragment EsgCardOverview on paragraph__esg_card_overview {
    __typename
    id
    placement: field_placement
    relationships {
      components: field_components {
        __typename
        id
        ...MediaSingle
      }
    }
  }
`;

export const MediaSingle = graphql`
  fragment MediaSingle on paragraph__media_single {
    __typename
    id
    relationships {
      media: field_media {
        ...MediaESG
        ...MediaImage
      }
      components: field_components {
        ...FeatureBasic
      }
    }
  }
`;

// ESG Media
// This also gets the title and alias from the node this media is on.
export const MediaESG = graphql`
  fragment MediaESG on media__esg {
    __typename
    id
    name
    segment: field_ring_segment
    color: field_theme
    mediaImageMeta: field_media_image {
      alt
      title
    }
    relationships {
      mediaImage: field_media_image {
        __typename
        id
        localFile {
          publicURL
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
          }
        }
      }
      esgBranding: paragraph__esg_branding {
        __typename
        id
        relationships {
          nodePage: node__page {
            title
            path {
              alias
            }
          }
        }
      }
      icon: field_icon {
        id
        localFile {
          name
          publicURL
        }
      }
    }
  }
`;

// Full Width Layout, single column

// Full Width Layout, single column
export const Layout3Column = graphql`
  fragment Layout3Column on paragraph__layout_3_col {
    __typename
    id
    relationships {
      left: field_sidebar_left {
        __typename
        id
        ...componentGroup
      }
      primary: field_content_primary {
        __typename
        id
        ...componentGroup
      }
      right: field_sidebar_right {
        __typename
        id
        ...componentGroup
      }
    }
  }
`;

export const ParallaxBgComponents = graphql`
  fragment ParallaxBgComponents on paragraph__feature_components_bg {
    __typename
    id
    relationships {
      media: field_media {
        ...MediaImage
      }
      components: field_components {
        ...FeatureBasic
        ...Metrics
      }
    }
  }
`;

// Hero related
export const HeroFullImage = graphql`
  fragment HeroFullImage on paragraph__hero_full_image {
    __typename
    id
    relationships {
      media: field_media {
        ...MediaImage
      }
      components: field_components {
        ...FeatureBasic
      }
    }
  }
`;

export const HeroIntro = graphql`
  fragment HeroIntro on paragraph__hero_intro {
    __typename
    id
    relationships {
      components: field_components {
        ...FeatureBasic
      }
    }
  }
`;

// Base
export const FeatureBasic = graphql`
  fragment FeatureBasic on paragraph__feature_basic {
    __typename
    id
    heading: field_heading {
      processed
    }
    body: field_body {
      processed
    }
    description: field_description {
      processed
    }
    cta: field_cta {
      title
      uri_alias
      uri
    }
  }
`;

// Media
export const MediaImage = graphql`
  fragment MediaImage on media__image {
    __typename
    id
    name
    mediaDisplay: field_display
    mediaLink: field_link {
      title
      uri
    }
    mediaImageMeta: field_media_image {
      alt
      title
    }
    shadow: field_image_shadow
    relationships {
      mediaImage: field_media_image {
        __typename
        id
        filemime
        localFile {
          publicURL
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
          }
        }
      }
    }
  }
`;

// Embedded Video
export const MediaRemoteVideo = graphql`
  fragment MediaRemoteVideo on media__remote_video {
    __typename
    id
    videoEmbed: field_media_oembed_video
    mediaImageMeta: field_media_image {
      alt
      title
    }
    relationships {
      mediaImage: field_media_image {
        filemime
        localFile {
          publicURL
        }
      }
    }
  }
`;

export const MediaVideo = graphql`
  fragment MediaVideo on media__video_file {
    __typename
    id
    relationships {
      video: field_media_video_file {
        id
        filemime
        localFile {
          publicURL
        }
      }
    }
  }
`;

export const CaptionedMedia = graphql`
  fragment CaptionedMedia on paragraph__captioned_media {
    __typename
    id
    layout: field_component_layout
    border: field_component_border
    relationships {
      media: field_media {
        ...MediaImage
        ...MediaVideo
        ...MediaRemoteVideo
      }
      components: field_components {
        ...FeatureBasic
      }
    }
  }
`;

// Gatsby components
export const GatsbyComponent = graphql`
  fragment GatsbyComponent on paragraph__gatsby_component {
    __typename
    id
    key: field_resolver_key
  }
`;

export const Accordion = graphql`
  fragment Accordion on paragraph__accordion {
    __typename
    id
    heading: field_heading {
      processed
    }
    relationships {
      accordionItems: field_accordion_items {
        ...AccordionItem
      }
    }
  }
`;

export const AccordionItem = graphql`
  fragment AccordionItem on paragraph__accordion_item {
    __typename
    id
    relationships {
      media: field_media {
        ...MediaImage
      }
      components: field_components {
        ...FeatureBasic
      }
    }
  }
`;

// Animated Metrics
export const Metrics = graphql`
  fragment Metrics on paragraph__metrics {
    __typename
    id
    heading: field_heading {
      processed
    }
    relationships {
      metrics: field_metrics {
        __typename
        id
        number: field_number
        description: field_description {
          processed
        }
        prefix: field_prefix
        suffix: field_suffix
      }
    }
  }
`;

// Links List
export const LinkList = graphql`
  fragment LinkList on paragraph__link_list {
    __typename
    id
    listLinks: field_links {
      title
      uri
    }
  }
`;

export const MediaGrid = graphql`
  fragment MediaGrid on paragraph__media_grid {
    __typename
    id
    relationships {
      mediaUnlimited: field_media_unlimited {
        ...MediaImage
      }
      components: field_components {
        ...FeatureBasic
      }
    }
  }
`;

export const MediaLinks = graphql`
  fragment MediaLinks on paragraph__media_links {
    __typename
    id
    relationships {
      mediaUnlimited: field_media_unlimited {
        ...MediaImage
      }
      components: field_components {
        ...FeatureBasic
      }
    }
  }
`;

export const PngAnimation = graphql`
  fragment PngAnimation on paragraph__png_animation {
    __typename
    id
    relationships {
      mediaUnlimited: field_media_unlimited {
        ...MediaImage
      }
    }
  }
`;

export default Page;
