import { EsgChartConfig } from 'types';

const defaultConfig: EsgChartConfig = {
  type: 'bar',
  options: {
    indexAxis: 'x',
    aspectRatio: 1.6,
    responsive: true,
    maintainAspectRatio: true, // test other charts
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: value => {
            return value;
          },
        },
      },
      x: {},
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        // Creates separate tooltip for each "stacked" color vs all colors in
        // a single tooltip.
        mode: 'nearest',
        callbacks: {
          label: ({ formattedValue }) => Number(formattedValue),
        },
      },
      datalabels: {
        align: 'end',
        anchor: 'end',
        padding: 0,
        formatter: ({ formattedValue }) => formattedValue,
      },
    },
  },
};

export default defaultConfig;
