/**
 * Displays on /esg/safety
 */
import React from 'react';
import { merge } from 'lodash';
import BarChart from '../Bar';
import { EsgChartConfig } from 'types';
import defaultConfig from './config';

/**
 * Configuration for the chart.
 */
const chartConfig: EsgChartConfig = {
  data: {
    labels: [
      '2009',
      '2010',
      '2011',
      '2012',
      '2013',
      '2014',
      '2015',
      '2016',
      '2017',
      '2018',
      '2019',
      '2020',
      '2021E',
      '2022E',
      '2023E',
      '2024E',
      '2025E',
    ],
    datasets: [
      {
        data: [
          52, 53, 46, 41, 45, 57, 52, 43, 48, 55, 49, 48, 55, 58, 61, 65, 68,
        ],
        backgroundColor: '#2175D9',
      },
    ],
  },
  options: {
    title: {
      text: 'Average Monthly Customer Bill',
    },
    scales: {
      y: {
        ticks: {
          callback: value =>
            value.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            }),
        },
      },
    },
    plugins: {
      subtitle: {
        display: true,
        text: 'Period Ending',
        position: 'bottom',
        padding: {
          top: 20,
        },
      },
      tooltip: {
        callbacks: {
          label: ({ formattedValue }) =>
            Number(formattedValue).toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            }),
        },
      },
      datalabels: {
        // Original arguments: (value, context), this is a destructuring of
        // `context`.
        formatter: (
          value,
          {
            chart: {
              config: { data },
            },
            dataIndex,
          },
        ) => {
          const year = data.labels[dataIndex];
          // For specific years they want the "value" to show at the top of the
          // corresponding bar.
          return year === '2009' || year === '2020' || year === '2025E'
            ? `$${value}`
            : '';
        },
      },
    },
  },
  // Custom config we added for this chart.
  footnote: {
    text: '* Estimates exclude costs associated with the February 2021 winter storm',
  },
};

const config = merge({}, defaultConfig, chartConfig);

/**
 * Average Monthly Custom Bills : Bar
 *
 * @constructor
 */
const Amcb = () => <BarChart name="Amcb" props={config} />;

export default Amcb;
