import React from 'react';
import BackgroundImage from 'gatsby-background-image';
import ComponentResolver from 'utils/componentResolver';
import {
  NodePage,
  ParallaxBgComponentsFragment,
} from 'generated/graphqlCodegen';

interface ParallaxBg {
  fieldComponent: ParallaxBgComponentsFragment;
  node: NodePage;
}

/**
 * Paragraph that uses TitlePlusContent & Metrics.
 *
 * This component allows us to render the components differently using our
 * ComponentResolver and passing in the name of the name of a "customResolver".
 *
 * @param fieldComponent
 * @param node
 * @constructor
 */
const ComponentsParallaxBg = ({ fieldComponent, node }: ParallaxBg) => {
  const {
    relationships,
    relationships: { components, media },
  } = fieldComponent;

  if (!relationships) {
    return null;
  }

  // This is used as part of the key in our ComponentResolver to render
  // these components with different jsx.
  const customResolver = 'ComponentsParallaxBg';

  const resolvedComponents = ComponentResolver(
    components,
    node,
    customResolver,
  );

  return (
    <BackgroundImage
      className="w-full clear-both pb-24 bg-cover bg-center bg-fixed pt-24 md:pt-40 lg:pt-48"
      fluid={media.relationships.mediaImage.localFile.childImageSharp.fluid}
      dataType="ComponentsParallaxBg"
    >
      <>{resolvedComponents && <>{resolvedComponents}</>}</>
    </BackgroundImage>
  );
};

export default ComponentsParallaxBg;
