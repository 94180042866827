import React, { useContext } from 'react';
import linkResolver from 'utils/linkResolver';
import EsgPageContext from 'components/Esg/Context';
import { HeroIntroFragment } from 'generated/graphqlCodegen';

const HeroIntro = ({ fieldComponent }: HeroIntroFragment) => {
  const { relationships } = fieldComponent;
  const {
    components: {
      0: { heading, body },
    },
  } = relationships;
  const { esg } = useContext(EsgPageContext);
  const { color } = esg.relationships.esgMedia;

  return (
    <div
      id="hero-secondary-intro"
      className={`section border-${color} border-t-8`}
    >
      <div className="container text-center font-book">
        <div className="w-full md:w-10/12 lg:w-9/12 mx-auto px-4 md:px-12">
          <div
            dangerouslySetInnerHTML={{
              __html: linkResolver(heading.processed),
            }}
            className={`text-${color}`}
          />
          <div
            dangerouslySetInnerHTML={{
              __html: linkResolver(body.processed),
            }}
            className="text-lg text-esg-gray-dark leading-relaxed"
          />
        </div>
      </div>
    </div>
  );
};

export default HeroIntro;
