import React, { useEffect } from 'react';
import { WysiwygComponent } from 'types';
import SlicePadding from 'components/Energy/Layout/SlicePadding';
import './wysiwyg.css';

const Wysiwyg = (data: WysiwygComponent) => {
  const {
    content: { text },
    topspacing,
    bottomspacing,
    width,
    align,
  } = data;

  const classeNames = ['wysiwyg', width, align];

  useEffect(() => {
    const documentLinks = document.querySelectorAll('.wysiwyg-wrapper a');

    if (documentLinks.length > 0) {
      documentLinks.forEach(link => {
        const elem = link.getAttribute('href');
        if (elem && elem.startsWith('/document')) {
          link.setAttribute('target', '_blank');
        }
      });
    }

    console.log(documentLinks);
  }, []);

  // Return the markup from the wysiwyg editor.
  return (
    <SlicePadding top={topspacing} bottom={bottomspacing}>
      <div className="wysiwyg-wrapper">
        <div
          className={classeNames.join(' ')}
          dangerouslySetInnerHTML={{ __html: text || '' }}
        />
      </div>
    </SlicePadding>
  );
};

export default Wysiwyg;
