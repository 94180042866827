/**
 * Displays on /esg/safety
 */
import React from 'react';
import { merge } from 'lodash';
import BarChart from '../Bar';
import { EsgChartConfig } from 'types';
import defaultConfig from './config';

/**
 * Configuration for the chart.
 */
const chartConfig: EsgChartConfig = {
  description:
    'Days Away/Restricted/Transfer Injury Rate (per 200,000 hours worked)',
  data: {
    labels: ['2014', '2015', '2016', '2017', '2018', '2019'],
    datasets: [
      {
        data: [1.72, 1.94, 2.31, 1.92, 1.61, 1.69],
        backgroundColor: '#FF8D1E',
      },
    ],
  },
  options: {
    title: {
      display: false,
      text: 'DART',
    },
    scales: {
      y: {
        max: 2.5,
        ticks: {
          callback: value => value.toFixed(2),
        },
      },
      x: {
        title: {
          display: false,
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: ({ formattedValue }) => Number(formattedValue).toFixed(2),
        },
      },
      datalabels: {
        display: false,
      },
    },
  },
};

const config = merge({}, defaultConfig, chartConfig);

/**
 * Days Away/Restricted/Transfer Injury Rate
 *
 * @constructor
 */
const Dart = () => <BarChart name="Dart" props={config} />;

export default Dart;
