/**
 * Displays on /esg/financials
 *
 * Data: eps-table.json
 *
 * @todo How do we shade this line chart below the line?
 * @see https://atmosenergy.com/esg/financials
 */
import React from 'react';
import { merge } from 'lodash';
import Line from '../Line';
import { EsgChartConfig } from 'types';
import defaultConfig from './config';

const chartConfig: EsgChartConfig = {
  type: 'line',
  data: {
    labels: [
      '2006',
      '2007',
      '2008',
      '2009',
      '2010',
      '2011',
      '2012',
      '2013',
      '2014',
      '2015',
      '2016',
      '2017',
      '2018',
      '2019',
      '2020',
    ],
    datasets: [
      {
        data: [
          1.81, 1.74, 1.84, 1.9, 2.03, 2.07, 2.1, 2.5, 2.96, 3.09, 3.38, 3.6,
          4.0, 4.35, 4.72,
        ],
        label: 'Earnings Per Share',
        borderColor: '#FF8D1E',
        pointHoverBorderColor: '#FF8D1E',
        borderWidth: 5,
        pointRadius: 3,
        pointHoverRadius: 4,
        pointHoverBorderWidth: 4,
        // Fill the grid below the line
        fill: {
          target: 'origin',
        },
      },
    ],
  },
  options: {
    scales: {
      y: {
        title: {
          display: true,
          text: 'Adjusted EPS',
        },
      },
      x: {
        title: {
          display: true,
          text: 'Fiscal Year End',
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          labelColor: () => ({ backgroundColor: '#FF8D1E' }),
          label: ({ formattedValue }) =>
            Number(formattedValue).toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            }),
        },
      },
    },
  },
};

const config = merge({}, defaultConfig, chartConfig);

/**
 * Page: /esg/environment
 * @constructor
 */
const EpsLine = () => <Line name="EpsLine" props={config} />;

export default EpsLine;
