import React, { useEffect } from 'react';
import { GradientColumn } from 'types';
import ContentResolver from 'components/Energy/ContentResolver/ContentResolver';

const GradientBackground = (data: GradientColumn) => {
  const { columnOne, size } = data;

  const columnOneComponents = ContentResolver({ content: columnOne });

  useEffect(() => {
    const gradientElement = document.querySelector(
      '#gradient-background',
    ) as HTMLElement;
    const lastEl = gradientElement?.lastChild as HTMLElement;

    // If last element is recognizing a leak add margin.
    if (lastEl.className === 'recognizing-leak') {
      lastEl.style.marginBottom = '480px';
    }
    // If last element is an info card add margin.
    if (lastEl.className === 'info-card') {
      lastEl.style.marginBottom = '150px';
    }

    const nextEl = gradientElement?.nextSibling as HTMLElement;
    if (gradientElement && nextEl && nextEl.classList.contains('overlay-top')) {
      gradientElement.insertAdjacentElement('beforeEnd', nextEl);
    }
  }, []);

  const style = {
    background: `linear-gradient(1.48deg, #C2DEFF 1.51%, rgba(194, 222, 255, 0) ${
      size === 'small' ? '50%' : '90.41%'
    })`,
  };

  return (
    <>
      <div style={style} id="gradient-background">
        {columnOneComponents && <>{columnOneComponents}</>}
      </div>
    </>
  );
};

export default GradientBackground;
