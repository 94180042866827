import React from 'react';
import MediaCaptionImage from 'components/Esg/MediaCaption/MediaCaptionImage';
import MediaCaptionVideoRemote from 'components/Esg/MediaCaption/MediaCaptionVideoRemote';
import MediaCaptionVideoFile from 'components/Esg/MediaCaption/MediaCaptionVideoFile';
import MediaGridImage from 'components/Esg/MediaGrid/MediaGridImage';
import MediaLinkImage from 'components/Esg/MediaLink/MediaLinkImage';
import EsgMediaIconGrid from 'components/Esg/MediaGrid/MediaGridIcon';
import EsgMediaOverviewSingle from 'components/Esg/MediaSingle/MediaSingleOverview';
import MediaSingleImage from 'components/Esg/MediaSingle/MediaSingleImage';

interface Media {
  [key: string]: Function;
}

interface Extras {
  columns: number;
}

export const media: Media = {
  'media__image-paragraph__esg_card_overview': MediaCaptionImage,
  'media__image-paragraph__captioned_media': MediaCaptionImage,
  'media__remote_video-paragraph__captioned_media': MediaCaptionVideoRemote,
  'media__video_file-paragraph__captioned_media': MediaCaptionVideoFile,
  'media__image-paragraph__media_grid': MediaGridImage,
  'media__image-paragraph__media_links': MediaLinkImage,
  'media__esg-paragraph__media_grid-icon': EsgMediaIconGrid,
  'media__esg-paragraph__media_single': EsgMediaOverviewSingle,
  'media__esg-paragraph__esg_card_overview': EsgMediaOverviewSingle,
  'media__image-paragraph__media_single': MediaSingleImage,
};

/**
 * Resolver for media used across different paragraphs.
 *
 * @param mediaComponent
 * @param type
 * @param extras
 */
const mediaResolver = (
  mediaComponent,
  type: string,
  extras?: Extras,
  // customResolver?: string,
) => {
  const { __typename: mediaType, relationships } = mediaComponent;

  // @notes...
  // Handle ESG media a little differently.
  // if (mediaType === 'media__esg') {
  //   // ESG media can have an overview image and/or an icon.
  //   // @NOTE: We are only using icon for the time being.
  //   const { icon, mediaImage } = relationships;
  //   if (icon) {
  //     type = `${type}-icon`;
  //   }
  // }

  // The `media` we are targeting.
  const target = `${mediaType}-${type}`;

  // if (!media[`${target}`]) {
  //   return <h1>`Target: ${target} not found!`</h1>;
  // }
  //
  // return media[`${target}`];

  if (media[`${target}`]) {
    // Render media based on the `target`.
    switch (target) {
      // This first case will only be triggered if when adding an "ESG Card
      // Overview" component and choosing the 'Image' type vs 'ESG'.
      case 'media__image-paragraph__esg_card_overview':
      case 'media__image-paragraph__captioned_media':
      case 'media__remote_video-paragraph__captioned_media':
      case 'media__video_file-paragraph__captioned_media':
      case 'media__image-paragraph__media_single': {
        const Captioned = media[target];
        return <Captioned mediaComponent={mediaComponent} />;
      }
      case 'media__image-paragraph__media_grid': {
        const ImageGrid = media[`${target}`];
        return extras ? (
          <ImageGrid
            // image={relationships.mediaImage}
            mediaComponent={mediaComponent}
            columns={extras.columns}
          />
        ) : null;
      }
      // This component uses unlimited media items.
      case 'media__esg-paragraph__media_grid-icon': {
        // 'grid-icon'
        const IconGrid = media[`${target}`];
        const esgMedia = mediaComponent;
        // Format used by esg media types.
        const esg = { relationships: { esgMedia } };
        return (
          <>
            <div
              className="mx-auto max-w-6xl flex flex-wrap justify-center text-esg-gray-dark"
              id="esg-ring-sequence"
            >
              <IconGrid esg={esg} />
            </div>
          </>
        );
      }
      case 'media__image-paragraph__media_links': {
        const ImageLinks = media[target];
        return <ImageLinks mediaComponent={mediaComponent} />;
      }
      // This component uses unlimited media items.
      case 'media__esg-paragraph__media_grid': {
        const Grid = media[`${target}`];
        return extras ? (
          <Grid image={relationships.mediaImage} columns={extras.columns} />
        ) : null;
      }
      case 'media__esg-paragraph__media_single':
      case 'media__esg-paragraph__esg_card_overview': {
        const EsgMedia = media[target];
        return <EsgMedia mediaComponent={mediaComponent} />;
      }
      default:
        return <h1 className="m-20">{target}</h1>;
    }
  }
  return <h1 className="m-20">{target}</h1>;
};

export default mediaResolver;
