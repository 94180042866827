import React from 'react';
import { useInView } from 'react-intersection-observer';
import { registerables } from 'chart.js';
import { ReactChart } from 'chartjs-react';
import { EsgChartConfig } from 'types';

ReactChart.register(...registerables);

const Line = ({ props }: EsgChartConfig, name: string) => {
  const {
    data,
    options,
    options: { title, aspectRatio },
    plugins,
  } = props;

  // Lets us know when the component is in the viewport.
  const [ref, inView] = useInView({
    // Number between 0 and 1 indicating the percentage that should be visible
    // before triggering. Can also be an array of numbers, to create multiple
    // trigger points.
    threshold: 0.2,
    triggerOnce: true,
  });

  // This component should fade in and up
  return (
    <figure
      datatype={`${name}|Line`}
      ref={ref}
      className="my-20 fade-in-up opacity-0 opacity-100 animated fadeInUpSmall"
    >
      <>
        {inView && (
          <>
            <figcaption className="pb-2">
              {title && (
                <h5 className=" font-md mb-0 text-center text-2xl">
                  {title.text}
                </h5>
              )}
            </figcaption>
            <div className="w-full lg:w-10/12 mx-auto px-0 md:px-4 mb-6 md:mb-0">
              <ReactChart
                type="line"
                width={aspectRatio ? 400 * aspectRatio : 400}
                height={aspectRatio ? 400 : 200}
                data={data}
                options={options}
                plugins={plugins}
              />
            </div>
          </>
        )}
      </>
    </figure>
  );
};

export default Line;
