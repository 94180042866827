import { useEffect } from 'react';

const DocumentPage = ({
  pageContext,
}: {
  pageContext: { redirect: string };
}) => {
  const { redirect } = pageContext;

  useEffect(() => {
    setTimeout(() => {
      window.close();
    }, 500);

    document.location = redirect;
  }, [redirect]);

  return null;
};

export default DocumentPage;
