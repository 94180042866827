import React from 'react';
import { SlicePaddingTypes } from 'types';

const SlicePadding = (props: SlicePaddingTypes) => {
  const { top, bottom, children } = props;

  const style = {
    paddingTop: '0',
    paddingBottom: '0',
  };

  if (top) style.paddingTop = '64px';
  if (bottom) style.paddingBottom = '64px';

  return <div style={style}>{children}</div>;
};

export default SlicePadding;
