import React from 'react';
import { registerables } from 'chart.js';
import { useInView } from 'react-intersection-observer';
import { ReactChart } from 'chartjs-react';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import ChartDoughnutLabels from '@scottalan/chartjs-plugin-doughnutlabel';
import { EsgChartConfig } from 'types';

ReactChart.register(...registerables, ChartDataLabels, ChartDoughnutLabels);

const Doughnut = ({ props }: EsgChartConfig, name: string) => {
  const { data, options, plugins, subText } = props;

  // Lets us know when the component is in the viewport.
  const [ref, inView] = useInView({
    // Number between 0 and 1 indicating the percentage that should be visible
    // before triggering. Can also be an array of numbers, to create multiple
    // trigger points.
    threshold: 0.2,
    triggerOnce: true,
  });

  // This component should fade in and up
  return (
    <figure
      datatype={`${name}|Doughnut`}
      ref={ref}
      className="flex flex-wrap items-center mt-10 flow-root mb-24"
    >
      <>
        {inView && (
          <div className="w-full lg:w-10/12 mx-auto px-0 md:px-4 mb-6 md:mb-0">
            <ReactChart
              type="doughnut"
              data={data}
              options={options}
              plugins={plugins}
              width={400}
              height={400}
            />
            {subText && (
              <p className="text-sm block leading-tight text-center">
                {subText}
              </p>
            )}
          </div>
        )}
      </>
    </figure>
  );
};

/**
 * Finds the data displayed on hover
 *
 * @param tooltipItem
 * @param tooltipData
 */
const getDoughnutTooltip = (
  tooltipItem: ChartTooltipItem,
  tooltipData: ChartData,
) => {
  if (tooltipItem.index !== undefined) {
    if (tooltipData.labels && tooltipData.labels.length) {
      const tooltipLabel = tooltipData.labels[tooltipItem.index];
      if (tooltipData.datasets && tooltipData.datasets.length) {
        const { data } = tooltipData.datasets[0];
        if (data && data.length) {
          const tooltipValue = data[tooltipItem.index];
          return { label: tooltipLabel, value: tooltipValue };
        }
      }
    }
  }
  return { label: undefined, value: undefined };
};

export default Doughnut;

export { getDoughnutTooltip };
