/**
 * React Hook
 *
 * This hook will only "listen" while the element is in the viewport
 *
 * @param attributes media attributes
 * @param nodes all nodes from media type
 * @param class class for media
 * @param slot name of the slot that contains the image
 * @param styles custom styles for the image
 */

import React, { CSSProperties } from 'react';
import { GatsbyImage, getImage, ImageDataLike } from 'gatsby-plugin-image';
import GetEEImages from 'utils/GetEEImages';

interface imageType {
  embedHTML: string;
  embed: {
    attributes: { [key: string]: string };
  };
}

type imageMedia = {
  publicURL: string;
} & ImageDataLike;

export default function useSlottedImage(
  imageSlotted: imageType,
  imageClass?: string,
  slot?: string,
  styles?: CSSProperties,
  alternateImage?: imageMedia | null,
) {
  const {
    allMediaEeImage: { nodes },
  } = GetEEImages();

  // If media is not defined return empty.
  if (!imageSlotted) {
    return null;
  }
  // Accessing attributes here as well because we need the original image url.
  const {
    embed: { attributes },
  } = imageSlotted;

  if (!attributes['data-entity-uuid']) {
    if (alternateImage) {
      const image = getImage(alternateImage);

      if (image) {
        return image ? (
          <div slot={slot}>
            <GatsbyImage
              className={imageClass}
              image={image}
              imgStyle={{ maxWidth: '100%', width: '100%' }}
              alt="default text"
              style={styles}
            />
          </div>
        ) : null;
      } else if (alternateImage.publicURL) {
        return (
          <div slot={slot}>
            <img
              className={imageClass}
              src={alternateImage.publicURL}
              alt="default text"
              style={styles}
            />
          </div>
        );
      }
    }
    return null;
  }

  let imageUUID = '';
  if (attributes['data-entity-type'] === 'node') {
    imageUUID = attributes['data-media-uuid'];
  } else {
    imageUUID = attributes['data-entity-uuid'];
  }

  if (imageUUID !== undefined) {
    // Filter the nodes and return the media entity from our query with the
    // matching uuid.
    const mediaNode = nodes.filter(node => node.drupal_id === imageUUID);

    if (mediaNode.length > 0) {
      const { relationships, field_media_image: imageAttr } = mediaNode[0];

      if (relationships && imageAttr) {
        const { field_media_image: mediaImage } = relationships;
        // Sets the "localFile" variable.
        const { alt, title } = imageAttr;

        if (mediaImage) {
          // Get the image.
          const image = getImage(mediaImage.localFile);

          return image ? (
            <div slot={slot}>
              <GatsbyImage
                className={imageClass}
                image={image}
                imgStyle={{ maxWidth: '100%', width: '100%' }}
                alt={alt || ''}
                title={title || ''}
                style={styles}
              />
            </div>
          ) : null;
        }

        return null;
      }
    }
    return null;
  }

  return null;
}
