import React, { useState, useEffect, ReactNode, createContext } from 'react';
import Cookies from 'js-cookie';

interface RegionContext {
  region: string | undefined;
  updateRegion?: (region: string) => void;
}

const defaultRegionContext: RegionContext = {
  region: undefined,
  updateRegion: () => {},
};

// @see https://github.com/DefinitelyTyped/DefinitelyTyped/pull/24509#issuecomment-382213106
export const regionContext = createContext(defaultRegionContext);
export const RegionProvider = regionContext.Provider;

const Provider = ({ children }: { children: ReactNode }) => {
  const [region, setRegion] = useState<string>();

  useEffect(() => {
    setRegion(Cookies.get('region'));
  }, []);

  return (
    <RegionProvider
      value={{
        region,
        updateRegion: r => {
          if (r) {
            Cookies.set('region', r);
          } else {
            Cookies.remove('region');
          }
          setRegion(r);
        },
      }}
    >
      {children}
    </RegionProvider>
  );
};

export default ({ element }: { element: ReactNode }) => (
  <Provider>{element}</Provider>
);
