import React from 'react';
import { useInView } from 'react-intersection-observer';
import { useSpring } from 'react-spring';
import { isEmpty } from 'lodash';
import { MediaEsgFragment } from 'generated/graphqlCodegen';
import RingSegments from '../Ring/RingSegments';
import Link from '../Shared/Link';

interface Props {
  esgMedia: MediaEsgFragment;
  count?: number;
}

/**
 * Renders an ESG icon in a grid.
 *
 * @param {EsgBrandingFragment} esgMedia
 * The ESG Media representing the icon that will render in the grid.
 * @param {number} count
 * Used to determine what classes should be applied.
 */
const EsgMediaIconGrid = ({ esgMedia, count = 0 }: Props) => {
  // let { other } = useContext(EsgPageContext);

  // We render the name of the media, i.e., Corporate Governance, under the
  // icon.
  // const { name, relationships: mediaRel } = esgMedia;

  // Get the title and path of the "parent" esg node.
  // const { relationships: { nodePage: { 0: parentPage } } } = esg;
  // const title = name || parentPage.title;
  // const path = parentPage.path.alias;

  // We render the name of the media, i.e., Corporate Governance, under the
  // icon.
  const { name, relationships: mediaRel } = esgMedia;
  const { esgBranding } = mediaRel;

  // This is necessary to ensure we don't iterate over "non-existent"
  // paragraphs. https://www.drupal.org/project/paragraphs/issues/2935630
  const branding = esgBranding
    ? esgBranding.filter(brand => {
        return !isEmpty(brand.relationships.nodePage);
      })
    : null;

  const brandingRel = branding ? branding[0] : null;

  const title = brandingRel
    ? brandingRel.relationships.nodePage[0].title
    : esgMedia.name;
  const path = brandingRel ? brandingRel.relationships.nodePage[0].path : null;

  // Format used by esg media types.
  const esg = { relationships: { esgMedia } };

  // Lets us know when the icon is in the viewport.
  const [ref, inView] = useInView({
    // Number between 0 and 1 indicating the percentage that should be visible
    // before triggering. Can also be an array of numbers, to create multiple
    // trigger points.
    threshold: 1,
    triggerOnce: true,
  });

  const classes =
    count === 1
      ? ''
      : 'w-1/2 md:w-1/3 lg:w-1/6 p-2 md:p-3 xl:p-5 lg:pb-0 relative';
  const shadow = count === 1 ? 'shadow-light' : '';
  const iconProps = useSpring({ opacity: inView ? 1 : 0 });

  return (
    <article ref={ref} style={iconProps} className={`${classes}`}>
      {inView && (
        <>
          <div className={`esg-ring relative mb-6 ${shadow}`}>
            <RingSegments esgData={esg} />
          </div>
          <h5 className="font-heavy mb-0 text-center">{title || name}</h5>
          {path && path.alias && (
            <Link
              className="absolute top-0 right-0 bottom-0 left-0"
              href={path.alias}
              title={title}
              label={title}
            />
          )}
        </>
      )}
    </article>
  );
};

export default EsgMediaIconGrid;
