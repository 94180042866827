import React from 'react';
import { FlexibleComponent, FlexibleContent } from 'types';
import contentMap from './contentMap';

const ContentResolver = ({ content }: FlexibleContent) => {
  if (!content) {
    return null;
  }

  return (
    <>
      {content.map((item: FlexibleComponent) => {
        const { id, data, type, media } = item;
        const contentType = `${type}`;
        if (contentMap[`${contentType}`]) {
          const Component = contentMap[`${contentType}`];

          if (media) {
            return <Component key={id} {...data} media={media} />;
          }
          return <Component key={id} {...data} />;
        }
        return null;
      })}
    </>
  );
};

export default ContentResolver;
