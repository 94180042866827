/**
 * Some links are relative links that need to be prefixed with the Drupal url
 *
 * Use this utility for any links or menu components that might need to
 * rewrite the link prior to it being used in a <Link> component or for html
 * using the [DRUPAL_URL] placeholder.
 *
 * @param link The url or a html string
 */
const linkResolver = (link: string) => {
  // Default to production so we are never undefined
  const drupalUrl = process.env.DRUPAL_URL || 'https://www.atmosenergy.com';
  if (link === '#' || link.startsWith('http')) {
    // We shouldn't alter this link
    return link;
  }
  if (link.includes('[DRUPAL_URL]')) {
    return link.replace(/\[DRUPAL_URL]/g, drupalUrl);
  }
  if (link.includes('internal:')) {
    return link.replace(/internal:/g, drupalUrl);
  }
  return link;
};

export default linkResolver;
