/**
 * Displays on /esg/financials
 */
import React from 'react';
import { merge } from 'lodash';
import BarChart from '../Bar';
import { EsgChartConfig } from 'types';
import defaultConfig from './config';

/**
 * Configuration for the chart.
 */
const chartConfig: EsgChartConfig = {
  data: {
    labels: [
      ['1-Year', 'Total Return'],
      ['3-Year', 'Total Return'],
      ['5-Year', 'Total Return'],
    ],
    datasets: [
      {
        label: 'ATO',
        backgroundColor: '#FF8D1E',
        data: [-14.2, 21.6, 83.6],
      },
      {
        label: 'S&P 500',
        backgroundColor: '#2175D9',
        data: [15.1, 41.5, 93.7],
      },
      {
        label: 'S&P 500 Utilities',
        backgroundColor: '#80B7EC',
        data: [-5.0, 24.3, 63.5],
      },
    ],
  },
  options: {
    title: {
      display: false,
      text: 'Return to Shareholders (as of 9/30/20)',
    },
    scales: {
      y: {
        title: {
          display: true,
          text: '% Return',
        },
        ticks: {
          callback: value => `${value}%`,
        },
      },
    },
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: true,
        position: 'bottom',
      },
      tooltip: {
        callbacks: {
          label: ({ dataset: { label }, formattedValue }) =>
            ` ${label}: ${formattedValue}%`,
        },
      },
    },
  },
};

const config = merge({}, defaultConfig, chartConfig);

/**
 * Return to Shareholders
 *
 * @constructor
 */
const ShareholderReturn = () => (
  <BarChart name="ShareholderReturn" props={config} />
);

export default ShareholderReturn;
