import React, { useEffect, useState } from 'react';
import { withPrefix } from 'gatsby';
import GetEEVideos from 'utils/GetEEVideos';
import useSlottedImage from 'hooks/useSlottedImage';
import SlicePadding from 'components/Energy/Layout/SlicePadding';
import MediaCaptionVideoRemote from 'components/Esg/MediaCaption/MediaCaptionVideoRemote';
// import getSliceMedia from 'utils/getSliceMedia';

declare global {
  // Merge into HTMLElementEventMap
  interface HTMLElementEventMap {
    'modal-click-level-1': CustomEvent;
  }
}
const VideoCard = data => {
  const {
    topspacing,
    bottomspacing,
    type,
    variant,
    title,
    content: { text },
    link: {
      embed: {
        text: linkText,
        attributes: { href, target, rel, title: linkTitle },
      },
    },
    controls,
    layout,
    videoThumbnail,
    video: {
      embed: { attributes: videoAttributes },
    },
    media,
  } = data;

  let videoFile = null;

  const {
    allMediaEeVideoFile: { nodes: fileVideoData },
    allMediaEeRemoteVideo: { nodes: remoteVideoData },
  } = GetEEVideos();

  const mediaRemote =
    media && media.__typename && media.__typename === 'media__ee_remote_video';

  const mediaLocal =
    media && media.__typename && media.__typename === 'media__ee_image';

  let alternateImage = null;
  if (
    videoAttributes['data-media-bundle'] === 'ee_remote_video' ||
    mediaRemote
  ) {
    const {
      0: {
        relationships: {
          thumbnail: { localFile },
        },
      },
    } = remoteVideoData.filter(
      v => v.drupal_id === videoAttributes['data-entity-uuid'],
    );
    alternateImage = localFile;
  }
  if (videoAttributes['data-media-bundle'] === 'ee_video_file' || mediaLocal) {
    videoFile = fileVideoData
      .filter(v => v.drupal_id === videoAttributes['data-entity-uuid'])
      .shift();
  }

  // This is an exception, the video image is slotted inside another slot
  // so can't be accessed through the slotted selector.
  const videoStyles = {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  };

  // Get the image markup.
  const markup = useSlottedImage(
    videoThumbnail,
    'preview',
    'videoThumbnail',
    videoStyles,
    alternateImage,
  );

  // TODO: Replace useSlottedImage with getSliceMedia.
  // const markup2 = getSliceMedia(
  //   media,
  //   'preview',
  //   'videoThumbnail',
  //   videoStyles,
  // );

  let videoUrl = '';

  if (videoFile) {
    const {
      relationships: { field_media_video_file },
    } = videoFile;
    videoUrl = field_media_video_file.localFile.url;
  } else {
    videoUrl =
      videoAttributes['data-media-url'] ?? media.field_media_oembed_video;
  }

  const textColor = variant ? 'none' : 'reverse ';

  const [play, togglePlay] = useState(false);

  const containerId = media && media.id ? media.id : 'video-card';

  useEffect(() => {
    const videoCard = document.getElementById(containerId);
    const modalClose = (e: CustomEvent): void => {
      const {
        detail: { state },
      } = e;
      togglePlay(state);
    };

    videoCard?.addEventListener('modal-click-level-1', modalClose);
  }, [containerId]);

  return (
    <SlicePadding top={topspacing} bottom={bottomspacing}>
      <atmos-video-card
        id={containerId}
        url={withPrefix(href)}
        type={type}
        variant={textColor}
        title={title}
        text={text}
        link={linkText}
        target={target}
        rel={rel}
        link-title={linkTitle}
        controls={controls}
        layout={layout}
        video-url={withPrefix(videoUrl)}
        remote={
          videoAttributes['data-media-bundle'] === 'ee_remote_video' ||
          mediaRemote
            ? 'remote'
            : null
        }
      >
        {videoAttributes['data-media-bundle'] === 'ee_remote_video' ||
        mediaRemote ? (
          <>
            <div slot="videoCardEmbed">
              <MediaCaptionVideoRemote
                status={play}
                mediaComponent={{ videoEmbed: videoUrl }}
              />
            </div>
            {markup}
          </>
        ) : null}
      </atmos-video-card>
    </SlicePadding>
  );
};

export default VideoCard;
