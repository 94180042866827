import React, { useEffect, useState, Fragment } from 'react';
import { navigate } from 'gatsby';
import parseMenuData from 'utils/parseMenuData';
import { createLocalStorageRecentSearchesPlugin } from '@algolia/autocomplete-plugin-recent-searches';
import { InstantSearch } from 'react-instantsearch-dom';
import Autocomplete from 'components/Energy/Search/Autocomplete';
import Location from 'components/Energy/Location';
import algoliasearch from 'algoliasearch';
import { searchData } from './demoData';
import { HeaderProps } from '../../types';
import GetAlerts from 'utils/GetAlerts';
import Cookies from 'universal-cookie';

declare global {
  // Merge into HTMLElementEventMap
  interface HTMLElementEventMap {
    'menu-click': CustomEvent;
    'modal-click': CustomEvent;
  }
}

const EnergyHeader = ({ menus }: HeaderProps) => {
  enum AlertTypes {
    Header = 'Header Alert',
    Modal = 'PopUp Alert',
  }

  // Session storage for user searches.
  const recentSearchesPlugin = createLocalStorageRecentSearchesPlugin({
    key: 'recent-searches',
    limit: 3,
  });

  // Dummy data to make <atmos-header search-data> happy
  const recentSearches = searchData.recentSearches;

  const [isDesktop] = useState(() =>
    typeof window !== 'undefined'
      ? window.matchMedia('(min-width: 1024px)').matches
      : false,
  );

  const appId = process.env.GATSBY_ALGOLIA_APP_ID || '';
  const apiKey = process.env.GATSBY_ALGOLIA_PUBLIC_API_KEY || '';
  const algoliaClient = algoliasearch(appId, apiKey);
  const searchClient = {
    search(requests: any) {
      // Filter out requests with an empty query.
      const nonEmptyRequests = requests.filter(
        ({ params }) => params.query !== '',
      );

      if (nonEmptyRequests.length === 0) {
        // No valid search queries. No need to make the Algolia request.
        return Promise.resolve({
          results: [],
          query: '',
        });
      }

      // Make the Algolia request.
      return algoliaClient.search(nonEmptyRequests);
    },
  };
  const { mainMenu, serviceMenu } = menus;
  const subMenu = parseMenuData(mainMenu, true);
  const menu = parseMenuData(serviceMenu);
  const leftLink = menu.shift() || null;

  /**
   * Event handler
   *
   * @param e
   *
   * @see https://www.gatsbyjs.com/docs/how-to/previews-deploys-hosting/path-prefix/#in-app-linking
   */
  const menuClick = (e: CustomEvent): void => {
    const { detail } = e;
    const { item } = detail;
    // This is NOT a relative path
    if (!item.external) {
      // Let Gatsby handle the navigation
      navigate(item.path);
    } else {
      // Take the user to the external link
      document.location.href = item.path;
    }
  };

  /**
   * Let Gatsby take us to the homepage
   */
  const logoClick = (): void => {
    navigate('/');
  };

  const {
    allNodeAlert: { nodes: alertNodes },
  } = GetAlerts();

  useEffect(() => {
    const headerElement = document.getElementById('header');

    headerElement?.addEventListener('menu-click', menuClick);
    headerElement?.addEventListener('logo-click', logoClick);

    return () => {
      headerElement?.removeEventListener('menu-click', menuClick);
      headerElement?.removeEventListener('logo-click', logoClick);
    };
  }, []);

  const { suggestedPages } = searchData;

  const navigateTo = (query: string | undefined) => {
    if (query) {
      recentSearchesPlugin.data?.addItem({
        id: query,
        label: query,
      });

      const url = `/search?query=${query}`;
      navigate(url, { replace: true, state: { param: query } });
    }
  };

  const searchIndex = process.env.GATSBY_ALGOLIA_GENERAL_INDEX;

  const cookies = new Cookies();
  const siteWideAlert = cookies.get('siteWideAlert');
  const storeClose = () => {
    cookies.set('siteWideAlert', 'set', {
      path: '/',
      maxAge: 604800,
    });
  };

  useEffect(() => {
    const popUpAlert = document.getElementById('popup-alert');
    const modalClose = (): void => {
      storeClose();
    };
    popUpAlert?.addEventListener('modal-click', modalClose);

    return () => {
      popUpAlert?.removeEventListener('modal-click', modalClose);
    };
  }, []);

  return (
    <atmos-header
      id="header"
      left-link={JSON.stringify(leftLink)}
      links={JSON.stringify(menu)}
      sub-menu={JSON.stringify(subMenu)}
      search-data={JSON.stringify({ recentSearches, suggestedPages })}
    >
      {alertNodes &&
        alertNodes.map(({ body, field_cta, relationships, status }) => {
          if (!status) {
            return null;
          }
          const linkText = field_cta?.title || null;
          const linkUrl = field_cta?.uri || null;
          const bodyText = body?.value || null;
          const { field_alert_type } = relationships || {};
          const alertType = field_alert_type?.name || 'none';

          return (
            <Fragment key={`${alertType}-${linkText}`}>
              {status && alertType === AlertTypes.Header ? (
                <div id="alert" slot="alertHeader">
                  <div className="alert container">
                    <atmos-wysiwyg markup={bodyText} variant="no-padding" />
                    {linkUrl && (
                      <atmos-button url={linkUrl} variant="reverse">
                        {linkText}
                      </atmos-button>
                    )}
                  </div>
                </div>
              ) : null}
              <div slot="alertPopUp">
                {status && alertType === AlertTypes.Modal && !siteWideAlert ? (
                  <atmos-modal-dialog
                    id="popup-alert"
                    open="true"
                    variant="blue"
                  >
                    <div className="popup" slot="content">
                      <atmos-wysiwyg
                        markup={bodyText}
                        variant="no-padding popup-alert"
                      />
                      {linkUrl && (
                        <atmos-button url={linkUrl} variant="reverse">
                          {linkText}
                        </atmos-button>
                      )}
                    </div>
                  </atmos-modal-dialog>
                ) : null}
              </div>
            </Fragment>
          );
        })}

      <div slot="location">
        <Location />
      </div>
      <div id="search-header" className="search-header" slot="search-field">
        <InstantSearch searchClient={searchClient} indexName={searchIndex}>
          <Autocomplete
            navigateTo={navigateTo}
            section="header"
            isDesktop={isDesktop}
          />
        </InstantSearch>
      </div>
    </atmos-header>
  );
};

export default EnergyHeader;
