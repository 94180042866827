import { graphql, useStaticQuery } from 'gatsby';
import { GetMediaEeVideosQuery } from 'generated/graphqlCodegen';

const GetEEVideos = () => {
  return useStaticQuery<GetMediaEeVideosQuery>(
    graphql`
      query GetMediaEeVideos {
        allMediaEeRemoteVideo {
          nodes {
            __typename
            drupal_id
            field_media_oembed_video
            name
            relationships {
              thumbnail {
                __typename
                filename
                drupal_id
                localFile {
                  childImageSharp {
                    gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
                  }
                  publicURL
                }
                uri {
                  url
                  value
                }
              }
            }
          }
        }
        allMediaEeVideoFile {
          nodes {
            __typename
            drupal_id
            name
            thumbnail {
              alt
              height
              width
            }
            relationships {
              field_media_video_file {
                filename
                drupal_id
                localFile {
                  publicURL
                  url
                }
                uri {
                  url
                  value
                }
              }
              thumbnail {
                drupal_id
                filename
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                  publicURL
                  url
                }
                uri {
                  url
                  value
                }
              }
            }
          }
        }
      }
    `,
  );
};

export default GetEEVideos;
