/**
 * Displays on /esg/environment
 */
import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useInView } from 'react-intersection-observer';
import { BtnTableQuery } from 'generated/graphqlCodegen';
import FadeIn from '../Animations/FadeIn';

/**
 * By the Numbers : Table
 *
 * @constructor
 */
const BTNTable = () => {
  // Data: by-the-numbers-table.json
  const { data } = useStaticQuery<BtnTableQuery>(
    graphql`
      query BTNTable {
        data: allByTheNumbersTableJson {
          nodes {
            th {
              text
              label
            }
            tr {
              data
            }
          }
        }
      }
    `,
  );

  // Destructure our nodes
  const { th, tr } = data.nodes[0];

  // Lets us know when the component is in the viewport.
  const [ref, inView] = useInView({
    // Number between 0 and 1 indicating the percentage that should be visible
    // before triggering. Can also be an array of numbers, to create multiple
    // trigger points.
    threshold: 0.2,
    triggerOnce: true,
  });

  return (
    <section datatype="BTNTable" ref={ref} className="mb-24">
      {inView && (
        <FadeIn up>
          <table className="esg-matrix leading-tight">
            <thead>
              <tr>
                {th.map(({ label, text }) => {
                  return (
                    <th
                      // When using .map() you must have a unique key for each item.
                      key={`${text ? `${text}-th` : 'no-text-th'}`}
                      className="lg:first:text-left lg:first:pl-3"
                      dangerouslySetInnerHTML={{ __html: text }}
                      aria-label={label || text}
                      scope="col"
                    />
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {tr.map(row => {
                return (
                  // When using .map() you must have a unique key for each item.
                  <tr key={`${row.data[0]}-tr`}>
                    {row.data.map((value, trIndex: number) => {
                      const key = `${value}-td-${trIndex}`;
                      return (
                        <td
                          // When using .map() you must have a unique key for each item.
                          key={key}
                          className="first:font-bold lg:first:font-normal first:text-left lg:first:pl-3"
                          data-label={trIndex !== 0 ? th[trIndex].label : null}
                        >
                          {value}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </FadeIn>
      )}
    </section>
  );
};

export default BTNTable;
