import React, { useEffect, useState } from 'react';
import { isNull } from 'lodash';
import { NodesWithBrandingFragment } from 'generated/graphqlCodegen';
import ThemeHex from 'utils/ThemeHex';
import useEsgPages from 'hooks/useEsgPages';
import CircleSvg from '../Shared/Svg/CircleSvg';
import Segment, { SegmentPaths } from '../Shared/Svg/Segment';
import VivusAnimate from '../Animations/VivusAnimate';
import RingHeroHomeLogo from './RingHeroHomeLogo';

/**
 * The multi-color ring with all colored segments.
 */
const EsgRingFull = () => {
  const branding: NodesWithBrandingFragment[] = useEsgPages();
  const [ringsClasses, ringsAddClasses] = useState('opacity-0');
  const [ringsShadow, ringsAddShadow] = useState('');

  useEffect(() => {
    const timer = setTimeout(() => {
      ringsAddClasses('opacity-100 animated slower rotateInSmall');
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      ringsAddShadow('shadow-deep');
    }, 2500);
    return () => clearTimeout(timer);
  }, []);

  if (!branding) {
    return null;
  }

  // Filter ESG branding. We only want those with a segment.
  const esgBranding = branding.filter(brand => {
    const { esgMedia } = brand.relationships.custom.relationships;
    return !isNull(esgMedia.segment);
  });

  return (
    <>
      <svg
        id="esg-ring-group"
        viewBox="0 0 500 500"
        enableBackground="new 0 0 500 500"
        className={`opacity-0 absolute top-0 right-0 bottom-0 left-0 w-full h-auto transition transition-slower ${ringsClasses} ${ringsShadow}`}
      >
        <CircleSvg />
        {esgBranding.map((page: NodesWithBrandingFragment) => {
          const {
            relationships: {
              custom: {
                relationships: {
                  esgMedia: { color, segment },
                },
              },
            },
          } = page;
          const stroke = ThemeHex(color);
          if (!SegmentPaths[`${segment}`]) {
            return null;
          }
          return (
            <Segment
              key={`${color}-${segment}`}
              stroke={stroke}
              d={SegmentPaths[`${segment}`].toString()}
            />
          );
        })}
      </svg>
      <VivusAnimate id="esg-ring-group" option={{ duration: 180 }} />
      <RingHeroHomeLogo />
    </>
  );
};

export default EsgRingFull;
