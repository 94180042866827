import React, { useContext } from 'react';
import { isEmpty } from 'lodash';
import { ComponentGroupFragment, NodePage } from 'generated/graphqlCodegen';
import SEO from '../Shared/SEO';
import ComponentResolver from '../../../utils/componentResolver';
import EsgPageContext from '../Context';

type ColumnRelationships = {
  left: ComponentGroupFragment[];
  primary: ComponentGroupFragment[];
  right: ComponentGroupFragment[];
};

interface Props {
  columns: ColumnRelationships;
  node: NodePage;
}

const ThreeColumn = ({ columns, node }: Props) => {
  // Get the page context for ESG
  const { esg } = useContext(EsgPageContext);

  const { left, primary, right } = columns;
  const {
    relationships: { esgMedia },
  } = esg;
  const { color } = esgMedia;

  // Set a default so we don't error if this page has no theme.
  const theme = color || 'esg-gray-dark';

  let primaryContentCenter;
  let primaryContentBottom;

  if (!isEmpty(primary)) {
    // Split up the content based on the group type
    primaryContentCenter = primary.filter(
      value => value.groupType !== 'container-full',
    );
    primaryContentBottom = primary.filter(
      value => value.groupType === 'container-full',
    );
  }

  // Components rendered in the left sidebar
  const leftSidebarContent = left ? ComponentResolver(left, node) : null;

  // Components rendered in the content center
  const primaryComponents = primaryContentCenter
    ? ComponentResolver(primaryContentCenter, node)
    : null;

  // Components rendered in the center bottom
  const primaryComponentsBottom = primaryContentBottom
    ? ComponentResolver(primaryContentBottom, node)
    : null;

  // Components renderered in the right sidebar
  const rightSidebarContent = right ? ComponentResolver(right, node) : null;

  // Add our theme classe for the sections
  const sectionClasses = `${theme}-theme mb-24`;

  return (
    <>
      <SEO title={` | Atmos Energy`} />
      <div className="container flex flex-wrap text-esg-gray-dark leading-relaxed text-lg text-center md:text-left py-20">
        {/* Left Sidebar */}
        {leftSidebarContent && !isEmpty(leftSidebarContent.props.children) && (
          <div className="hidden lg:block md:w-3/12 xl:w-2/12 relative">
            <section className={`${sectionClasses}`}>
              <>{leftSidebarContent}</>
            </section>
          </div>
        )}
        {/* Primary Content (center) */}
        {primaryComponents && !isEmpty(primaryComponents.props.children) && (
          <div
            className={`w-full lg:w-9/12 xl:w-8/12 px-0 md:px-12 ${theme}-theme`}
          >
            <section className={`${sectionClasses}`}>
              <>{primaryComponents}</>
            </section>
          </div>
        )}
        {/* Right Sidebar */}
        {rightSidebarContent &&
          !isEmpty(rightSidebarContent.props.children) && (
            <div className="w-full lg:w-9/12 xl:w-2/12 m-auto sm:mr-0 xl:m-0 pt-12 xl:pt-0 text-center xl:text-left">
              <section className={`${sectionClasses}`}>
                <>{rightSidebarContent}</>
              </section>
            </div>
          )}
      </div>
      {primaryComponentsBottom &&
        !isEmpty(primaryComponentsBottom.props.children) && (
          <div>
            <>{primaryComponentsBottom}</>
          </div>
        )}
    </>
  );
};

export default ThreeColumn;
