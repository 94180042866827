import { useEffect, useRef } from 'react';

/**
 * React Hook
 *
 * Adds an event listener
 *
 * @param eventName The event being listened to
 * @param handler The handler responsible for updating the element
 */
export default function useEventListener(eventName, handler) {
  // Create a ref that stores the handler
  const savedHandler = useRef();

  const element = typeof window !== 'undefined' ? window : false;
  // Update ref.current value if the handler changes.
  // This allows our effect below to always get the latest handler
  // without us needing to pass it in effect deps array this can
  // cause the effect to re-run on every render.
  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    // Make sure element exists and supports addEventListener
    const isSupported = element && element.addEventListener;
    if (!isSupported) return;

    // Create event listener that calls handler function stored in ref
    const eventListener = event => savedHandler.current(event);

    // Add event listener
    element.addEventListener(eventName, eventListener);

    // Remove event listener on cleanup
    // eslint-disable-next-line consistent-return
    return () => element.removeEventListener(eventName, eventListener);
  }, [eventName, element]); // Re-run if eventName or element changes
}
