import React, { SVGAttributes } from 'react';

const CircleSvg = (
  { fill = `#FFF` }: SVGAttributes<SVGElement>,
  ignore = true,
) => (
  <path
    fill={fill}
    d="M128.221,460.926C11.731,393.668-28.181,244.712,39.075,128.221 C106.331,11.733,255.286-28.181,371.778,39.075c116.49,67.258,156.401,216.211,89.147,332.704 C393.668,488.269,244.713,528.18,128.221,460.926"
    data-ignore={ignore ? 'true' : 'false'}
  />
);

export default CircleSvg;
