import React, { Component } from 'react';
import algoliasearch from 'algoliasearch';
import {
  InstantSearch,
  connectSearchBox,
  Pagination,
  connectHits,
} from 'react-instantsearch-dom';
import { string, bool } from 'prop-types';
import { navigate } from 'gatsby';
import Autocomplete, { SearchAutocomplete } from './Autocomplete';
import HitRenderer from './HitRenderer';
import drupalLinks from './propTypes';
import './search.css';

const appId = process.env.GATSBY_ALGOLIA_APP_ID || '';
const apiKey = process.env.GATSBY_ALGOLIA_PUBLIC_API_KEY || '';
const generalIndex = process.env.GATSBY_ALGOLIA_GENERAL_INDEX;
const financialNewsIndex = process.env.GATSBY_ALGOLIA_NEWS_FINANCIALS_INDEX;
const newsIndex = process.env.GATSBY_ALGOLIA_NEWS_INDEX;
const pressReleasesIndex = process.env.GATSBY_ALGOLIA_NEWS_PRESS_RELEASES_INDEX;
const perspectivesIndex = process.env.GATSBY_ALGOLIA_NEWS_PERSPECTIVES_INDEX;

const getIndex = (redirect: string) => {
  let index = '';
  switch (redirect) {
    case 'search': {
      index = generalIndex || '';
      break;
    }
    case 'financial-news': {
      index = financialNewsIndex || '';
      break;
    }
    case 'all-news': {
      index = newsIndex || '';
      break;
    }
    case 'press-releases': {
      index = pressReleasesIndex || '';
      break;
    }
    case 'perspectives': {
      index = perspectivesIndex || '';
      break;
    }
    default: {
      index = generalIndex || '';
      break;
    }
  }
  return index;
};

const algoliaClient = algoliasearch(appId, apiKey);
const searchClient = {
  search(requests: any) {
    // Get the current path.
    const currPath =
      typeof window !== 'undefined' ? window.location.pathname : '';

    let nonEmptyRequests = requests;

    // Filter out requests with an empty query for the home and search pages.
    if (currPath === '' || currPath === '/' || currPath === '/search') {
      nonEmptyRequests = requests.filter(({ params }) => params.query !== '');
    }

    if (nonEmptyRequests.length === 0) {
      // No valid search queries. No need to make the Algolia request.
      return Promise.resolve({
        results: [],
        query: '',
      });
    }

    // Make the Algolia request.
    return algoliaClient.search(nonEmptyRequests);
  },
};
const VirtualSearchBox = connectSearchBox(() => null);

class Search extends Component<
  SearchAutocomplete,
  { query: string | undefined }
> {
  constructor(props: SearchAutocomplete) {
    super(props);
    const urlQuery = new URLSearchParams(
      typeof window !== 'undefined' ? window.location.search : '',
    ).get('query');
    this.state = {
      query: urlQuery || '',
    };
  }

  /**
   * Search input element.
   */
  SearchInput = () => {
    const { links, isDesktop, redirect } = this.props;

    return (
      <div className="mb-20 ">
        <Autocomplete
          section="block"
          navigateTo={this.navigateTo}
          links={links}
          filters={true}
          redirect={redirect}
          isDesktop={isDesktop}
          defaultRefinement={this.state.query}
        />
      </div>
    );
  };

  /**
   * Navigates to the search page with the query.
   * @param query
   */
  navigateTo = (query: string | undefined) => {
    if (query) {
      this.setState({
        query,
      });

      const { redirect } = this.props;
      const url = `/${redirect}?query=${query}`;
      navigate(url);
    }
  };

  /**
   * Input field used in the Search component.
   */
  searchPage = () => {
    const { links, isDesktop, redirect } = this.props;

    const indexName = redirect ? getIndex(redirect) : '';

    return (
      <div id="search-block" className="mb-10 search-block">
        <InstantSearch searchClient={searchClient} indexName={indexName}>
          <Autocomplete
            section="block"
            navigateTo={this.navigateTo}
            links={links}
            isDesktop={isDesktop}
            defaultRefinement={this.state.query}
          />
        </InstantSearch>
      </div>
    );
  };

  render() {
    const { query } = this.state;
    const { variant, redirect } = this.props;

    const indexName = redirect ? getIndex(redirect) : '';

    const urlQuery =
      new URLSearchParams(
        typeof window !== 'undefined' ? window.location.search : '',
      ).get('query') || undefined;

    const params = query && urlQuery !== query ? urlQuery : query;

    // React component
    const CustomSearchBox = connectSearchBox(this.SearchInput);

    const Hits = connectHits(({ hits }) => (
      <div>
        {hits.length ? (
          <>
            {hits.map(hit => {
              {
                /* TODO 😱 How do we type the "hit" prop with it using "BasicDoc"!!!!???? */
              }
              return <HitRenderer hit={hit} />;
            })}
            <Pagination
              // Optional parameters
              defaultRefinement={1}
              showFirst
              showPrevious
              showNext
              showLast
              totalPages={4}
            />
          </>
        ) : (
          <div className="no-results">
            <h4>
              We're having trouble finding a match. Please try another search.
            </h4>
            <p>
              <strong>Here are some suggestions:</strong>
            </p>
            <ul>
              <li>Check for typos.</li>
              <li>Try a different search term.</li>
              <li>
                Still can't find what you're looking for? &nbsp;
                <a href="/company/contact-us">Contact Us</a>
              </li>
            </ul>
          </div>
        )}
      </div>
    ));
    // Search results page
    if (!variant) {
      return (
        <div id="search-block" className="search-block">
          <InstantSearch searchClient={searchClient} indexName={indexName}>
            <CustomSearchBox defaultRefinement={params} />
            <VirtualSearchBox defaultRefinement={params} />
            <Hits />
          </InstantSearch>
        </div>
      );
    }
    return <this.searchPage />;
  }
}

Search.propTypes = {
  redirect: string,
  variant: bool,
  links: drupalLinks,
};

export default Search;
