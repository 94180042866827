import { withPrefix } from 'gatsby';
import React from 'react';

const IconCard = data => {
  const {
    title,
    titleAccent,
    icon,
    text,
    link: {
      embed: {
        text: linkText,
        attributes: { href, target, rel, title: linkTitle },
      },
    },
    phone,
  } = data;
  return (
    <atmos-icon-promo
      icon={icon}
      title={title}
      title-accent={titleAccent}
      text={text}
      cta-link-url={withPrefix(href)}
      cta-link-text={linkText}
      target={target}
      rel={rel}
      link-title={linkTitle}
      cta-phone={phone}
    />
  );
};

export default IconCard;
