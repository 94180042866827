/**
 * Displays on /esg/financials
 */
import React from 'react';
import { merge } from 'lodash';
import Line from '../Line';
import { EsgChartConfig } from 'types';
import defaultConfig from './config';

const chartConfig: EsgChartConfig = {
  type: 'line',
  data: {
    labels: [
      '2013',
      '2014',
      '2015',
      '2016',
      '2017',
      '2018',
      '2019',
      '2020',
      '2021E',
      '2022E',
      '2023E',
      '2024E',
      '2025E',
    ],
    datasets: [
      {
        data: [
          837, 824, 964, 1087, 1137, 1468, 1693, 1936, 2097, 2300, 2402, 2550,
          2742,
        ],
        label: 'Accelerating Investment in Infrastructure',
        borderColor: '#FF8D1E',
        pointHoverBorderColor: '#FF8D1E',
        borderWidth: 5,
        pointRadius: 3,
        pointHoverRadius: 4,
        pointHoverBorderWidth: 4,
        // Fill the grid below the line
        fill: {
          target: 'origin',
        },
      },
    ],
  },
  options: {
    title: {
      text: 'Accelerating Investment in Infrastructure',
    },
    scales: {
      y: {
        max: 3000,
        title: {
          display: true,
          text: '$ Millions',
        },
        ticks: {
          callback: value =>
            value
              .toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
              })
              .split('.')[0],
        },
      },
      x: {},
    },
    plugins: {
      tooltip: {
        enabled: false,
      },
    },
  },
};

const config = merge({}, defaultConfig, chartConfig);

/**
 * Accelerating Investment in Infrastructure
 *
 * @constructor
 */
const Aii = () => <Line name="Aii" props={config} />;

export default Aii;
