import React, { useContext } from 'react';
import GrowingLine from 'components/Esg/Animations/GrowingLine';
import Button from 'components/Esg/Shared/Button';
import EsgPageContext from 'components/Esg/Context';
import { FeatureBasicFragment } from 'generated/graphqlCodegen';

const FeatureBasicBg = (fieldComponent: FeatureBasicFragment) => {
  const { esg } = useContext(EsgPageContext);
  const { color } = esg.relationships.esgMedia;

  // Deconstruct variables.
  const { heading, body, cta } = fieldComponent;

  return (
    <div className="container text-center" datatype="FeatureBasicBg">
      {heading && heading.processed && (
        <>
          <div dangerouslySetInnerHTML={{ __html: heading.processed }} />
          <GrowingLine w="w-32" mb="mb-8" animate={false} />
        </>
      )}
      {body && body.processed && (
        <div
          dangerouslySetInnerHTML={{ __html: body.processed }}
          className="text-xl font-book leading-relaxed mx-auto max-w-2xl pb-12"
        />
      )}
      {cta && (
        <Button
          color={color}
          text={cta.title}
          link={{ href: cta.uri_alias || cta.uri, title: heading || cta.title }}
        />
      )}
    </div>
  );
};

export default FeatureBasicBg;
