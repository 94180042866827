/**
 * Displays on esg/financials
 */
import React from 'react';
import { merge } from 'lodash';
import BarChart from 'components/Esg/Charts/Bar';
import replaceValues from 'utils/replaceChartValues';
import { EsgChartConfig } from 'types';
import defaultConfig from './config';

// Order of values must match that of the chart
const replacementValues = ['$4.72', '$4.90', '$6.30 - $6.70'];

const chartConfig: EsgChartConfig = {
  data: {
    labels: ['2020', '2021E', '2025E'],
    datasets: [
      {
        // We can't do this as these values must be numeric
        // data: ['$4.35', '$4.58 - $4.73', '$5.90 - $6.30'],
        data: [4.35, 4.65, 6.1],
        backgroundColor: ['#FF8D1E', '#80B7EC', '#2175D9'],
      },
    ],
  },
  options: {
    title: {
      text: 'Earnings per Share',
    },
    scales: {
      y: {
        ticks: {
          callback: value =>
            value
              .toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
              })
              .split('.')[0],
        },
      },
      x: {},
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: ({ dataIndex }) => replaceValues(dataIndex, replacementValues),
        },
      },
      datalabels: {
        formatter: (value, context) => {
          const { dataIndex } = context;
          return replaceValues(dataIndex, replacementValues);
        },
      },
    },
  },
};

const config = merge({}, defaultConfig, chartConfig);

/**
 * Page: /esg/environment
 * @constructor
 */
const EpsBar = () => <BarChart name="EpsBar" props={config} />;

export default EpsBar;
