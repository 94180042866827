/* eslint import/no-cycle: "warn" */
import React from 'react';
import { useInView } from 'react-intersection-observer';
import displayResolver from 'utils/displayResolver';
import ComponentResolver from 'utils/componentResolver';
import mediaResolver from 'utils/mediaResolver';
import { CaptionedMediaFragment, NodePage } from 'generated/graphqlCodegen';
import FadeIn from '../Animations/FadeIn';

/**
 * Base component used for Captions.
 */
const MediaCaptionBase = (
  fieldComponent: CaptionedMediaFragment,
  node: NodePage,
) => {
  // Lets us know when the component is in the viewport.
  const [ref, inView] = useInView({
    // Number between 0 and 1 indicating the percentage that should be visible
    // before triggering. Can also be an array of numbers, to create multiple
    // trigger points.
    threshold: 0.8,
    triggerOnce: true,
  });

  const {
    id,
    layout,
    border,
    relationships,
    __typename: type,
  } = fieldComponent;

  if (!relationships) {
    return null;
  }

  // Null defaults used if this Caption doesn't use a form of media.
  let resolvedMedia;
  let display;
  let mediaImageMeta;
  let mediaOverlay;

  const { media, components } = relationships;

  if (media) {
    // For standalone images.
    if (media.__typename === 'media__image') {
      ({ mediaImageMeta, relationships } = media);
    }
    // We have a video that uses an "overlay" image that displays over the
    // corner of the video.
    else if (media.__typename === 'media__remote_video') {
      ({
        mediaImageMeta,
        relationships: { mediaImage: mediaOverlay },
      } = media);
    }
    display = displayResolver(layout);
    resolvedMedia = mediaResolver(media, type);
  } else {
    // This component is the base component and might not have "media".
    display = displayResolver(layout, true);
  }

  const borderStyles = border ? 'px-4 md:border-l border-gray-500' : 'pr-4';
  // Add padding for a left border. @todo might need this for all borders
  const paddingLeft = border === 'border-l' ? 'md:pl-10' : '';

  // Render the basic feature component.
  const resolvedComponents = ComponentResolver(components, node);

  return (
    <>
      <div
        id={id}
        ref={ref}
        className="min-h-20 py-12"
        datatype="MediaCaptionBase"
      >
        <>
          {inView && (
            <FadeIn up>
              <figure // flex flex-wrap items-center my-10 p-0 md:pl-10
                className={`flex flex-wrap items-center p-0 ${paddingLeft}`}
              >
                {resolvedMedia && (
                  <div
                    className={`${
                      display && display.leftWidth
                    } mb-6 md:mb-0 border-0 relative ${borderStyles}`}
                  >
                    {resolvedMedia}
                    {/* mediaOverlay will exist if a video is overlaying an image somewhere on the player */}
                    {mediaOverlay && (
                      <img
                        src={mediaOverlay.localFile.publicURL}
                        className="absolute top-0 right-0"
                        alt={
                          (mediaImageMeta && mediaImageMeta.alt) ||
                          'Video overlay image needs alt text'
                        }
                      />
                    )}
                  </div>
                )}
                {/* if no media: w-full px-4 border-0 md:border-l border-gray-500 */}
                <figcaption className={`${display && display.rightWidth}`}>
                  {resolvedComponents}
                </figcaption>
              </figure>
            </FadeIn>
          )}
        </>
      </div>
    </>
  );
};

export default MediaCaptionBase;
