import React, { ReactElement, useContext } from 'react';
import { pickBy } from 'lodash';
import { MediaEsgFragment } from 'generated/graphqlCodegen';
import RingSegment, { SegmentPaths } from './RingSegment';
import VivusAnimate from '../Animations/VivusAnimate';
import CircleSvg from '../Shared/Svg/CircleSvg';
import ThemeHex from '../../../utils/ThemeHex';
import EsgPageContext from '../Context';

interface Props {
  esgData: MediaEsgFragment;
  bg: boolean;
  renderIcon: boolean;
  className: string;
  attributes: {};
}

/**
 * Builds the Esg Ring with the correct segment colors.
 *
 * @param esgData
 * @param bg
 * @param renderIcon
 * @param className
 * @param attributes
 * @constructor
 */
const RingSegments = ({
  esgData,
  bg = true,
  renderIcon = true,
  className = '',
  attributes = {},
}: Props) => {
  let { esg } = useContext(EsgPageContext);

  // Allow overriding esg from context, i.e., home page.
  if (esgData) {
    esg = esgData;
  }

  const {
    relationships: {
      esgMedia: {
        id,
        color,
        segment,
        relationships: { icon },
      },
    },
  } = esg;

  const themeColor = ThemeHex(color);

  let targetPath;
  const segments: ReactElement[] = [];

  // If there is not segment it returns them all, else it will return all but
  // the segment.
  const otherSegments = pickBy(SegmentPaths, (v, k) => k !== segment);
  const arr = Object.keys(otherSegments).map(k => otherSegments[k]);
  // This will use a specific segment
  if (segment && segment !== 'all') {
    targetPath = SegmentPaths[`${segment}`].toString();
  }

  const svgId = esgData.id ? esgData.id : id;

  // const esgNodes = useQueryEsgRingFull();
  arr.forEach(s => {
    const seg = s.toString();
    segments.push(
      <RingSegment
        key={`segment-${seg}`}
        stroke="#dcdcdc"
        d={seg}
        attributes={attributes}
      />,
    );
  });

  return (
    <>
      <svg
        id={svgId}
        viewBox="0 0 500 500"
        enableBackground="new 0 0 500 500"
        className={`${className || 'relative w-full h-auto'}`}
      >
        <>
          {bg && <CircleSvg />}
          {segments}
          {targetPath && (
            <RingSegment
              // key={id}
              stroke={themeColor}
              d={targetPath}
              attributes={attributes || undefined}
            />
          )}
        </>
      </svg>
      {/* If we have an icon, animate it */}
      {renderIcon && (
        <VivusAnimate
          option={{ file: icon.localFile.publicURL }}
          id={icon.id}
        />
      )}
      {/* Animate the colored segment of the ring */}
      {targetPath && <VivusAnimate id={svgId} />}
    </>
  );
};

export default RingSegments;
