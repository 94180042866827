import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useInView } from 'react-intersection-observer';
import { BeTableQuery } from 'generated/graphqlCodegen';
import FadeIn from '../Animations/FadeIn';
import useTailwindConfig from '../../../hooks/useTailwindConfig';

library.add(faCircle);

interface BETableMembers {
  data: [];
}

/**
 * Board Experience Table
 *
 * File: data/json/BETable.json
 *
 * /esg/corporate-governance
 *
 * @constructor
 */
const BETable = () => {
  const { json } = useStaticQuery<BeTableQuery>(
    graphql`
      query BETable {
        json: allBeTableJson {
          nodes {
            th {
              text
              label
            }
            tr {
              data
            }
          }
        }
      }
    `,
  );

  // The icon used in the table.
  const IconProp = 'circle';

  // Destructure our nodes
  const { th, tr } = json.nodes[0];

  // Lets us know when the component is in the viewport.
  const [ref, inView] = useInView({
    // Number between 0 and 1 indicating the percentage that should be visible
    // before triggering. Can also be an array of numbers, to create multiple
    // trigger points.
    threshold: 0.2,
    triggerOnce: true,
  });

  const { 'esg-purple': purple } = useTailwindConfig().theme.colors;

  return (
    <div
      ref={ref}
      datatype="BETable"
      key="board-experience-table"
      className="min-h-20"
    >
      {inView && (
        <FadeIn up>
          <table className="esg-matrix board-exp leading-tight my-20">
            <caption>
              <h5 className="font-md text-center text-2xl">Board Experience</h5>
            </caption>
            <thead>
              <tr>
                {th.map(({ label, text }) => {
                  return (
                    <th
                      // When using .map() you must have a unique key for each item.
                      key={`${text ? `${text}-th` : 'no-text-th'}`}
                      className="lg:first:text-left lg:first:pl-3"
                      dangerouslySetInnerHTML={{ __html: text }}
                      aria-label={label || text}
                      scope="col"
                    />
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {tr.map(
                (
                  row: BETableMembers,
                  trIndex: number,
                  array: { length: number },
                ) => {
                  const { length } = array;
                  const lastItem = length - 1 === trIndex;
                  return (
                    <tr
                      // When using .map() you must have a unique key for each item.
                      key={`${row.data[0]}-tr`}
                      className={lastItem ? 'text-white bg-esg-purple' : ''}
                    >
                      {row.data.map((value, rowIndex: number) => {
                        const notApplicable = value === '' ? 'na' : 'valid';
                        const key = `${value}-row-${rowIndex}`;
                        return (
                          <td
                            // When using .map() you must have a unique key for each item.
                            key={key}
                            className={`first:font-bold lg:first:font-normal first:text-left lg:first:pl-3 ${notApplicable}`}
                            data-label={
                              th[rowIndex] && value !== ''
                                ? th[rowIndex].label
                                : ''
                            }
                          >
                            {value === 'icon' ? (
                              <FontAwesomeIcon
                                title="Experience"
                                icon={IconProp}
                                color={purple}
                                size="sm"
                              />
                            ) : (
                              value
                            )}
                          </td>
                        );
                      })}
                    </tr>
                  );
                },
              )}
            </tbody>
          </table>
        </FadeIn>
      )}
    </div>
  );
};

export default BETable;
