import React, { Component, SyntheticEvent } from 'react';
import { connectAutoComplete, Hits, MenuSelect } from 'react-instantsearch-dom';
import { AutocompleteProvided, Hit } from 'react-instantsearch-core';
import AutoSuggest, {
  RenderInputComponentProps,
  RenderSuggestionsContainerParams,
} from 'react-autosuggest';
import { createLocalStorageRecentSearchesPlugin } from '@algolia/autocomplete-plugin-recent-searches';
import { string, func, arrayOf, bool } from 'prop-types';
import drupalLinks, { DrupalLink } from './propTypes';
import { Link } from 'gatsby';

export interface SearchAutocomplete extends AutocompleteProvided {
  suggestion?: SearchSuggestion;
  section: string;
  links?: DrupalLink[];
  isDesktop: boolean | undefined;
  navigateTo: (query: string | undefined) => void;
  redirect?: string;
  variant?: boolean;
  filters?: boolean;
  defaultRefinement?: string;
}
export interface SearchInputElement extends HTMLInputElement {
  _valueTracker: {
    getValue(): void;
    setValue(value?: string): void;
    stopTracking(): void;
  };
}

export interface SearchRecentItems {
  label: string;
  id: string;
}

type SearchSuggestion = Hit & {
  drupal_internal__nid: number;
  field_summary:
    | {
        value: string;
      }
    | undefined;
  path: {
    alias: string;
  };
  title: string;
};
class Autocomplete extends Component<SearchAutocomplete, { value: string }> {
  static propTypes: any;

  private filterInput: any;

  private yearfilterInput: any;

  constructor(props: SearchAutocomplete) {
    super(props);
    const filter = this.getTopicFilters();
    const yearFilter = this.getYearFilters();
    this.state = {
      // Property on the AutocompleteProvided connector
      // @see interface AutocompleteProvided in react-instantsearch-core
      value: props.defaultRefinement || '',
    };

    this.yearfilterInput = yearFilter;
    this.filterInput = filter;
  }

  getTopicFilters = () => {
    const { redirect, filters } = this.props;

    const topicFilter = 'relationships.field_categories.name';
    if (redirect !== 'financial-news' && redirect !== 'search' && filters) {
      return (
        <MenuSelect
          translations={{ seeAllOption: 'Topic' }}
          attribute={topicFilter}
        />
      );
    }
    return <></>;
  };

  getYearFilters = () => {
    const { redirect, filters } = this.props;
    const yearFilter = 'created';

    if (redirect !== 'search' && filters) {
      return (
        <MenuSelect
          translations={{ seeAllOption: 'Year' }}
          attribute={yearFilter}
          transformItems={items =>
            items.sort((a, b) => {
              return b.label - a.label;
            })
          }
        />
      );
    }
    return <></>;
  };

  /**
   * Calling document.getElementById() returns the type HTMLElement which does
   * NOT contain a value property; the subtype HTMLInputElement DOES contain
   * the value property
   * @returns
   */
  getSearchInputField = () =>
    document.getElementById(
      `search-field-${this.props.section}`,
    ) as SearchInputElement;

  /**
   * Local Storage for recent searches
   */
  recentSearchesPlugin = createLocalStorageRecentSearchesPlugin({
    key: 'recent-searches',
    limit: 3,
  });

  // Retrieve searches from user.
  getRecentSearches = () => {
    const recentItems =
      this.recentSearchesPlugin.data?.getAll() as SearchRecentItems[];
    return recentItems
      ? recentItems.map(item => {
          return { label: item.label, value: item.id };
        })
      : [];
  };

  onSuggestionsFetchRequested = ({ value }: { value: string }) => {
    this.props.refine(value);
  };

  onSuggestionsClearRequested = () => {};

  getSuggestionValue = (hit: Hit) => {
    return hit.title;
  };

  // Retrieve searches from user.
  renderRecentSearches = () => {
    // const recentSearches = this.getRecentSearches();
    const recentSearches =
      this.recentSearchesPlugin.data?.getAll() as SearchRecentItems[];
    return (
      <>
        <div className="recent-searches">
          {recentSearches ? (
            <>
              <h4>RECENT SEARCHES</h4>
              <ul>
                {recentSearches.map(el => {
                  return (
                    <li key={el.id}>
                      <button
                        type="button"
                        value={el.label}
                        onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                          this.updateInputValue(e)
                        }
                      >
                        {el.label}
                      </button>
                    </li>
                  );
                })}
              </ul>
            </>
          ) : null}
        </div>
      </>
    );
  };

  // Render suggestions and recent searches box.
  onRenderSuggestions = ({
    containerProps,
    children,
  }: RenderSuggestionsContainerParams) => {
    const searches = this.renderRecentSearches();
    return (
      <div {...containerProps}>
        {children}
        {searches ? searches : null}
      </div>
    );
  };

  // Set the value on each keystroke.
  // @see https://reactjs.org/docs/events.html; documents SyntheticEvent
  onChange = (_: SyntheticEvent, { newValue }: { newValue: string }) => {
    if (newValue !== 'undefined') {
      this.setState({
        value: newValue,
      });
    }

    if (newValue === '') {
      this.props.refine();
    }
  };

  /**
   * Search when "Enter" is pressed
   * @param e
   */
  onKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      const { value } = this.state;
      this.hideRecent();
      // Autocomplete.searchNavigateTo(value);
      this.props.navigateTo(value);
      // this.clearInputField();
    }
  };

  // Submit when clicking on the search icon.
  submitSearch = () => {
    const searchInputField = this.getSearchInputField();
    if (searchInputField) {
      // Autocomplete.searchNavigateTo(searchInputField.value);
      this.props.navigateTo(searchInputField.value);
      // this.clearInputField();
    }
  };

  /**
   * Update search field with the value selected from recent searches
   *
   * @param e
   */
  updateInputValue = (e: React.MouseEvent<HTMLButtonElement>) => {
    const searchInputField = this.getSearchInputField();
    const { value } = e.currentTarget;
    searchInputField.value = value;
    const tracker = searchInputField._valueTracker;
    if (tracker) {
      // Ensure the event is dispatched by changing the last value
      const lastValue = '';
      tracker.setValue(lastValue);
    }

    searchInputField.dispatchEvent(new Event('input', { bubbles: true }));
  };

  /**
   * Add an item to the "recent-searches" local storage array
   */
  updateRecentSearches = () => {
    const query = this.props.currentRefinement;
    if (query) {
      this.recentSearchesPlugin.data?.addItem({
        id: query,
        label: query,
      });
    }
  };

  // Render the search field.
  renderInput = ({ ...rest }: RenderInputComponentProps) => {
    const { isDesktop, section, links, redirect } = this.props;
    const desktop = isDesktop ? 'desktop' : 'mobile';

    if (typeof document !== 'undefined') {
      const suggestionsContainerLink = document.querySelectorAll(
        '.react-autosuggest__suggestions-container li a',
      );
      if (suggestionsContainerLink.length > 0) {
        // Add an event listener to each link in the search dropdown
        // When a link is clicked, the input value will be added to
        // local storage like it is when the search icon is clicked or the
        // "ENTER" key is pressed
        suggestionsContainerLink.forEach(link => {
          link.addEventListener('click', this.updateRecentSearches);
        });
      }
    }

    return (
      <div className={`search-field-wrapper ${section} ${desktop} ${redirect}`}>
        <input className="input-text" {...rest} />
        {section !== 'header' ? this.filterInput : ''}
        {section !== 'header' ? this.yearfilterInput : ''}
        <button
          type="button"
          onClick={() => this.submitSearch()}
          aria-label="search"
          className={`search-button-${section}`}
        >
          <atmos-icon icon="search" svg="true" />
        </button>

        {links ? (
          <div className="links">
            {links.map((el, i) => {
              if (el.drupal_link) {
                const {
                  drupal_link: {
                    embed: {
                      text,
                      attributes: { href },
                    },
                  },
                } = el;
                return (
                  <Link key={i.toString()} className="btn" to={href}>
                    {text}
                  </Link>
                );
              }
              return null;
            })}
          </div>
        ) : null}
      </div>
    );
  };

  // Hide and show suggestions box on focus and blur.
  showRecent = () => {
    const { section } = this.props;
    const field = document.getElementById(
      `search-${section}`,
    ) as SearchInputElement;
    if (field) {
      const suggestionsContainer = field.querySelector(
        '.react-autosuggest__suggestions-container',
      ) as HTMLElement;
      if (suggestionsContainer) {
        suggestionsContainer.style.display = 'block';
      }
    }
  };

  hideRecent = () => {
    const field = document.getElementById(
      `search-${this.props.section}`,
    ) as SearchInputElement;
    if (field) {
      const suggestionsContainer = field.querySelector(
        '.react-autosuggest__suggestions-container',
      ) as HTMLElement;
      // TODO: Why not in the header?
      if (suggestionsContainer && this.props.section !== 'header') {
        suggestionsContainer.style.display = 'none';
      }
      suggestionsContainer.style.display = 'none';
    }
  };

  // Render each suggestion with this custom html.
  renderSuggestion = (suggestion: Hit<any>) => {
    const { title, path } = suggestion;
    const url = path?.alias || '#';
    const titleFixed =
      title.substring(0, 60 - 1) + (title.length > 100 ? '...' : '');
    return (
      <div>
        {url.startsWith('/document') ? (
          <a href={url} target="_blank">
            {titleFixed} - Document
          </a>
        ) : (
          <Link to={url}>{titleFixed}</Link>
        )}
      </div>
    );
  };

  /**
   * Determines the number of characters that will trigger an API call to
   * fetch search results from Algolia
   *
   * @param value
   * @returns
   */
  shouldRenderSuggestions = (value: string) => {
    return value.trim().length > 2;
  };

  render() {
    const { hits, section } = this.props;
    const { value } = this.state;

    const propInput = {
      id: `search-field-${section}`,
      className: `input-text search-field-${section}`,
      placeholder: 'Search',
      // isDesktop: isDesktop || false,
      section: section,
      onChange: this.onChange,
      // FIXME: No overload matches this call - Autosuggest.inputProps???
      onKeyDown: (e: KeyboardEvent) => this.onKeyDown(e),
      onFocus: () => this.showRecent(),
      onBlur: () => this.hideRecent(),
      value,
    };

    return (
      <AutoSuggest
        suggestions={hits.slice(0, 5)}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={this.getSuggestionValue}
        renderSuggestion={this.renderSuggestion}
        renderSuggestionsContainer={this.onRenderSuggestions}
        inputProps={propInput}
        renderInputComponent={this.renderInput}
        shouldRenderSuggestions={this.shouldRenderSuggestions}
      />
    );
  }
}

Autocomplete.propTypes = {
  suggestions: string,
  refine: func,
  currentRefinement: string,
  section: string,
  hits: arrayOf(Hits),
  links: drupalLinks,
  isDesktop: bool,
  filter: string,
  defaultRefinement: string,
};

export default connectAutoComplete(Autocomplete);
