/**
 * Displays on /esg/financials
 */
import React from 'react';
import { merge } from 'lodash';
import { EsgChartConfig } from 'types';
import Doughnut from '../Doughnut';
import defaultConfig from './config';

/**
 * Chart Configuration
 */
const chartConfig: EsgChartConfig = {
  data: {
    labels: ['Equity', 'LT Debt'],
    datasets: [
      {
        data: [60, 40],
        backgroundColor: ['#FF8D1E', '#2175D9'],
        borderColor: ['#ffffff', '#ffffff'],
        borderWidth: 5,
      },
    ],
  },
  options: {
    plugins: {
      datalabels: {
        formatter: value => `${value}%`,
      },
      doughnutLabel: {
        labels: [
          {
            text: 'Balance Sheet',
            font: {
              size: '32',
              family: 'futura_ptmedium',
            },
            color: '#66696c',
          },
        ],
      },
    },
  },
};

const config = merge({}, defaultConfig, chartConfig);

const CBSChart = () => <Doughnut name="CBSChart" props={config} />;

export default CBSChart;
