import React from 'react';
import { useInView } from 'react-intersection-observer';
import opSvg from 'data/svg/operatingPrinciples.svg';
import FadeIn from 'components/Esg/Animations/FadeIn';

const OperatingPrincipals = fieldComponent => {
  const { drupal_id } = fieldComponent;
  // Lets us know when the component is in the viewport.
  const [ref, inView] = useInView({
    // Number between 0 and 1 indicating the percentage that should be visible
    // before triggering. Can also be an array of numbers, to create multiple
    // trigger points.
    threshold: 0.2,
    triggerOnce: true,
  });

  return (
    <figure
      ref={ref}
      key={drupal_id}
      className="items-center mt-20 p-0 md:pl-10 flow-root"
    >
      {inView && (
        <FadeIn up>
          <div className="w-full px-4 mb-6 md:mb-0">
            <img
              src={opSvg}
              alt="1) Execute Exceptionally Well 2) Mitigate Risk 3) Improve Every Day 4) Adapt Quickly 5) Develop Employees, Grow Leaders and Shape Culture 6) Build Relationships and Give Back"
              className="m-auto"
            />
          </div>
        </FadeIn>
      )}
    </figure>
  );
};

export default OperatingPrincipals;
