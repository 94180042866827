import React, { ReactNode } from 'react';
import { animated, useSpring } from 'react-spring';

interface Props {
  children: ReactNode;
  up?: boolean;
}

/**
 * Fade in and possibly up
 *
 * @see https://www.react-spring.io/docs/props/spring
 *
 * @param children
 * @param up
 * @constructor
 */
const FadeIn = ({ children, up = false }: Props) => {
  const props = useSpring({
    to: { opacity: 1, transform: up ? 'translate3d(0,0,0)' : 'none' },
    from: { opacity: 0, transform: up ? 'translate3d(0, 30%, 0)' : 'none' },
    config: { duration: 1000 },
  });

  return <animated.div style={props}>{children}</animated.div>;
};

export default FadeIn;
