/* eslint-disable */
import React, { useContext, useState } from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faChevronRight,
  faChevronDown,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SlideAnimation from '../Animations/SlideAnimation';
import ComponentResolver from '../../../utils/componentResolver';
import { AccordionItemFragment, NodePage } from 'generated/graphqlCodegen';
import EsgPageContext from '../Context';

interface Props {
  item: AccordionItemFragment;
  node: NodePage;
}

// Add the icons we need to the library.
library.add(faChevronRight, faChevronDown);

/**
 * The accordion item
 *
 * @TODO: Remove eslint-disable for this file once we type 'item'.
 *
 * @param item
 * @param node
 * @constructor
 */
const AccordionItem = ({ item, node }: Props) => {
  // React hooks at the top.
  const { esg } = useContext(EsgPageContext);
  const [visible, toggle] = useState(false);

  const { id: itemId, relationships: itemRelationships } = item;
  const { media, components } = itemRelationships;
  const {
    id: mediaId,
    mediaImageMeta,
    relationships: mediaRelationships,
  } = media;
  const { alt, title } = mediaImageMeta;
  const { mediaImage } = mediaRelationships;
  const { description, body } = components[0];
  const {
    relationships: {
      esgMedia: { color },
    },
  } = esg;

  const comp = ComponentResolver(components, node);

  // Render the accordion item.
  return (
    <>
      <li
        // Reusing the same "key" from the parent but adding "li" to make it
        // unique.
        key={`${itemId}-li`}
        role="presentation"
        onClick={() => toggle(!visible)}
        className={`${visible ? 'border-b border-gray-700 open' : ''}`}
      >
        {/* title-bar */}
        <div
          className={`${
            !visible ? 'border-b border-gray-700' : ''
          } hover:text-${color} cursor-pointer py-4 relative`} //transition-all duration-300 ease
        >
          <img
            id={mediaId}
            src={mediaImage.localFile.publicURL}
            alt={alt}
            title={title}
            className="inline-block align-top mr-6 w-24 rounded-full"
          />
          {/* title-bar-text  */}
          <div
            className={`inline-block align-top pt-5 pr-12 leading-6 w-accordion-text-calc `}
            dangerouslySetInnerHTML={{ __html: description.processed }}
          />
          <FontAwesomeIcon
            className={`top-35 inline-block right-0 text-base absolute md:top-45 md:right-15`} //transition-transform duration-500 ease-linear
            icon="chevron-down"
            rotation={visible ? 180 : undefined}
          />
        </div>
        <SlideAnimation visible={visible} body={body.processed} id={itemId} />
      </li>
    </>
  );
};

export default AccordionItem;
