import { withPrefix } from 'gatsby';
import React from 'react';

interface Card {
  title: string;
  text: string;
  icon?: string;
  number: string;
  cardPadding: string;
  altText: string;
  href: string;
  cardGroupTotal: number;
}

interface Items {
  title: string;
  cards: Card[];
}

interface Link {
  embed: {
    attributes: {
      href: string;
      target: string;
      rel: string;
      title: string;
    };
    text: string;
  };
}

interface Data {
  title: string;
  titleAccent: string;
  link: Link;
  url?: string;
  items: Items[];
}

/**
 * This component could be more that just a "Season Switcher"
 *
 * @todo ...
 * We should rename this component (at the least in the EE) and allow editors
 * to use it however they want
 */
const SeasonSwitcher = (data: Data) => {
  // Do not destructure "link" so we can check for href/text
  const { title, titleAccent, link, items } = data;

  const newItems = items.map(i => {
    const item = { ...i };
    const cards = item.cards.map(c => {
      const card = { ...c };
      if (card.icon === 'number') {
        delete card.icon;
      }
      return card;
    });
    item.cards = cards;
    return item;
  });

  const {
    embed: {
      text,
      attributes: { href, target, rel, title: linkTitle },
    },
  } = link;

  return (
    <atmos-season-switcher
      title={title}
      title-accent={titleAccent}
      url={withPrefix(href)}
      link={text}
      target={target}
      rel={rel}
      link-title={linkTitle}
      items={JSON.stringify(newItems)}
    />
  );
};

export default SeasonSwitcher;
