import React from 'react';
import { useInView } from 'react-intersection-observer';
import { omit } from 'lodash';
import ComponentResolver from 'utils/componentResolver';
import mediaResolver from 'utils/mediaResolver';
import Button from 'components/Esg/Shared/Button';
import FadeIn from 'components/Esg/Animations/FadeIn';
import { NodePage, ParagraphEsgCardOverview } from 'generated/graphqlCodegen';

const EsgCardOverview = (
  { fieldComponent }: ParagraphEsgCardOverview,
  node: NodePage,
) => {
  const [ref, inView] = useInView({
    // Number between 0 and 1 indicating the percentage that should be visible
    // before triggering. Can also be an array of numbers, to create multiple
    // trigger points.
    threshold: 1,
    triggerOnce: true,
  });

  const { placement, relationships, __typename: type } = fieldComponent;
  const { components } = relationships;
  const { media, components: feature } = components[0].relationships;
  const { heading } = feature[0];

  // Remove the cta, we will render it differently.
  const justBody = omit(feature[0], 'cta');
  const { cta } = feature[0];

  // Need to pass ESG data to the ComponentResolver.
  const { color, segment, name } = media;
  const esg = { color, segment, name };

  // Resolve Components.
  const info = ComponentResolver([justBody], node, '', esg);
  // Resolve Media.
  const mediaRender = mediaResolver(media, type);

  const flexRow = placement === 'left' ? 'flew-row' : 'flex-row-reverse';
  const title = heading ? heading.processed : name;

  return (
    <div className={`flex flex-wrap ${flexRow} w-full xl:w-4/5 m-auto`}>
      <div className="w-full md:w-2/5 lg:w-2/5 xl:w-2/5">
        <>{mediaRender && <>{mediaRender}</>}</>
      </div>
      <div
        ref={ref}
        className="w-full md:w-3/5 lg:w-3/5 xl:w-3/5 pt-0 px-0 md:px-8 md:pt-6"
      >
        <h3
          dangerouslySetInnerHTML={{ __html: title }}
          className={`font-heavy text-${color}`}
        />
        <>{info && <>{info}</>}</>
        {inView && (
          <FadeIn>
            <Button
              color={color}
              text={cta.title}
              link={{ href: cta.uri, title: name }}
              size="big"
            />
          </FadeIn>
        )}
      </div>
    </div>
  );
};

export default EsgCardOverview;
