/**
 * Displays on /esg/employees
 */
import React from 'react';
import { merge } from 'lodash';
import BarChart from '../Bar';
import { EsgChartConfig } from 'types';
import defaultConfig from './config';

/**
 * Configuration for the chart.
 */
const chartConfig: EsgChartConfig = {
  data: {
    labels: ['2014', '2015', '2016', '2017', '2018', '2019', '2020'],
    datasets: [
      {
        label: 'Safety Hours',
        backgroundColor: '#FF8D1E',
        data: [45984, 51240, 51565, 52434, 53520, 91233, 82296],
      },
      {
        label: 'Technical Training Hours',
        backgroundColor: '#2175D9',
        data: [27680, 51440, 53588, 65744, 73517, 122798, 92595],
      },
      {
        label: 'Non-Technical Training Hours',
        backgroundColor: '#80B7EC',
        data: [90519, 44154, 52763, 54019, 45480, 73872, 48344],
      },
    ],
  },
  options: {
    title: {
      text: 'Training Hours',
    },
    indexAxis: 'x',
    aspectRatio: 1.6,
    responsive: true,
    scales: {
      y: {
        stacked: true,
        beginAtZero: true,
        ticks: {
          callback: value => {
            return value
              .toLocaleString('en-US', {
                style: 'decimal',
              })
              .split('.')[0];
          },
        },
        scaleLabel: {
          display: true,
          labelString: 'Hours',
        },
      },
      x: {
        stacked: true,
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: ({ formattedValue }) => formattedValue.toLocaleString('en-US'),
        },
      },
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          fontSize: 16,
          fontFamily: 'futura_ptbook',
          boxWidth: 15,
        },
      },
      datalabels: {
        display: false,
      },
    },
  },
};

const config = merge({}, defaultConfig, chartConfig);

/**
 * CKV Training Hours : Vertical Bar
 *
 * /esg/employees
 *
 * @constructor
 */
const Ckv = () => <BarChart name="Ckv" props={config} />;

export default Ckv;
