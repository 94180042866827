// Please order imports alphabetically.
import AccordionBasic from 'components/Energy/AccordionBasic/AccordionBasic';
import AccordionPerson from 'components/Energy/AccordionPerson/AccordionPerson';
import BaseHero from 'components/Energy/Hero/BaseHero';
import CirclePromo from 'components/Energy/CirclePromo';
import EmployeeSpotlight from 'components/Energy/EmployeeSpotlight';
import FeatureContent from 'components/Energy/FeatureContent';
import Feedback from 'components/Energy/Feedback';
import Footprint from 'components/Energy/Footprint';
import Heading from 'components/Energy/Heading';
import IconCard from 'components/Energy/IconCard';
import IconPromo from 'components/Energy/IconPromo';
import Image from 'components/Energy/Image';
import ImageCard from 'components/Energy/ImageCard';
import InfoCardCarousel from 'components/Energy/InfoCardCarousel';
import JumboFeature from 'components/Energy/JumboFeature';
import PullQuote from 'components/Energy/PullQuote/PullQuote';
import SeasonSwitcher from 'components/Energy/SeasonSwitcher';
import TwoColumn from 'components/Energy/Layout/TwoColumn';
import GradientBackground from 'components/Energy/Layout/GradientBackground';
import Video from 'components/Energy/Video';
import VideoCard from 'components/Energy/VideoCard';
import Wysiwyg from 'components/Energy/Wysiwyg';
import PageSwitcher from 'components/Energy/PageSwitcher';
import ContentSwitcher from 'components/Energy/ContentSwitcher';
import InfoCard from 'components/Energy/InfoCard';
import RecognizingLeak from 'components/Energy/RecognizingLeak';
import Search from 'components/Energy/Search';
import SocialCarousel from 'components/Energy/SocialCarousel';
import Stock from 'components/Energy/Stock';
import MapCode from 'components/Energy/MapCode';
import MultiColumn from 'components/Energy/MultiColumn';
import DocumentList from 'components/Energy/DocumentList';
import ReasearchCoverage from 'components/Energy/ResearchCoverage';
import RegionWrapper from 'components/Energy/Layout/RegionWrapper';

interface Content {
  [key: string]: Function;
}

// Please order components alphabetically.
const contentMap: Content = {
  accordion_basic: AccordionBasic,
  accordion_person: AccordionPerson,
  base_hero: BaseHero,
  circle_promo: CirclePromo,
  employee_spotlight: EmployeeSpotlight,
  feature_content: FeatureContent,
  feedback: Feedback,
  footprint: Footprint,
  heading: Heading,
  icon_card: IconCard,
  icon_promo: IconPromo,
  image: Image,
  image_card: ImageCard,
  info_card: InfoCard,
  info_card_carousel: InfoCardCarousel,
  jumbo_feature: JumboFeature,
  pull_quote: PullQuote,
  season_switcher: SeasonSwitcher,
  social_carousel: SocialCarousel,
  two_column: TwoColumn,
  gradient_background: GradientBackground,
  video: Video,
  video_card: VideoCard,
  wysiwyg: Wysiwyg,
  page_switcher: PageSwitcher,
  content_switcher: ContentSwitcher,
  recognizing_leak: RecognizingLeak,
  search: Search,
  map_code: MapCode,
  multi_column: MultiColumn,
  stock: Stock,
  document_list: DocumentList,
  research_coverage: ReasearchCoverage,
  region_wrapper: RegionWrapper,
};

export default contentMap;
