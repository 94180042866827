/* eslint import/no-cycle: "warn" */
import React from 'react';
import mediaResolver from 'utils/mediaResolver';
import ComponentResolver from 'utils/componentResolver';
import { MediaSingleFragment, NodePage } from 'generated/graphqlCodegen';

interface Props {
  fieldComponent: MediaSingleFragment;
  node: NodePage;
}

/**
 * Base component used to render a grid of media components.
 *
 * Determines the more specific media grid component responsible for the render.
 * Called directly from ComponentResolver.
 *
 * @param fieldComponent
 * @param node
 * @constructor
 */
const MediaSingleBase = ({ fieldComponent, node }: Props) => {
  const { id, relationships, __typename: type } = fieldComponent;
  if (!relationships) {
    return null;
  }

  // We only need a title for now but we don't want to limit this component.
  // The "components" represent a Basic Feature that can have a title + more.
  const { components, media } = relationships;

  // Get the react component for our feature.
  const feature = ComponentResolver(components, node);
  const mediaComponent = mediaResolver(media, type);

  return (
    <>
      <div id={id} className="min-h-20">
        {feature && <>{feature}</>}
        <figure>{mediaComponent && <>{mediaComponent}</>}</figure>
      </div>
    </>
  );
};

export default MediaSingleBase;
