/* eslint import/no-cycle: "warn" */
import React, { Fragment } from 'react';
import mediaResolver from 'utils/mediaResolver';
import ComponentResolver from 'utils/componentResolver';
import { MediaGridFragment, NodePage } from 'generated/graphqlCodegen';

/**
 * Renders a grid of media components.
 *
 * @param fieldComponent
 * @constructor
 */
const MediaLinkBase = (fieldComponent: MediaGridFragment, node: NodePage) => {
  const { relationships, __typename: type, id } = fieldComponent;
  if (!relationships) {
    return null;
  }

  // We only need a heading for now but we don't want to limit this component.
  // The "components" represent a Basic Feature that can have a heading+.
  const { components, mediaUnlimited } = relationships;
  // Get the react component for our feature.
  const feature = ComponentResolver(components, node);

  return (
    <>
      <div id={id} className="min-h-20" datatype="MediaLinkBase">
        {feature}
        <nav className="flex flex-wrap font-heavy text-center text-base mb-6 leading-tight">
          {mediaUnlimited.map(media => {
            const mediaComponent = mediaResolver(media, type);
            return (
              <Fragment key={media.id}>
                {mediaComponent && <>{mediaComponent}</>}
              </Fragment>
            );
          })}
        </nav>
      </div>
    </>
  );
};

export default MediaLinkBase;
