import React from 'react';
import { merge } from 'lodash';
import { EsgChartConfig } from 'types';
import Doughnut from '../Doughnut';
import defaultConfig from './config';

/**
 * Chart configuration
 */
const chartConfig: EsgChartConfig = {
  data: {
    labels: ['Cast Iron', 'Bare Steel', 'Vintage Plastics', 'Other'],
    datasets: [
      {
        data: [357, 4969, 733, 63724],
        backgroundColor: ['#2175D9', '#FF8D1E', '#80B7EC', '#00B56C'],
        borderColor: ['#ffffff', '#ffffff', '#ffffff', '#ffffff'],
        borderWidth: 5,
      },
    ],
  },
  options: {
    plugins: {
      datalabels: {
        formatter: (value, { dataset: { data } }) => {
          const sum = data.reduce((a, b) => a + b, 0);
          return `${((value * 100) / sum).toFixed(2)}%`;
        },
      },
      tooltip: {
        callbacks: {
          label(item, data) {
            const tooltip = getDoughnutTooltip(item, data);
            return `${tooltip.label}: ${tooltip.value.toLocaleString()}`;
          },
        },
      },
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          fontSize: 16,
          fontFamily: 'futura_ptbook',
          boxWidth: 15,
        },
      },
      doughnutLabel: {
        labels: [
          {
            text: 'Distribution Pipe Type',
            font: {
              size: '32',
              family: 'futura_ptmedium',
            },
            color: '#66696c',
          },
          {
            text: 'CY 2019',
            font: {
              size: '32',
              family: 'futura_ptmedium',
            },
            color: '#66696c',
          },
        ],
      },
    },
  },
  // Custom addition, not a part of ChartConfig.
  subText: '* Source: 2019 DOT reports',
};

const config = merge({}, defaultConfig, chartConfig);

/**
 * Distribution Pipe Type : 2019
 *
 * /esg/environment
 *
 * @constructor
 */
const DPT2018Chart = () => <Doughnut name="DPT2018Chart" props={config} />;

export default DPT2018Chart;
