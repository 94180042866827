/* eslint import/no-cycle: "warn" */
import React, { useContext } from 'react';
import EsgPageContext, {
  ComponentProvider,
  EsgProvider,
} from 'components/Esg/Context';

import Hero from 'components/Esg/Hero/Hero';
import RingSegments from 'components/Esg/Ring/RingSegments';
import HeroIntro from 'components/Esg/Hero/HeroIntro';
import ComponentGroup from 'components/Esg/Groups/ComponentGroup';
import LinkList from 'components/Esg/Link/LinkList';
import FeatureBasic from 'components/Esg/FeatureBasic/FeatureBasic';
import Metrics from 'components/Esg/Metrics/Metrics';
import ComponentsParallaxBg from 'components/Esg/Other/ComponentsParallaxBg';
import FeatureBasicBg from 'components/Esg/FeatureBasic/FeatureBasicBg';
import MetricsBg from 'components/Esg/Metrics/MetricsBg';
import BETable from 'components/Esg/Tables/BETable';
import BTChart from 'components/Esg/Charts/Doughnut/BTChart';
import OperatingPrincipals from 'components/Esg/Other/OperatingPrincipals';
import Osha from 'components/Esg/Charts/Bar/Osha';
import Dart from 'components/Esg/Charts/Bar/Dart';
import Rmvc from 'components/Esg/Charts/Bar/Rmvc';
import Amcb from 'components/Esg/Charts/Bar/Amcb';
import BLCTable from 'components/Esg/Tables/BLCTable';
import Ckv from 'components/Esg/Charts/Bar/Ckv';
import DistributionMRR from 'components/Esg/Charts/Bar/DistributionMRR';
import TransmissionMRR from 'components/Esg/Charts/Bar/TransmissionMRR';
import DPT2013Chart from 'components/Esg/Charts/Doughnut/DPT2013Chart';
import DPT2018Chart from 'components/Esg/Charts/Doughnut/DPT2018Chart';
import PSDChart from 'components/Esg/Charts/Doughnut/PSDChart';
import BEIChart from 'components/Esg/Charts/Doughnut/BEIChart';
import EpsBar from 'components/Esg/Charts/Bar/EpsBar';
import BTNTable from 'components/Esg/Tables/BTNTable';
import EpsLine from 'components/Esg/Charts/Line/EpsLine';
import Cydg from 'components/Esg/Charts/Bar/Cydg';
import EPSTable from 'components/Esg/Tables/EPSTable';
import ShareholderReturn from 'components/Esg/Charts/Bar/ShareholderReturn';
import Aii from 'components/Esg/Charts/Line/Aii';
import RegulatedGrowth from 'components/Esg/Charts/Bar/RegulatedGrowth';
import CBSChart from 'components/Esg/Charts/Doughnut/CBSChart';
import MetersService from 'components/Esg/Charts/Line/MetersService';
import PngAnimation from 'components/Esg/Animations/PngAnimation';
import MediaCaptionBase from 'components/Esg/MediaCaption/MediaCaptionBase';
import MediaGridBase from 'components/Esg/MediaGrid/MediaGridBase';
import Accordion from 'components/Esg/Accordion/Accordion';
import MediaLinkBase from 'components/Esg/MediaLink/MediaLinkBase';
import BTNTableFinancials from 'components/Esg/Tables/BTNTableFinancials';
import EsgIconRing from 'components/Esg/Ring/EsgIconRing';
import EsgCardOverview from 'components/Esg/Card/EsgCardOverview';
import MediaSingleBase from 'components/Esg/MediaSingle/MediaSingleBase';
import AnnualMMBtu from 'components/Esg/Charts/Bar/AnnualMMBtu';
import StockPrice from 'components/Esg/Charts/Line/StockPrice';
import SocialShare from 'components/Esg/Shared/SocialShare';
import { NodePage } from 'generated/graphqlCodegen';
// import Related from 'components/Esg/Related';

interface Components {
  [key: string]: Function;
}

/**
 * After creating your component, and adding it here, make sure you
 * create a fragment for it and add that fragment to the `ComponentGroup`
 * fragment.
 */
export const components: Components = {
  // Complete
  paragraph__accordion: Accordion,
  paragraph__component_group: ComponentGroup,
  paragraph__hero_full_image: Hero,
  paragraph__hero_intro: HeroIntro,
  paragraph__segmented_ring: RingSegments,
  paragraph__feature_basic: FeatureBasic,
  paragraph__feature_components_bg: ComponentsParallaxBg,
  paragraph__captioned_media: MediaCaptionBase,
  paragraph__media_links: MediaLinkBase,
  paragraph__media_grid: MediaGridBase,
  paragraph__media_single: MediaSingleBase,
  paragraph__metrics: Metrics,
  paragraph__link_list: LinkList,
  paragraph__esg_icon_ring: EsgIconRing,
  paragraph__esg_card_overview: EsgCardOverview,
  paragraph__png_animation: PngAnimation,

  // Extended components
  FeatureBasic_ComponentsParallaxBg: FeatureBasicBg,
  Metrics_ComponentsParallaxBg: MetricsBg,

  // Custom components that are not fielded in Drupal.
  // The keys defined here must match those defined in the Drupal function
  // atmos_config_allowed_values_field_resolver_key() located in the
  // atmos_config module.
  boardExperienceTable: BETable,
  boardTenureChart: BTChart,
  boardLeadershipCommitteesTable: BLCTable,
  operatingPrinciplesSVG: OperatingPrincipals,
  oshaBarChart: Osha,
  dartBarChart: Dart,
  rmvcBarChart: Rmvc,
  amcbBarChart: Amcb,
  ckvBarChart: Ckv,
  distributionMRRBarChart: DistributionMRR,
  transmissionMRRBarChart: TransmissionMRR,
  dpt2013PieChart: DPT2013Chart,
  dpt2018PieChart: DPT2018Chart,
  psdPieChart: PSDChart,
  beiPieChart: BEIChart,
  epsBarChart: EpsBar,
  btnTable: BTNTable,
  btnTableFinancials: BTNTableFinancials,
  epsLineChart: EpsLine,
  cydgBarChart: Cydg,
  epsTable: EPSTable,
  spLineChart: StockPrice,
  shrBarChart: ShareholderReturn,
  aiiLineChart: Aii,
  rgBarChart: RegulatedGrowth,
  cbsPieChart: CBSChart,
  msLineChart: MetersService,
  annualMMBtuChart: AnnualMMBtu,
  socialShare: SocialShare,

  // Needs work
  // paragraph__ref_images: Related,
};

/**
 * Resolves paragraphs, media, etc to their corresponding Components.
 *
 * @param fieldComponents
 * @param node
 * @param customResolver
 * @param esgData
 */
const ComponentResolver = (
  fieldComponents: any[],
  node: NodePage,
  customResolver?: string,
  esgData?: object,
) => {
  // Use esg if it's defined else get it from context.
  const esgContext = useContext(EsgPageContext);
  const esg = esgData || esgContext;

  if (!fieldComponents) {
    return null;
  }

  return (
    <>
      {/* eslint-disable-next-line react/destructuring-assignment */}
      {fieldComponents.map(fieldComponent => {
        let paragraphType;
        const { __typename, id, key } = fieldComponent;
        if (__typename === 'paragraph__gatsby_component') {
          paragraphType = `${key}`;
        } else {
          paragraphType = `${__typename}`;
        }

        if (components[`${paragraphType}`]) {
          // Find the dynamic Component that will be used to render content.
          const Paragraph = components[`${paragraphType}`];

          return (
            <ComponentProvider
              key={id}
              value={
                customResolver ? { customResolver } : { customResolver: [] }
              }
            >
              <EsgProvider
                value={esg ? { esgContext: esg } : { esgContext: {} }}
              >
                <Paragraph
                  key={id}
                  fieldComponent={fieldComponent}
                  node={node}
                />
              </EsgProvider>
            </ComponentProvider>
          );
        }
        // Debug: Should see this on the Preview server prior to publishing
        // content. Let's us know if there's a component that should render.
        // return <h1 style={{ height: '200px' }}>{paragraphType}</h1>;
        return null;
      })}
    </>
  );
};

export default ComponentResolver;
