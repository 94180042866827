import React, {
  useContext,
  useState,
  useEffect,
  ReactNode,
  FormEvent,
} from 'react';
import { createPortal } from 'react-dom';
import { regionContext } from 'context/Provider';
import getRegionByZip from 'utils/getRegionByZip';
import { useSiteMessages } from 'utils/useSiteMessages';
import './location.css';

const Portal = ({ children }: { children: ReactNode }) => {
  const [el, setEl] = useState<HTMLElement | null>();

  useEffect(() => {
    if (document) {
      setEl(document.getElementById('portal'));
    }
  }, []);

  return el ? createPortal(children, el) : null;
};

const Location = () => {
  const [isEdit, setIsEdit] = useState(false);
  const { region, updateRegion } = useContext(regionContext);
  const [notify, updateNotify] = useState('');
  // These messages come from Drupal.
  const { field_zip_code_message } = useSiteMessages();

  const zipCodeMsg: string =
    typeof field_zip_code_message === 'string' &&
    field_zip_code_message.trim() !== ''
      ? field_zip_code_message
      : 'Please try with a different zip code.';

  const inputs = [
    { name: 'input-1' },
    { name: 'input-2' },
    { name: 'input-3' },
    { name: 'input-4' },
    { name: 'input-5' },
  ];

  const LocationField = () => (
    <div className="location-wrapper">
      <button
        className="choose-location"
        onClick={() => setIsEdit(true)}
        type="button"
      >
        <span>{`${
          region && region !== 'N/A' ? region : 'Select Location'
        }`}</span>
        <div className="icon">
          <atmos-icon icon="pin" svg="true" />
        </div>
      </button>
    </div>
  );

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    const zipCodeEl = document.querySelector('atmos-zip-code-collection');
    const { zipCode } = zipCodeEl;
    updateRegion(getRegionByZip(zipCode));

    if (getRegionByZip(zipCode) === 'N/A') {
      updateNotify(zipCodeMsg);
    } else {
      updateNotify('');
      setIsEdit(false);
    }
  };

  return (
    <>
      <LocationField />
      <Portal>
        {isEdit ? (
          <div className="zipcode-form">
            <button
              className="zipcode-close"
              onClick={() => setIsEdit(false)}
              type="button"
            >
              <atmos-icon icon="close" svg="true" />
            </button>
            <p className="zipcode-form-text">
              Enter your zip code to see
              <span> information </span>
              in your area
            </p>
            <form
              className="pt-spacing-18 flex flex-col items-center"
              onSubmit={onSubmit}
            >
              <atmos-zip-code-collection
                size="1"
                className="flex flex-col w-56"
                name="zipCodeInput"
                id="zipCodeInput"
                inputs={JSON.stringify([...inputs])}
                label="Enter your zip code"
                errorMessage="Enter a valid zip code"
              />
              <p>{notify}</p>
              <input
                className="zipcode-form-button w-full bottom-0 xl:-bottom-16 p-2.5 xl:p-5 text-neutral-white bg-brand-primary hover:bg-brand-primaryHover hover:cursor-pointer font-body"
                type="submit"
                value="Go"
              />
            </form>
          </div>
        ) : null}
      </Portal>
    </>
  );
};

export default Location;
