export const subMenu = [
  {
    label: 'Our Company',
    path: '#',
    external: false,
    children: [
      {
        title: null,
        items: [
          {
            label: 'About',
            path: '#',
            external: false,
          },
          {
            label: 'Careers',
            path: '#',
            external: false,
          },
          {
            label: 'News',
            path: '#',
            external: false,
          },
          {
            label: 'Filings, Rates, & Tariffs',
            path: '#',
            external: false,
            children: [
              {
                label: 'View all rates & tariffs',
                path: '#',
                external: false,
              },
            ],
          },
          {
            label: 'Atmos Pipeline - Texas',
            path: '#',
            external: false,
          },
        ],
      },
      {
        title: 'For Investors',
        items: [
          {
            label: 'Why Choose Natural Gas',
            path: '#',
            external: false,
          },
          {
            label: 'Learn About Natural Gas',
            path: '#',
            external: false,
          },
        ],
      },
      {
        title: 'Sustainability',
        items: [
          {
            label: 'Financial News',
            path: '#',
            external: false,
          },
          {
            label: 'Publications & SEC Filings',
            path: '#',
            external: false,
          },
          {
            label: 'Stock Information',
            path: '#',
            external: false,
          },
          {
            label: 'Webcasts & Presentations',
            path: '#',
            external: false,
          },
        ],
      },
    ],
  },
  {
    label: 'Our Customers',
    path: '#',
    external: false,
    children: [
      {
        title: null,
        items: [
          {
            label: 'Start/Stop/Transfer Service',
            path: '#',
            external: false,
          },
          {
            label: 'Manage Energy Consumption',
            path: '#',
            external: false,
          },
          {
            label: 'Utility Scams',
            path: '#',
            external: false,
          },
        ],
      },
      {
        title: 'Billing and Payment',
        items: [
          {
            label: 'Bill Inserts',
            path: '#',
            external: false,
          },
          {
            label: 'Get Help Paying Your Bill',
            path: '#',
            external: false,
          },
          {
            label: 'Understanding Your Bill',
            path: '#',
            external: false,
          },
          {
            label: 'Wireless Meter Reading',
            path: '#',
            external: false,
          },
        ],
      },
      {
        title: 'Ways to Save',
        items: [
          {
            label: 'Low Income Programs',
            path: '#',
            external: false,
          },
          {
            label: 'Appliance Rebates',
            path: '#',
            external: false,
          },
          {
            label: 'Find a Gas Dealer',
            path: '#',
            external: false,
          },
          {
            label: 'Business Savings',
            path: '#',
            external: false,
          },
        ],
      },
    ],
  },
  {
    label: 'Safety',
    path: '#',
    external: false,
    children: [
      {
        title: null,
        items: [
          {
            label: 'Suspect a Leak',
            path: '#',
            external: false,
          },
          {
            label: 'Carbon Monoxide Safety',
            path: '#',
            external: false,
          },
          {
            label: 'Recognizing a Leak',
            path: '#',
            external: false,
          },
          {
            label: 'Call 811 Before You Dig',
            path: '#',
            external: false,
          },
          {
            label: 'Right-of-Way',
            path: '#',
            external: false,
          },
        ],
      },
      {
        title: null,
        items: [
          {
            label: 'Pipeline Projects',
            path: '#',
            external: false,
            children: [
              {
                label: 'Pipeline Replacement Process',
                path: '#',
                external: false,
              },
              {
                label: 'View all projects',
                path: '#',
                external: false,
              },
            ],
          },
          {
            label: 'Carbon Monoxide Safety',
            path: '#',
            external: false,
          },
          {
            label: 'Weather Safety',
            path: '#',
            external: false,
          },
        ],
      },
      {
        link: 'View all <br /> safety information',
        url: '#',
        title: 'Safety for',
        items: [
          {
            label: 'Homeowners',
            path: '#',
            external: false,
          },
          {
            label: 'Commercial Managers',
            path: '#',
            external: false,
          },
          {
            label: 'Builder/Developers',
            path: '#',
            external: false,
          },
          {
            label: 'First Responders',
            path: '#',
            external: false,
          },
        ],
        altern: true,
      },
    ],
  },
];

export const primaryLink = [
  {
    label: 'Pay Bill',
    path: '#',
    external: false,
  },
  {
    label: 'Manage Account',
    path: '#',
    external: false,
  },
  {
    label: 'Contact Us',
    path: '#',
    external: false,
  },
];

export const searchData = {
  quickLinks: [
    {
      label: 'Manage Account',
      value: 'Manage Account',
    },
    {
      label: 'Pay Bill',
      value: 'Pay Bill',
    },
    {
      label: 'Help Paying Bill',
      value: 'Help Paying Bill',
    },
    {
      label: 'Start/Transfer Service',
      value: 'Start/Transfer Service',
    },
    {
      label: 'Stop Service',
      value: 'Stop Service',
    },
    {
      label: 'Contact Us',
      value: 'Contact Us',
    },
  ],
  recentSearches: [
    {
      label: 'Rebates',
      value: 'Rebates',
    },
    {
      label: 'Replacing gas lines mid-Tex',
      value: 'Replacing gas lines mid-Tex',
    },
  ],
  suggestedPages: [
    {
      label: 'Saving money on your gas bill',
      path: '#',
      external: false,
    },
    {
      label: 'Service map',
      path: '#',
      external: false,
    },
    {
      label: 'How Atmos prioritizes your safety',
      path: '#',
      external: false,
    },
  ],
};
