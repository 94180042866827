import useSlottedImage from 'hooks/useSlottedImage';
import React from 'react';
import LocationHero from 'components/Energy/LocationHero';
import { withPrefix } from 'gatsby';

const BaseHero = data => {
  const {
    location,
    title,
    titleAccent,
    subheading,
    content,
    drupal_link: {
      embed: {
        text: link,
        attributes: { href, target, rel, title: linkTitle },
      },
    },
    image,
    cardTitle,
    cardText,
    cardPhone,
    cardUrl,
  } = data;

  const text = (content && content.text) || '';

  const {
    embed: { attributes },
  } = image;

  // This is an exception, the video image is slotted inside another slot
  // so can't be accessed through the slotted selector.
  const imageStyles = {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  };

  // Get the image markup.
  const markup = useSlottedImage(image, '', 'image', imageStyles);
  const imageUrl = attributes['data-entity-uuid'] !== undefined;

  let infoCard =
    cardTitle || cardText || cardPhone
      ? {
          title: cardTitle,
          text: cardText,
          phone: cardPhone,
        }
      : {};

  if (cardUrl && cardUrl.embed) {
    const {
      embed: {
        text: infoCardlink,
        attributes: {
          href: infoCardUrl,
          rel: infoCardRel,
          target: infoCardTarget,
          title: infoCardLinkTitle,
        },
      },
    } = cardUrl;

    if (infoCardUrl && infoCardlink) {
      infoCard.url = infoCardUrl;
      infoCard.link = infoCardlink;
      infoCard.target = infoCardTarget;
      infoCard.rel = infoCardRel ?? '';
      infoCard.linkTitle = infoCardLinkTitle ?? '';
    }
  }

  const opts = {};
  if (imageUrl) {
    opts['image-url'] = true;
  }

  if (location) {
    infoCard = {};
  }

  if (infoCard) {
    opts['info-card'] = JSON.stringify(infoCard);
  }

  return (
    <atmos-hero
      title={title}
      title-accent={titleAccent}
      subheading={subheading}
      text={text}
      link={link}
      url={withPrefix(href)}
      target={target}
      rel={rel}
      link-title={linkTitle}
      {...opts}
      hero-variant={location ? 'location-selector' : 'none'}
    >
      {markup}
      {location ? <LocationHero /> : null}
    </atmos-hero>
  );
};

export default BaseHero;
