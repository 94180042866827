import { graphql, useStaticQuery } from 'gatsby';
import { GetMediaEeImagesQuery } from 'generated/graphqlCodegen';

// 315 images as of 2022-04-30 : warning This query took more than 15s to run
const GetEEImages = () => {
  return useStaticQuery<GetMediaEeImagesQuery>(
    graphql`
      query GetMediaEeImages {
        allMediaEeImage {
          nodes {
            drupal_id
            relationships {
              field_media_image {
                localFile {
                  publicURL
                  childImageSharp {
                    gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
                  }
                }
                filename
              }
            }
            field_media_image {
              alt
              height
              title
              width
            }
          }
          totalCount
        }
      }
    `,
  );
};

export default GetEEImages;
