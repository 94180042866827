import { withPrefix } from 'gatsby';
import React from 'react';
// import { BodyComponent } from 'types';

const FeatureContent = data => {
  const {
    'button-size': buttonSize,
    text,
    title,
    link: {
      embed: {
        text: link,
        attributes: { href: url, target, rel, title: linkTitle },
      },
    },
    variant,
    type,
  } = data;

  const textColor = variant ? 'none' : 'reverse ';

  return (
    <atmos-feature-content
      class="content"
      url={withPrefix(url) || ''}
      variant={textColor}
      title={title}
      text={text}
      link={link || ''}
      target={target}
      rel={rel}
      link-title={linkTitle}
      button-size={buttonSize}
      type={type}
    />
  );
};

export default FeatureContent;
