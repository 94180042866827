/**
 * Displays on esg/environment
 */
import React from 'react';
import { merge } from 'lodash';
import BarChart from '../Bar';
import { EsgChartConfig } from 'types';
import defaultConfig from './config';

/**
 * Configuration for the chart.
 */
const chartConfig: EsgChartConfig = {
  data: {
    labels: ['2015', '2016', '2017', '2018', '2019'],
    datasets: [
      {
        data: [105, 103, 144, 155, 120],
        backgroundColor: '#00B56C',
      },
    ],
  },
  options: {
    title: {
      text: 'Transmission Miles Replacement Rate',
    },
    scales: {
      y: {
        title: {
          display: true,
          text: 'Miles',
        },
      },
    },
  },
};

const config = merge({}, defaultConfig, chartConfig);

/**
 * Transmission Miles Replacement Rate
 *
 * /esg/environment
 *
 * @constructor
 */
const TransmissionMRR = () => (
  <BarChart name="TransmissionMRR" props={config} />
);

export default TransmissionMRR;
