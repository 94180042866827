import React from 'react';
import EsgMediaIconGrid from 'components/Esg/MediaGrid/MediaGridIcon';
import { EsgIconRingFragment } from 'generated/graphqlCodegen';

/**
 * The ESG icon ring.
 *
 * @param fieldComponent
 * @param node
 * @constructor
 */
const EsgIconRing = ({ fieldComponent }: EsgIconRingFragment) => {
  const { relationships } = fieldComponent;

  if (!relationships) {
    return null;
  }

  const {
    relationships: { components },
  } = fieldComponent;
  const { mediaUnlimited } = components[0].relationships;
  const count = mediaUnlimited.length;

  return (
    <div
      className="relative mx-auto max-w-6xl flex flex-wrap justify-center text-esg-gray-dark"
      id="esg-ring-sequence"
    >
      {mediaUnlimited.map(media => {
        return (
          <EsgMediaIconGrid key={media.id} esgMedia={media} count={count} />
        );
      })}
    </div>
  );
};

export default EsgIconRing;
