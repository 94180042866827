import React, { CSSProperties } from 'react';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';

interface MediaMarkup {
  media: {
    field_media_image: {
      alt: string;
      title: string;
    };
    relationships: {
      field_media_image: {
        localFile: object;
      };
    };
  };
  imageClass?: string;
  slot?: string;
  styles?: CSSProperties;
}

const getImageMarkup = (
  media: MediaMarkup['media'],
  imageClass?: MediaMarkup['imageClass'],
  slot?: MediaMarkup['slot'],
  styles?: MediaMarkup['styles'],
) => {
  if (!media) {
    return <h1>No Media -- why?</h1>;
  }

  /**
   * TODO: This helper needs to be converted to "images" only and a clone of this
   * created to deal with other media fields for videos, documents, ...
   */
  const {
    field_media_image: imageMeta,
    relationships: {
      field_media_image: { localFile },
    },
  } = media;

  const image = getImage(localFile);

  return image ? (
    <div slot={slot}>
      <GatsbyImage
        className={imageClass}
        image={image}
        imgStyle={{ maxWidth: '100%', width: '100%' }}
        alt={imageMeta.alt || ''}
        title={imageMeta.title || ''}
        style={styles}
      />
    </div>
  ) : null;
};

const getDocumentAlias = () => {};

export { getImageMarkup, getDocumentAlias };
