import React from 'react';
import ReactPlayer from 'react-player';
import { MediaRemoteVideoFragment } from 'generated/graphqlCodegen';

interface Props {
  status: string;
  mediaComponent: MediaRemoteVideoFragment;
}

/**
 * Renders a remote video url using React Player.
 *
 * @param mediaComponent
 * @constructor
 */
const MediaCaptionVideoRemote = ({
  mediaComponent,
  status = 'false',
}: Props) => {
  const { videoEmbed } = mediaComponent;
  if (!videoEmbed) {
    return null;
  }
  console.log(status);
  return (
    <>
      <div className="video-wrap relative">
        <ReactPlayer
          key={status.toString()}
          width="100%"
          height="100%"
          className="w-full h-full absolute top-0 left-0"
          url={videoEmbed}
          playing={status == 'true'}
          ready="true"
        />
      </div>
    </>
  );
};

export default MediaCaptionVideoRemote;
