/**
 * Displays on /esg/financials
 */
import React from 'react';
import { merge } from 'lodash';
import Line from '../Line';
import { EsgChartConfig } from 'types';
import defaultConfig from './config';

const chartConfig: EsgChartConfig = {
  data: {
    labels: [
      '2008',
      '2009',
      '2010',
      '2011',
      '2012',
      '2013',
      '2014',
      '2015',
      '2016',
      '2017',
      '2018',
      '2019',
      '2020',
    ],
    datasets: [
      {
        data: [
          3191779, 3178844, 3186040, 3213191, 3116589, 3011980, 3115419,
          3151666, 3185865, 3221405, 3256336, 3291835, 3333181,
        ],
        label: 'Meters in Service at Year-End',
        borderColor: '#FF8D1E',
        pointHoverBorderColor: '#FF8D1E',
        borderWidth: 5,
        pointRadius: 3,
        pointHoverRadius: 4,
        pointHoverBorderWidth: 4,
        // Fill the grid below the line
        fill: {
          target: 'origin',
        },
      },
    ],
  },
  options: {
    title: {
      text: 'Meters in Service at Year-End',
    },
    scales: {
      y: {
        ticks: {
          callback: value =>
            value.toLocaleString('en-US', {
              style: 'decimal',
            }),
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: ({ formattedValue }) => formattedValue,
        },
      },
    },
  },
};

const config = merge({}, defaultConfig, chartConfig);

/**
 * Stock Price Line Chart
 * @constructor
 */
const MetersService = () => <Line name="MetersService" props={config} />;

export default MetersService;
