import React from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  fab,
  faFacebookF,
  faTwitter,
  faLinkedinIn,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import { graphql, useStaticQuery } from 'gatsby';
import { SocialIconsTopQuery } from 'generated/graphqlCodegen';
import { FooterMenuItem } from 'types';

// Add the icons we need to the library.
library.add(fab, faFacebookF, faTwitter, faLinkedinIn, faYoutube);

interface popUpProps {
  path?: string;
  target?: string;
}

const SocialShare = () => {
  const data = useStaticQuery<SocialIconsTopQuery>(
    graphql`
      query SocialIconsTop {
        socialIconsTopJson {
          items {
            icon
            path
            target
          }
        }
      }
    `,
  );

  const openPopup = ({ path, target }: popUpProps) => {
    if (target === 'popup') {
      window.open(
        path,
        'popup',
        'width=600,height=600,scrollbars=no,resizable=no',
      );
    }
  };

  const currUrl = typeof window !== 'undefined' ? window.location.href : '';

  return (
    <ul className="esg-share-social flex">
      <li className="text-lg font-heavy pr-6 inline-block">Share</li>
      {data.socialIconsTopJson.items.map(
        ({ path, icon, target }: FooterMenuItem) => (
          <li key={`${icon}-list`} className="text-lg pr-4 inline-block">
            <a
              className="text-white no-underline"
              href={target === 'popup' ? path + currUrl : path}
              target={target}
              onClick={() => openPopup({ path, target })}
            >
              <FontAwesomeIcon icon={['fab', icon]} color="#C1C6C8" size="1x" />
            </a>
          </li>
        ),
      )}
    </ul>
  );
};

export default SocialShare;
