/**
 * Interface for EE Slices that contain media
 *
 * The value represents the property name in the JSON that contains the media
 * @see MediaReferenceFieldItemList.php in Drupal
 */
export interface Slices {
  [key: string]: string[];
}

/**
 * If a slice type, such as video, has more that one media then comma
 * separate the property keys that might contain media.
 *
 * This should mirror $mediaSlices in:
 * atmos-odyssey/project/modules/atmos_enhanced_editor/src/Plugin/Field/FieldType/MediaReferenceFieldItemList.php
 *
 * @see MediaReferenceFieldItemList.php in Drupal
 */
export const mediaSlices: Slices = {
  base_hero: ['image', 'drupal_link'],
  circle_promo: ['image', 'link'],
  document_list: ['document'],
  employee_spotlight: ['media', 'link'],
  footprint: ['map'],
  image: ['image'],
  image_card: ['image', 'link'],
  info_card: ['infoCardImage', 'link'],
  jumbo_feature: ['image', 'link'],
  video: ['video', 'videoThumbnail'],
  video_card: ['video', 'videoThumbnail', 'link'],
};
