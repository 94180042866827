import React, { useState, useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Swiper, { Navigation, Pagination } from 'swiper';
import { Card } from 'types';
import './socialCarousel.css';

const SocialCarousel = data => {
  const socialPostQuery = useStaticQuery(
    graphql`
      query SocialPosts {
        # TODO: Uncomment when we uncomment (enable) the gatsby-source-facebook-graphql plugin
        # allFacebook(limit: 10) {
        #   edges {
        #     node {
        #       id
        #       name
        #       posts {
        #         data {
        #           created_time(difference: "")
        #           id
        #           message
        #         }
        #       }
        #     }
        #   }
        # }
        # allTwitterStatusesUserTimelineAtmos(limit: 10) {
        #   nodes {
        #     created_at
        #     id
        #     entities {
        #       media {
        #         media_url_https
        #         type
        #         display_url
        #       }
        #       hashtags {
        #         text
        #       }
        #     }
        #     full_text
        #   }
        # }
        allInstagramContent(limit: 10) {
          edges {
            node {
              id
              caption
              media_url
              media_type
              permalink
              timestamp
            }
          }
        }
      }
    `,
  );

  interface socialLink {
    text: string;
  }
  interface facebookPost {
    created_time: string;
    id: string;
    message: string;
  }
  const { title, socialLinks } = data;
  const socialLinksArray = socialLinks.map((link: socialLink) => link.text);

  const [collatedPosts, setCollatedPosts] = useState([]);

  const removeUndefinedPosts = (arr: Array<any>) => {
    return arr.filter(post => post !== undefined);
  };

  const collatePosts = (...allSocialPosts: Array<any>) => {
    const allPosts = [];
    for (let i = 0; i < allSocialPosts.length; i += 1) {
      allPosts.push(...allSocialPosts[i]);
    }
    const postsSortedByMostRecent = allPosts.sort((a, b) => {
      const dateA = new Date(a.timestamp);
      const dateB = new Date(b.timestamp);
      return dateB.valueOf() - dateA.valueOf();
    });
    setCollatedPosts(postsSortedByMostRecent);
  };

  useEffect(() => {
    // TODO: TS does not like this but it works; declare this as a module
    import('../../../../node_modules/swiper/swiper-bundle.js');
    const socialCard = {
      id: '',
      text: '',
      sampleImage: '',
      socialPostLink: '',
      timestamp: '',
      socialIcon: '',
      variant: '',
    };
    const storedFacebookPosts = sessionStorage.getItem('facebookPosts');
    let twitterContent: Array<any> = [];
    let instagramContent: Array<any> = [];
    let facebookContent: Array<any> = [];

    if (socialPostQuery.allTwitterStatusesUserTimelineAtmos) {
      twitterContent =
        socialPostQuery.allTwitterStatusesUserTimelineAtmos.nodes;
    }

    if (socialPostQuery.allInstagramContent) {
      instagramContent = socialPostQuery.allInstagramContent.edges;
    }
    if (socialPostQuery.allFacebook) {
      // trim post number
      if (socialPostQuery.allFacebook.edges[0].node.posts.data.length > 10) {
        facebookContent =
          socialPostQuery.allFacebook.edges[0].node.posts.data.slice(0, 5);
      } else {
        facebookContent = socialPostQuery.allFacebook.edges[0].node.posts.data;
      }
    }

    // Reformat api data to consistent data structure across all socials
    const formatFacebookPosts = (facebookPosts: Array<Object>) => {
      const sanitizedPosts = removeUndefinedPosts(facebookPosts);
      if (sanitizedPosts.length > 0) {
        const filteredFbPosts = sanitizedPosts.filter(
          facebookPost => facebookPost.full_picture && facebookPost.message,
        );
        const formattedFbPosts = filteredFbPosts.map(facebookPost => {
          const obj = {
            ...socialCard,
          };
          obj.id = facebookPost.id;
          obj.text = facebookPost.message;
          obj.sampleImage = facebookPost.full_picture;
          obj.socialPostLink = facebookPost.permalink_url;
          obj.timestamp = facebookPost.created_time;
          obj.socialIcon = 'facebook';
          obj.variant = 'social';
          return obj;
        });
        return formattedFbPosts;
      }
      return [];
    };

    const formatTwitterPosts = (twitterPosts: Array<Object>) => {
      const sanitizedPosts = removeUndefinedPosts(twitterPosts);
      if (sanitizedPosts.length > 0) {
        const filteredTwitterPosts = sanitizedPosts.filter(
          twitterPost => twitterPost.full_text && twitterPost.entities.media,
        );
        const formattedTwitterPosts = filteredTwitterPosts.map(twitterPost => {
          // This URL doesn't have a protocol
          let displayUrl = twitterPost.entities.media[0].display_url;
          if (!displayUrl.startsWith('https')) {
            displayUrl = `https://${displayUrl}`;
          }
          const obj = {
            ...socialCard,
          };
          obj.id = twitterPost.id;
          obj.text = twitterPost.full_text;
          obj.sampleImage = twitterPost.entities.media[0].media_url_https;
          obj.socialPostLink = displayUrl;
          obj.timestamp = twitterPost.created_at;
          obj.socialIcon = 'twitter';
          obj.variant = 'social';
          return obj;
        });
        return formattedTwitterPosts;
      }
      return [];
    };

    const formatInstagramPosts = (instagramPosts: Array<Object>) => {
      const sanitizedPosts = removeUndefinedPosts(instagramPosts);
      if (sanitizedPosts.length > 0) {
        const filteredInstaPosts = sanitizedPosts.filter(
          instaPost =>
            instaPost.node.caption &&
            instaPost.node.media_url &&
            instaPost.node.media_type === 'IMAGE',
        );
        const formattedInstaPosts = filteredInstaPosts.map(instaPost => {
          const obj = {
            ...socialCard,
          };
          obj.id = instaPost.node.id;
          obj.text = instaPost.node.caption;
          obj.sampleImage = instaPost.node.media_url;
          obj.socialPostLink = instaPost.node.permalink;
          obj.timestamp = instaPost.node.timestamp;
          obj.socialIcon = 'instagram';
          obj.variant = 'social';
          return obj;
        });
        return formattedInstaPosts;
      }
      return [];
    };

    // Fetch individual FB posts if not in session storage and combine with Instagram and Twitter
    const getSocialPosts = () => {
      const fetchFullFacebookPost = (post: facebookPost) => {
        const accessToken = `${process.env.FACEBOOK_ACCESS_TOKEN}`;
        const apiUrl = `https://graph.facebook.com/${post.id}?fields=permalink_url,full_picture,created_time,message&access_token=${accessToken}`;
        return (
          fetch(apiUrl)
            // eslint-disable-next-line consistent-return
            .then(response => {
              if (response.ok) {
                return response.json();
              }
              console.log('facebook error status', response.status);
              console.log('facebook error response', response);
            })
            .catch(error => {
              console.log(error);
            })
        );
      };

      const getAllFacebookPosts = async () => {
        return Promise.all(
          facebookContent.map((post: facebookPost) => {
            return fetchFullFacebookPost(post);
          }),
        );
      };

      // Fetch individual FB posts if not in session storage
      if (!storedFacebookPosts && facebookContent.length > 0) {
        getAllFacebookPosts().then((fetchedFacebookPostsArr: Array<any>) => {
          const formattedFBPosts = formatFacebookPosts(fetchedFacebookPostsArr);
          sessionStorage.setItem(
            'facebookPosts',
            JSON.stringify(formattedFBPosts),
          );
          collatePosts(
            formattedFBPosts,
            formatInstagramPosts(instagramContent),
            formatTwitterPosts(twitterContent),
          );
        });
      } else {
        collatePosts(
          storedFacebookPosts ? JSON.parse(storedFacebookPosts) : [],
          instagramContent.length > 0
            ? formatInstagramPosts(instagramContent)
            : [],
          twitterContent.length > 0 ? formatTwitterPosts(twitterContent) : [],
        );
      }
    };

    getSocialPosts();
  }, [socialPostQuery]);

  const cards = collatedPosts;

  const totalSlides: number = cards.length;

  useEffect(() => {
    const nextBtn = document.getElementById('swiper-button-next');
    const prevBtn = document.getElementById('swiper-button-prev');
    let slidesPerViewMobile = 1;
    let slidesPerViewDesktop = 1;

    switch (totalSlides) {
      case 1:
        slidesPerViewMobile = 1;
        slidesPerViewDesktop = 1;
        break;
      case 2:
        slidesPerViewMobile = 2;
        slidesPerViewDesktop = 2;
        break;
      case 3:
        slidesPerViewMobile = 2;
        slidesPerViewDesktop = 3;
        break;
      default:
        slidesPerViewMobile = 2;
        slidesPerViewDesktop = 3;
    }

    new Swiper('.swiper', {
      modules: [Navigation, Pagination],
      slidesPerView: 1,
      navigation: {
        nextEl: nextBtn,
        prevEl: prevBtn,
      },
      a11y: {
        itemRoleDescriptionMessage: `slide of carousel of navigable informational slides pertaining`,
      },
      breakpoints: {
        0: {
          slidesPerView: 1,
        },
        640: {
          slidesPerView: slidesPerViewMobile,
        },

        1440: {
          slidesPerView: slidesPerViewDesktop,
        },
      },
    });
  }, [totalSlides]);

  const getCarouselTitle = () => {
    if (title) {
      return <h3 className="carousel-title">{title}</h3>;
    } else {
      return null;
    }
  };

  const isDesktop = useState(() =>
    typeof window !== 'undefined'
      ? window.matchMedia('(min-width: 1024px)').matches
      : false,
  );

  const getCardWrapper = (card: Card) => {
    return (
      <atmos-info-card
        title={card.title}
        text={card.text}
        sample-image={card.sampleImage}
        alt-text={card.altText}
        card-padding={card.cardPadding}
        variant={card.variant}
        social-icon={card.socialIcon}
      >
        <img slot="infoImage" src={card.sampleImage} />
      </atmos-info-card>
    );
  };

  const mobileCards = cards.slice(0, 3);

  return (
    <div className="wrapper social-carousel">
      <div className="inner">
        {getCarouselTitle()}
        {socialLinksArray ? (
          <div className="social-icon-group-wrapper">
            {socialLinksArray.map(linkUrl => {
              return (
                <div className="social-icon-wrapper mr-5 w-6">
                  <atmos-icon-link
                    variant="dark"
                    linkUrl={linkUrl}
                  ></atmos-icon-link>
                </div>
              );
            })}
          </div>
        ) : null}
        {!isDesktop ? (
          <div className="card-wrapper-mobile">
            {mobileCards.map((card: Card) =>
              card.socialPostLink ? (
                <div className="card-wrapper">
                  <a
                    className="social-post-link no-underline"
                    href={card.socialPostLink}
                    target="_blank"
                  >
                    {getCardWrapper(card)}
                  </a>
                </div>
              ) : (
                <div className="card-wrapper">{getCardWrapper(card)}</div>
              ),
            )}
          </div>
        ) : (
          <div className="card-wrapper-desktop">
            <div
              id="swiper"
              className="swiper"
              role="region"
              aria-label="carousel"
            >
              <div className="swiper-wrapper">
                {cards.map((card: Card) => (
                  <div className="swiper-slide">
                    {card.socialPostLink ? (
                      <a
                        className="social-post-link no-underline"
                        href={card.socialPostLink}
                        target="_blank"
                      >
                        <div className="card-wrapper">
                          {getCardWrapper(card)}
                        </div>
                      </a>
                    ) : (
                      <div className="card-wrapper">{getCardWrapper(card)}</div>
                    )}
                  </div>
                ))}
              </div>
            </div>
            <button
              type="button"
              aria-controls="swiper"
              id="swiper-button-prev"
              className="swiper-button-prev"
            >
              <span className="icon-container-prev">
                <atmos-icon className="" icon="next" svg="true"></atmos-icon>
              </span>
            </button>
            <button
              type="button"
              aria-controls="swiper"
              id="swiper-button-next"
              className="swiper-button-next"
            >
              <span className="icon-container-next">
                <atmos-icon className="" icon="next" svg="true"></atmos-icon>
              </span>
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default SocialCarousel;
