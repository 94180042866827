/**
 * Tailwind CSS config overrides
 *
 * Cheat Sheet
 * @see https://nerdcave.com/tailwind-cheat-sheet
 * Variants
 * @see https://github.com/tailwindcss/tailwindcss/blob/master/stubs/defaultConfig.stub.js
 */
const plugin = require('tailwindcss/plugin');

module.exports = {
  // The paths to all of the HTML templates, JavaScript components, and any
  // other source files that contain Tailwind class names.
  content: [
    './src/styles/**/*.tsx',
    './src/components/**/*.tsx',
    './src/fonts/**/*.tsx',
    './src/hooks/**/*.tsx',
    './src/templates/**/*.tsx',
    './src/utils/**/*.tsx',
  ],
  // This silences the tailwind warning since we are using gatsby-plugin-purgecss.
  purge: false,
  // future: {
  //   removeDeprecatedGapUtilities: true,
  //   purgeLayersByDefault: true,
  // },
  theme: {
    colors: {
      // Default
      transparent: 'transparent',
      current: 'currentColor',
      black: '#000',
      white: '#fff',
      // Custom
      'gray-menu': '#ebeced',
      'gray-menu-darken': '#d0d3d5',
      'yellow-menu-accent': '#ffe96b',
      'blue-footer-darken': '#005ead',
      accent: '#ffe96b',
      body: '#ebeced',
      'esg-blue-darken': '#2d5b92',
      'esg-blue': '#2175D9',
      'esg-blue-light': '#80B7EC',
      'esg-green': '#00B56C',
      'esg-yellow': '#FFC600',
      'esg-orange': '#FF8D1E',
      'esg-purple': '#3C4BE8',
      'esg-gray-light': '#C1C6C8',
      'esg-gray-dark': '#7C878E',
      gray: {
        100: '#f7f7f7',
        200: '#f0f0f0',
        300: '#e0e0e0',
        400: '#bdbdbd',
        500: '#9e9e9e',
        600: '#757575',
        700: '#616161',
        800: '#424242',
        900: '#212121',
      },
    },
    extend: {
      height: {
        55: '55px',
        50: '50px',
        250: '250px',
        '30vh': '30vh',
        '35vh': '35vh',
        '45vh': '45vh',
        '55vh': '55vh',
        '60vh': '60vh',
        '65vh': '65vh',
      },
      width: {
        250: '250px',
        'accordion-text-calc': 'calc(100% - 140px)',
      },
      // Top / Right / Bottom / Left scale
      inset: {
        55: '55px',
        '50p': '50%',
        '-30': '-30px',
        '-40': '-40px',
        '-125': '-125px',
        '12%': '12%',
        '12p': '12%',
        '20%': '20%',
        '20p': '20%',
        12: '3rem',
        24: '6rem',
        40: '10rem',
        48: '12rem',
        56: '14rem',
        64: '16rem',
        72: '18rem',
        80: '20rem',
        88: '22rem',
      },
      lineHeight: {
        separated: 2.5,
      },
      maxWidth: {
        1400: '1400px',
        300: '300px',
        250: '250px',
        225: '225px',
        175: '175px',
        150: '150px',
        125: '125px',
        144: '36rem',
        W300: '300px',
        W250: '250px',
        W225: '225px',
        W175: '175px',
        W150: '150px',
        W125: '125px',
      },
      maxHeight: {
        300: '300px',
        250: '250px',
        225: '225px',
        175: '175px',
      },
      minHeight: {
        20: '20px',
        300: '300px',
        400: '400px',
        500: '500px',
        550: '550px',
      },
      marginTop: {
        hero: '50px',
        '-20': '-20px',
      },
      padding: {
        7: '1.75em',
      },
      zIndex: {
        '-100': '-100',
      },
    },
    container: {
      center: true,
      padding: '2rem',
    },
    screens: {
      // xs: '361px',
      sm: '640px',
      md: '768px',
      lg: '992px',
      xl: '1280px',
    },
    fontFamily: {
      body: ['futura_ptbook', 'sans-serif'],
      display: ['futura_ptbook', 'sans-serif'],
      lt: ['futura_ptlight', 'sans-serif'],
      book: ['futura_ptbook', 'sans-serif'],
      md: ['futura_ptmedium', 'sans-serif'],
      bold: ['futura_ptbold', 'sans-serif'],
      heavy: ['futura_ptheavy', 'sans-serif'],
    },
    fontSize: {
      // Defaults
      xs: '0.75rem',
      sm: '0.875rem',
      base: '1rem',
      lg: '1.125rem',
      xl: '1.25rem',
      '2xl': '1.5rem',
      '3xl': '2.0rem', // 1.875rem default
      '4xl': '2.75rem', // 2.25rem default
      '5xl': '3rem',
      '6xl': '4rem',
      // Custom
      75: '75%',
      90: '90%',
      'lg-m': '1.2rem',
      '7xl': '5rem',
    },
    gradients: theme => ({
      'esg-blue-grad': ['to bottom', '#2573d1', '#0659b7'],
      'esg-blue-light-grad': ['to bottom', '#7db4ea', '#4988c5'],
      'esg-green-grad': ['to bottom', '#00974d', '#007e40'],
      'esg-yellow-grad': ['to bottom', '#FFC600', '#e5b200'],
      'esg-orange-grad': ['to bottom', '#fd8a22', '#da6d02'],
      'esg-purple-grad': ['to bottom', '#3e4ce5', '#3248ae'],
      'esg-white-grad': ['to bottom', '#ffffff', '#e0e0e0'],
      'esg-gray-dark-grad': ['to bottom', '#909ba2', '#7C878E'],
    }),
  },
  plugins: [
    require('glhd-tailwindcss-transitions')(),
    require('tailwindcss-plugins/gradients'),
    require('tailwindcss-pseudo-elements'),
    plugin(function ({ addVariant }) {
      addVariant('important', ({ container }) => {
        container.walkRules(rule => {
          rule.selector = `.\\!${rule.selector.slice(1)}`;
          rule.walkDecls(decl => {
            decl.important = true;
          });
        });
      });
    }),
  ],
};
