/* eslint import/no-cycle: "warn" */
import React, { useContext } from 'react';
import { MetricsFragment, NodePage } from 'generated/graphqlCodegen';
import MetricCounter from './MetricCounter';
import { ComponentContext } from '../Context';
import { components } from '../../../utils/componentResolver';

/**
 * A component that renders Metrics.
 *
 * @param fieldComponent
 * @param node
 * @constructor
 */
const Metrics = ({ fieldComponent }: MetricsFragment, node: NodePage) => {
  const {
    id,
    relationships,
    relationships: {
      metrics: { length: itemCount },
      metrics,
    },
    heading,
  } = fieldComponent;
  const { customResolver } = useContext(ComponentContext);

  if (!relationships) {
    return (
      <div>
        <h1>
          Did you forget to add the ...Metrics fragment to a component using a
          "customResolver"?
        </h1>
      </div>
    );
  }

  // Some MetricCounter have a title so lets check if is there and print it.
  const titleCaption = heading
    ? `<figcaption class="pb-2">
      <h5 class="font-md mb-4 text-2xl">${heading.processed}</h5>
    </figcaption>`
    : '';

  // A paragraph component might be using the TitlePlusContent paragraph
  // as a sub-component. This allows us to redirect this to the proper
  // component resolver.
  if (customResolver && components[`Metrics_${customResolver}`]) {
    const CustomComponent = components[`Metrics_${customResolver}`];
    return (
      <CustomComponent key={id} fieldComponent={fieldComponent} node={node} />
    );
  }

  // Standard paragraph__metrics component.
  return (
    // @note: changed my-20 -> my-5
    <figure className="my-5 p-0 md:pl-10">
      <div dangerouslySetInnerHTML={{ __html: titleCaption }} />
      <div className="w-full px-4 mb-6 md:mb-0 border-0 md:border-l border-gray-500 font-md flex flex-wrap justify-center">
        {metrics.map(value => {
          return (
            <MetricCounter
              key={value.id}
              props={{
                id: value.id,
                prefix: value.prefix,
                number: value.number,
                suffix: value.suffix,
                description: value.description.processed,
              }}
              itemCount={itemCount}
            />
          );
        })}
      </div>
    </figure>
  );
};

export default Metrics;
