import { withPrefix } from 'gatsby';
import React from 'react';
import { Hit } from 'react-instantsearch-core';

type HitResult = {
  hit: {
    drupal_internal__nid: number;
    field_summary:
      | {
          value: string;
        }
      | undefined;
    path: {
      alias: string;
    };
    title: string;
  };
  children?: React.ReactNode;
};

export type CombinedSearchProps = Hit & HitResult;

const HitRenderer: React.FunctionComponent<Hit<CombinedSearchProps>> = ({
  hit,
}: CombinedSearchProps) => {
  if (hit) {
    const { field_summary, path, title } = hit;
    const summary = field_summary?.value || '';
    // Pass to withPrefix in case we are in an environment (Github) that uses
    // a prefix.
    const url = withPrefix(path?.alias || '#');
    return (
      <>
        <atmos-search-result title={title} text={summary} url={url} />
      </>
    );
  }
  return null;
};

export default HitRenderer;
