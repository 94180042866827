import React, { useContext } from 'react';
import { regionContext } from 'context/Provider';

interface RegionDisplayProps {
  region?: string;
  children?: JSX.Element;
}

const RegionDisplay = ({ region, children }: RegionDisplayProps) => {
  const { region: regionValue } = useContext(regionContext);

  if (region === 'none') return null;
  if (
    region !== 'none' &&
    region !== 'all' &&
    (regionValue === 'undefined' || regionValue !== region)
  )
    return null;

  return <>{children && <>{children}</>}</>;
};

export default RegionDisplay;
