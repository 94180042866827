import React from 'react';
import { ComponentGroupFragment, NodePage } from 'generated/graphqlCodegen';
import SEO from '../Shared/SEO';
import ComponentResolver from '../../../utils/componentResolver';

type ColumnRelationships = {
  left: ComponentGroupFragment[];
  primary: ComponentGroupFragment[];
  right: ComponentGroupFragment[];
};

interface Props {
  columns: ColumnRelationships;
  node: NodePage;
}

const OneColumn = ({ columns, node }: Props) => {
  // Destructure our columns
  const { primary } = columns;

  const components = ComponentResolver(primary, node);

  return (
    <>
      <SEO title={` | Atmos Energy`} />
      {components && components.props.children.length > 0 && (
        <>{components && <>{components}</>}</>
      )}
    </>
  );
};

export default OneColumn;
