import React, { SVGAttributes } from 'react';

interface SegEnum {
  [key: string]: String;
}

/**
 * All paths for ring segments
 */
export const SegmentPaths: SegEnum = {
  one: 'M432.909,137.698 C396.086,77.859,330.733,37.511,255.8,35.519',
  two: 'M438.739,352.274 c16.511-30.402,25.891-65.244,25.891-102.277c0-37.027-9.381-71.867-25.891-102.27',
  three: 'M255.8,464.482 c74.934-1.994,140.286-42.344,177.109-102.179',
  four: 'M67.091,362.304 c36.818,59.835,102.175,100.185,177.109,102.176',
  five: 'M61.259,147.727 C44.75,178.13,35.372,212.97,35.372,250s9.378,71.869,25.887,102.274',
  six: 'M244.2,35.519 c-74.934,1.992-140.291,42.343-177.109,102.179',
};

/**
 * Parent components will use this interface.
 */
export interface SegmentConfig {
  stroke: string;
  d: string;
  attributes?: SVGAttributes<SVGElement>;
}

const attributeDefaults = {
  fill: 'none',
  strokeWidth: '12',
  strokeMiterlimit: '10',
};

const Segment = ({ stroke, d, attributes }: SegmentConfig) => {
  const attr = { ...attributeDefaults, ...attributes };
  return (
    <path
      stroke={stroke}
      d={d}
      {...attr}
      data-ignore={stroke === '#dcdcdc' ? 'true' : 'false'}
    />
  );
};

export default Segment;
