/**
 * Context API
 */
import { createContext } from 'react';

/**
 * Top Level Context
 */
const EsgPageContext = createContext({
  esg: {
    relationships: {
      esgMedia: {
        id: '',
        name: 'ESG',
        color: 'esg-gray-dark',
        segment: '',
        relationships: {
          icon: { id: '', localFile: { publicURL: '' } },
          mediaImage: {},
        },
      },
    },
  },
  metaDescription: '',
  group: {},
  other: {},
});

/**
 * Group Level Context
 */
export const GroupContext = createContext({
  group: {},
});

/**
 * Component Level Context
 */
export const ComponentContext = createContext({
  customResolver: '',
});

/**
 * ESG Context
 */
export const EsgContext = createContext({
  esgContext: {},
});

/**
 * Providers
 */
export const EsgPageProvider = EsgPageContext.Provider;
export const GroupProvider = GroupContext.Provider;
export const ComponentProvider = ComponentContext.Provider;
export const EsgProvider = EsgContext.Provider;

export default EsgPageContext;
