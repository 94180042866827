import React, { CSSProperties } from 'react';
import Vivus from 'react-vivus';

/**
 * The react-vivus module is just a wrapper around the vivusJS library
 * No types
 */
export type VivusAnimation = {
  id: string;
  className?: string;
  option?: {
    type?:
      | 'delayed'
      | 'sync'
      | 'oneByOne'
      | 'script'
      | 'scenario'
      | 'scenario-sync';
    duration?: number;
    delay?: number;
    start?: 'inViewport' | 'manual' | 'autostart';
    selfDestroy?: boolean;
    pathTimingFunction?:
      | 'LINEAR'
      | 'EASE'
      | 'EASE_OUT'
      | 'EASE_IN'
      | 'EASE_OUT_BOUNCE';
    file?: string;
    onReady?: CallableFunction;
  };
  style?: CSSProperties;
  callback?: Function;
};

/**
 * Animates the ring svg
 *
 * Place this component after the svg for the ring if you want to animate it
 *
 * @constructor
 */
const VivusAnimate = ({
  id = '',
  option = {},
  className = 'top-0 absolute',
}: VivusAnimation) => {
  const defaultOptions = {
    type: 'delayed',
    duration: 150,
    start: 'inViewport',
    selfDestroy: false,
    pathTimingFunction: 'EASE_OUT',
    file: '',
  };
  return (
    <Vivus
      id={id}
      option={{ ...defaultOptions, ...option }}
      className={className}
      callback={() => ''}
    />
  );
};

export default VivusAnimate;
