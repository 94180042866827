import React, { useEffect, useState } from 'react';
import { StockComponent } from 'types';
import SlicePadding from 'components/Energy/Layout/SlicePadding';

type QuoteData = {
  previousClose: number;
  week52Low: number;
  week52High: number;
  marketCap: number;
  volume: number;
};

const Stock = (data: StockComponent) => {
  const { topspacing, bottomspacing, type } = data;

  const [apiData, setApiData] = useState(null);
  const [quoteData, setQuoteData] = useState<QuoteData | null>(null);

  // I think pulling this function out fixed the linting problem.
  const getData = (endpoint: string, dataType: string) => {
    fetch(
      // The ${type} can be 'graph' or 'quote'.
      endpoint,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
      .then(response => {
        return response.json();
      })
      .then(({ data: dataRes }) => {
        if (dataType === 'graph') {
          return setApiData(dataRes);
        } else if (dataType === 'quote') {
          return setQuoteData(dataRes);
        }
      })
      .catch(console.error);
  };

  const getEndpoint = (dataType: string) => {
    return `${process.env.GATSBY_STOCKS_ENDPOINT}/${dataType}.php`;
  };

  /**
   * sending both quote and chart data into the graph component,
   * quote will only receive quote data.
   */
  useEffect(() => {
    if (type === 'graph') {
      getData(getEndpoint('quote'), 'quote');
      getData(getEndpoint('graph'), 'graph');
    } else if (type === 'quote') {
      getData(getEndpoint('quote'), 'quote');
    }
  }, [type]);

  if (!quoteData) return null;

  if (!apiData && type === 'graph') {
    return null;
  }

  const style = {
    maxWidth: '1134px',
    margin: 'auto',
  };

  // Return the markup from the wysiwyg editor.
  return (
    <SlicePadding top={topspacing} bottom={bottomspacing}>
      <div className="stock-wrapper" style={style}>
        {type === 'graph' ? (
          <atmos-stock-chart
            data={JSON.stringify(apiData)}
            quote-data={JSON.stringify(quoteData)}
          />
        ) : (
          <atmos-stock-overview
            previous-close={quoteData.previousClose}
            week52-low={quoteData.week52Low}
            week52-high={quoteData.week52High}
            market-cap={quoteData.marketCap}
            volume={quoteData.volume}
          />
        )}
      </div>
    </SlicePadding>
  );
};

export default Stock;
