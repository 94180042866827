import React from 'react';
import { MediaImageFragment } from 'generated/graphqlCodegen';

interface Props {
  mediaComponent: MediaImageFragment;
}

const MediaSingleImage = ({ mediaComponent }: Props) => {
  const { mediaImageMeta, relationships } = mediaComponent;
  const { mediaImage } = relationships;
  const { alt, title } = mediaImageMeta;

  return (
    <>
      <figure className="my-20 p-0">
        <img
          src={mediaImage.localFile.publicURL}
          alt={alt || 'MediaSingleImage needs alt text'}
          title={title || 'MediaSingleImage needs a title'}
        />
      </figure>
    </>
  );
};

export default MediaSingleImage;
