import React from 'react';
import { getImageMarkup } from 'utils/getSliceMedia';

const Image = data => {
  if (!data) {
    console.log('No data from Image component');
    return (
      <img src="https://www.loremflickr.com/443/443/dog" alt="lorem flickr" />
    );
  }
  const { caption, media, link } = data;

  // Adding this validation because this is a new field
  // and old nodes doesn't have it.
  if (link) {
    var {
      embed: {
        attributes: { href, title, target },
      },
    } = link;
  }

  // This is an exception, the video image is slotted inside another slot
  // so can't be accessed through the slotted selector.
  const imageStyles = {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  };

  // Get the image markup.
  const markup = getImageMarkup(media, 'preview', 'image', imageStyles);

  return (
    <div>
      {href ? (
        <a href={href} title={title} target={target}>
          {markup}
        </a>
      ) : (
        <>{markup}</>
      )}
      <outline-container slot="caption">{caption}</outline-container>
    </div>
  );
};

export default Image;
