import React from 'react';
import { FlexibleColumns } from 'types';
import ContentResolver from 'components/Energy/ContentResolver/ContentResolver';
import './twoColumn.css';

const TwoColumn = (data: FlexibleColumns) => {
  const {
    columnsReverse,
    gutter,
    padding,
    fullwidth,
    withborder,
    halfsize,
    columnOne,
    columnTwo,
    overlayTop,
  } = data;

  const classNames = ['two-column'];

  if (fullwidth) {
    classNames.push('fullWidth');
  }

  if (withborder) {
    classNames.push('border');
  }

  if (!halfsize) {
    classNames.push('no-half');
  }

  if (padding) {
    classNames.push('wrapperPadding');
  }

  if (columnsReverse) {
    classNames.push('columnsReverse');
  }

  if (gutter) {
    classNames.push(gutter);
  }

  if (overlayTop) {
    classNames.push('overlay-top');
  }

  const columnOneComponents = ContentResolver({ content: columnOne });
  const columnTwoComponents = ContentResolver({ content: columnTwo });

  return (
    <>
      <div id="twocolumn" className={classNames.join(' ')}>
        <div className="inner-wrapper">
          <div className="inner">
            <div className="column one">
              {columnOneComponents && <>{columnOneComponents}</>}
            </div>
            <div className="column two">
              {columnTwoComponents && <>{columnTwoComponents}</>}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TwoColumn;
