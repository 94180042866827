import React, { useEffect } from 'react';
import SlicePadding from 'components/Energy/Layout/SlicePadding';

const AccordionBasic = data => {
  const {
    clean,
    singlePanel,
    allOpen,
    gradient,
    accordionPanel,
    topspacing,
    bottomspacing,
  } = data;
  const isSinglePanel = singlePanel ? 'true' : null;
  const isClean = clean ? 'true' : null;
  const isAllOpen = allOpen ? 'true' : null;
  const isGradient = gradient ? 'true' : null;

  const toHyphen = (input: string) => {
    const keyString = input.replace(/\s+/g, '-').toLowerCase();
    return keyString;
  };

  useEffect(() => {
    const documentLinks = document.querySelectorAll(
      '.accordion-wysiwyg-wrapper a',
    );

    if (documentLinks.length > 0) {
      documentLinks.forEach(link => {
        const elem = link.getAttribute('href');
        if (elem && elem.startsWith('/document')) {
          link.setAttribute('target', '_blank');
        }
      });
    }
  }, []);

  return (
    <SlicePadding top={topspacing} bottom={bottomspacing}>
      <atmos-accordion
        clean={isClean}
        single-panel={isSinglePanel}
        allopen={isAllOpen}
      >
        <>
          {accordionPanel.map(panel => {
            return (
              <atmos-accordion-panel
                key={`accordion-panel-${toHyphen(panel.panelHeading)}`}
                slot="panels"
                gradient={isGradient}
              >
                <h3
                  className="text-base md:text-2xl font-bold m-0 md:leading-5"
                  slot="heading"
                >
                  {panel.panelHeading}
                </h3>
                <div className="accordion-wysiwyg-wrapper">
                  <div
                    className="wysiwyg"
                    dangerouslySetInnerHTML={{
                      __html: panel.panelContent.text || '',
                    }}
                  />
                </div>
              </atmos-accordion-panel>
            );
          })}
        </>
      </atmos-accordion>
    </SlicePadding>
  );
};

export default AccordionBasic;
