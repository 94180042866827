import React, { useRef } from 'react';
import { useTransition, animated } from 'react-spring';

export interface SlideAnimationProps {
  visible: boolean;
  body: string;
  id: string;
  forceSlideIn?: boolean;
}

/**
 * Slides content down and back up
 *
 * @param visible
 * @param body
 * @param id
 * @param forceSlideIn
 * @constructor
 */
const SlideAnimation = ({
  visible,
  body,
  forceSlideIn = false,
}: SlideAnimationProps) => {
  const isVisibleOnMount = useRef(visible && !forceSlideIn);
  const containerRef = useRef(null);
  const innerRef = useRef(null);
  const visibleStyle = { height: 'auto', opacity: 1, overflow: 'visible' };
  const hiddenStyle = { opacity: 0, height: 0, overflow: 'hidden' };

  // Mount / Unmount body component reveal.
  const transitions = useTransition(visible, {
    enter: () => visibleStyle,
    leave: () => hiddenStyle,
    from: isVisibleOnMount.current ? visibleStyle : hiddenStyle,
    unique: true,
  });

  return transitions((styles, item) => {
    if (item) {
      return (
        <animated.div ref={containerRef} style={styles}>
          <div
            ref={innerRef}
            className="pt-4 pb-8 px-0 md:px-4 flow-root "
            dangerouslySetInnerHTML={{
              __html: body || '',
            }}
          />
        </animated.div>
      );
    }
    return null;
  });
};

export default SlideAnimation;
