import GetEEImages from 'utils/GetEEImages';
import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';
import BackgroundImage from 'gatsby-background-image';
import { withPrefix } from 'gatsby';

// Get the markup from uuid or image url.
function getMarkup(entityUUID: string, nodes) {
  if (entityUUID !== undefined) {
    // Filter the nodes and return the media entity from our query with the
    // matching uuid.
    const mediaNode = nodes.filter(node => node.drupal_id === entityUUID);

    // Sets the "localFile" variable.
    const {
      0: {
        relationships: {
          field_media_image: { localFile },
        },
        field_media_image: { alt, title },
      },
    } = mediaNode;

    // Get the image.
    const image = getImage(localFile);

    // Get the background image.
    const bgImage = convertToBgImage(image);

    return (
      <>
        <div slot="bgImage">
          <BackgroundImage
            className="bg-image"
            style={{ position: 'initial' }}
            Tag="section"
            {...bgImage}
            preserveStackingContext
          />
        </div>
        <div slot="employee">
          <GatsbyImage
            className="employee-image"
            image={image}
            style={{ maxWidth: '100%', width: '100%', height: '100%' }}
            alt={alt}
            title={title}
          />
        </div>
      </>
    );
  }

  return <p slot="employee">Image is missing</p>;
}

const EmployeeSpotlight = data => {
  const {
    allMediaEeImage: { nodes },
  } = GetEEImages();

  // Drupal stores the uuid of the media entity in the "data-entity-uuid"
  // attribute.
  const {
    media: { uuid: entityUUID },
  } = data;

  const markup = getMarkup(entityUUID, nodes);

  const {
    title,
    text,
    link: {
      embed: {
        text: linkText,
        attributes: { href, target, rel, title: linkTitle },
      },
    },
    variant,
    role,
    name,
    underlayTop,
  } = data;

  const opts = {};
  if (underlayTop) {
    opts['underlay-top'] = true;
  }

  return (
    <atmos-employee-spotlight
      title={title}
      text={text}
      link={linkText}
      target={target}
      rel={rel}
      link-title={linkTitle}
      variant={variant}
      url={withPrefix(href)}
      role={role}
      name={name}
      {...opts}
    >
      {markup}
    </atmos-employee-spotlight>
  );
};

export default EmployeeSpotlight;
