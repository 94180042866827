import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import { isNull } from 'lodash';
import ContentResolver from 'components/Energy/ContentResolver';
import EnergyHeader from 'components/Header/EnergyHeader';
import EnergyFooter from 'components/Footer/EnergyFooter';
import { FlexiblePageTemplate } from 'types';
import { mediaSlices, Slices } from 'utils/mediaSlices';
import '../styles/energy/index.css';
import { FlexiblePageQuery } from 'generated/graphqlCodegen';

export const imageQuery = graphql`
  fragment SliceImage on media__ee_image {
    __typename
    uuid: drupal_id
    id
    name
    field_media_image {
      alt
      title
      width
      height
    }
    relationships {
      field_media_image {
        localFile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
          }
        }
      }
    }
  }
`;

export const videoQuery = graphql`
  fragment SliceVideo on media__ee_video_file {
    __typename
    uuid: drupal_id
    id
    name
    thumbnail {
      alt
      height
      width
    }
    relationships {
      field_media_video_file {
        id
        uuid: drupal_id
        filename
        localFile {
          publicURL
          url
        }
        uri {
          url
          value
        }
      }
      thumbnail {
        id
        uuid: drupal_id
        filename
        localFile {
          childImageSharp {
            gatsbyImageData
          }
          publicURL
          url
        }
        uri {
          url
          value
        }
      }
    }
  }
`;

export const remoteVideoQuery = graphql`
  fragment SliceRemoteVideo on media__ee_remote_video {
    __typename
    uuid: drupal_id
    id
    name
    field_media_oembed_video
    thumbnail {
      alt
      height
      width
    }
    relationships {
      thumbnail {
        filename
        localFile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
          }
          publicURL
        }
        uri {
          url
          value
        }
      }
    }
  }
`;

/**
 * Query for the flexible page template
 */
export const FlexiblePageGraphqlQuery = graphql`
  query FlexiblePage($alias: String!, $moderation_state: String!) {
    json: allNodeFlexiblePage(
      filter: {
        path: { alias: { eq: $alias } }
        moderation_state: { eq: $moderation_state }
      }
    ) {
      nodes {
        __typename
        id
        drupal_id
        title
        path {
          alias
        }
        moderation_state
        field_enhanced_body
        relationships {
          enhanced_editor_media {
            ... on media__ee_image {
              __typename
              uuid: drupal_id
              id
              name
              field_media_image {
                alt
                title
                width
                height
              }
              relationships {
                field_media_image {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        placeholder: BLURRED
                        formats: [AUTO, WEBP]
                      )
                    }
                  }
                }
              }
            }
            ... on media__ee_video_file {
              __typename
              uuid: drupal_id
              id
              name
              thumbnail {
                alt
                height
                width
              }
              relationships {
                field_media_video_file {
                  id
                  uuid: drupal_id
                  filename
                  localFile {
                    publicURL
                    url
                  }
                  uri {
                    url
                    value
                  }
                }
                thumbnail {
                  id
                  uuid: drupal_id
                  filename
                  localFile {
                    childImageSharp {
                      gatsbyImageData
                    }
                    publicURL
                    url
                  }
                  uri {
                    url
                    value
                  }
                }
              }
            }
            ... on media__ee_remote_video {
              __typename
              uuid: drupal_id
              id
              name
              field_media_oembed_video
              thumbnail {
                alt
                height
                width
              }
              relationships {
                thumbnail {
                  filename
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        placeholder: BLURRED
                        formats: [AUTO, WEBP]
                      )
                    }
                    publicURL
                  }
                  uri {
                    url
                    value
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * Flexible Page Template.
 */
const FlexiblePage = ({ data, pageContext }: FlexiblePageTemplate) => {
  if (!data) {
    throw new Error('No data from Flexible Page component');
  }

  useEffect(() => {
    // TODO: TS does not like this but it works; declare this as a module
    import('../../.design/outline.js');
    import('../../.design/outline.theme.css');
  }, []);

  const {
    json: {
      nodes: { 0: node },
    },
  }: FlexiblePageQuery = data;

  const {
    field_enhanced_body: json,
    relationships: { enhanced_editor_media: media },
  } = node;

  const { menus } = pageContext;
  const content = !isNull(json) ? JSON.parse(json) : null;

  const nestedSlices: Slices = {
    gradient_background: ['columnOne'],
    two_column: ['columnOne', 'columnTwo'],
  };

  const addMediaToContent = (
    slice: any,
    contentKey: number,
    mediaContent: any,
  ) => {
    if (Object.keys(mediaSlices).includes(slice.type)) {
      const properties = mediaSlices[slice.type];

      // Some slices have multiple media items
      properties.forEach(property => {
        const sliceMediaUuid =
          mediaContent.data[property]?.embed.attributes['data-entity-uuid'] ||
          null;
        if (sliceMediaUuid) {
          const mediaItem = media.filter(
            (reference: { uuid: string }) => reference.uuid === sliceMediaUuid,
          );
          if (mediaItem.length) {
            mediaContent.media = { ...mediaItem[0] };
          }
        }
      });
    }
  };

  const lookForMedia = (
    slice: { type: string; data: any },
    contentKey: number,
  ) => {
    // Slices can be nested under other slices.
    if (Object.keys(nestedSlices).includes(slice.type)) {
      // Get the object keys of the nested slice(s) that point to media fro
      const nestedSliceKeys = nestedSlices[slice.type];
      nestedSliceKeys.forEach((sliceKey: string) => {
        slice.data[sliceKey].forEach(
          (nestedContentSlice: any, nestedContentSliceKey: number) => {
            if (Object.keys(nestedSlices).includes(nestedContentSlice.type)) {
              // We have another nested level slice. Let's do a recursive lookup.
              lookForMedia(nestedContentSlice, contentKey);
            }
            addMediaToContent(
              nestedContentSlice,
              nestedContentSliceKey,
              slice.data[sliceKey][nestedContentSliceKey],
            );
          },
        );
      });
    } else {
      if (Object.keys(mediaSlices).includes(slice.type)) {
        addMediaToContent(slice, contentKey, content[contentKey]);
      }
    }
  };

  if (media.length > 0) {
    content.forEach(
      (slice: { type: string; data: any }, contentKey: number) => {
        // Look for media within multiple level nesting slices.
        lookForMedia(slice, contentKey);
      },
    );
  }

  return (
    <>
      <EnergyHeader menus={menus} />
      <ContentResolver content={content} />
      <EnergyFooter menus={menus} />
    </>
  );
};

export default FlexiblePage;
