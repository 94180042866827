import useSlottedImage from 'hooks/useSlottedImage';
import React from 'react';

const Footprint = data => {
  const { title, text, textSecondary, footnotes, map, mapUrl } = data;

  const markup = useSlottedImage(map, 'bg-image', 'bgMap');

  return (
    <atmos-footprint
      title={title}
      text={text}
      map-url={mapUrl}
      textSecondary={textSecondary}
      footnotes={JSON.stringify([...footnotes])}
    >
      {markup}
    </atmos-footprint>
  );
};

export default Footprint;
