/**
 * Displays on /esg/financials
 */
import React from 'react';
import { merge } from 'lodash';
import BarChart from '../Bar';
import { EsgChartConfig } from 'types';
import defaultConfig from './config';

/**
 * Configuration for the chart.
 */
const chartConfig: EsgChartConfig = {
  type: 'bar',
  data: {
    labels: [
      '2009',
      '2010',
      '2011',
      '2012',
      '2013',
      '2014',
      '2015',
      '2016',
      '2017',
      '2018',
      '2019',
      '2020',
      '2021E',
    ],
    datasets: [
      {
        data: [
          1.32, 1.34, 1.36, 1.38, 1.4, 1.48, 1.56, 1.68, 1.8, 1.94, 2.1, 2.35,
          2.5,
        ],
        backgroundColor: '#FF8D1E',
      },
    ],
  },
  options: {
    title: {
      display: false,
    },
    aspectRatio: 1.6,
    responsive: true,
    scales: {
      y: {
        ticks: {
          callback: value =>
            value.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            }),
        },
      },
      x: {},
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: ({ formattedValue }) =>
            Number(formattedValue).toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            }),
        },
      },
      datalabels: {
        display: false,
      },
    },
  },
};

const config = merge({}, defaultConfig, chartConfig);

/**
 * Page: /esg/environment
 * @constructor
 */
const Cydg = () => <BarChart name="Cydg" props={config} />;

export default Cydg;
