import { graphql, useStaticQuery } from 'gatsby';
import { GetMessagesQuery } from 'generated/graphqlCodegen';

export const useSiteMessages = () => {
  const { siteSettingEntityMessages } = useStaticQuery<GetMessagesQuery>(
    graphql`
      query GetMessages {
        siteSettingEntityMessages {
          field_zip_code_message
        }
      }
    `,
  );

  return siteSettingEntityMessages;
};
