import React from 'react';

/**
 * Feedback Component
 *
 */

const Feedback = data => {
  const { title } = data;

  return (
    <outline-container>
      <atmos-feedback title={title}> </atmos-feedback>
    </outline-container>
  );
};

export default Feedback;
