/**
 * Override the numerical values for tooltips or values
 *
 * @param index
 * @param array
 */
const replaceValues = (index: number, array: string[]) => {
  // Replacing the chart values to display a range, vs. a specific number
  return array[index] ? array[index] : '';
};

export default replaceValues;
