import React from 'react';
import { merge } from 'lodash';
import { EsgChartConfig } from 'types';
import Doughnut from '../Doughnut';
import defaultConfig from './config';

/**
 * Chart configuration
 *
 * @TODO: This is identical to the DPT2018Chart minus the data
 */
const chartConfig: EsgChartConfig = {
  data: {
    labels: ['Cast Iron', 'Bare Steel', 'Vintage Plastics', 'Other'],
    datasets: [
      {
        data: [737, 5418, 870, 59686],
        backgroundColor: ['#2175D9', '#FF8D1E', '#80B7EC', '#00B56C'],
        borderColor: ['#ffffff', '#ffffff', '#ffffff', '#ffffff'],
        borderWidth: 5,
      },
    ],
  },
  options: {
    plugins: {
      doughnutLabel: {
        labels: [
          {
            text: 'Distribution Pipe Type',
            font: {
              size: '32',
              family: 'futura_ptmedium',
            },
            color: '#66696c',
          },
          {
            text: 'CY 2013',
            font: {
              size: '32',
              family: 'futura_ptmedium',
            },
            color: '#66696c',
          },
        ],
      },
    },
  },
};

const config = merge({}, defaultConfig, chartConfig);

/**
 * Distribution Pipe Type : 2013
 *
 * /esg/environment
 *
 * @constructor
 */
const DPT2013Chart = () => <Doughnut name="DPT2013Chart" props={config} />;

export default DPT2013Chart;
